<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">탈퇴한 사용자</div>
        <div class="description">탈퇴한 사용자를 조회합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr>
                <th>탈퇴날짜</th>
                <td>
                  <div class="inner_td">
                    <DatePicker class="w_300" v-model="searchInfo.dateRange"
                                :format="'yyyy-MM-dd'"
                                :enable-time-picker="false" auto-apply
                                placeholder="탈퇴날짜 구간 선택"
                                locale="ko" range
                                week-start="0"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>가입수단별</th>
                <td>
                  <FilterTabs :tabs="signupOptions"
                              v-model:selectTab="searchInfo.typeId"
                              @tab-selected="setPageSize"/>
                </td>
              </tr>
              <tr>
                <th>탈퇴유형</th>
                <td>
                  <FilterTabs :tabs="withdrawalTypeOptions"
                              v-model:selectTab="searchInfo.exitStatus"
                              @tab-selected="setPageSize"/>
                </td>
              </tr>
              <tr>
                <th>키워드검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="searchInfo.searchKey">
                      <option value="email">이메일</option>
                      <option value="name">이름</option>
                      <option value="phoneNumber">휴대폰번호</option>
                    </select>
                    <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                        v-model="searchInfo.searchValue"
                        @keydown.enter="fetchWithdrawUsers"
                    />
                    <button @click="fetchWithdrawUsers" type="button">검색</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="명"
                                 v-model:searchResultCount="pagination.pageSize"
                                 />
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 6%"/>
                  <col style="width: 7%"/>
                  <col style="width: 17%"/>
                  <col style="width: 11%"/>
                  <col/>
                  <col style="width: 10%"/>
                  <col style="width: 10%"/>
                  <col style="width: 11%"/>
                  <col style="width: 13%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th>
                    <input type="checkbox" v-model="isAllSelected"/>
                  </th>
                  <th v-for="sortField in sortFieldsState" :key="sortField.label ">
                    <TableHeaderItem :headData="sortField" @sort="setOrderBy"/>
                  </th>
                </tr>
                </thead>
                <tbody>
                  <template v-if="withdrawUsers.length>0">
                    <WithdrawListItem v-for="user in withdrawUsers" :key="user.id" :userInfo="user"/>
                  </template>
                  <tr class="no_data" v-else>
                    <td colspan="9">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="accountRecovery" >계정복구</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {onMounted, ref, watch} from "vue";
import {signupOptions, withdrawalTypeOptions} from "@/util/filterFields";
import Pagination from "@/components/common/Pagination.vue";
import {useOrder} from "@/composables/orderBy";
import {withdrawListSortFields} from "@/util/sortFields";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import WithdrawListItem from "@/components/user/WithdrawListItem.vue";
import {fetchUsers} from "@/api/user";
import {useSelectAll} from "@/composables/selectAll";

const withdrawUsers = ref([]);

const {sortFieldsState,setOrderBy,resetOrderBy,getOrderBy} = useOrder(withdrawListSortFields, fetchWithdrawUsers);
const {isAllSelected, getSelectedIds} = useSelectAll(withdrawUsers, 'id');

const searchInfo = ref({
  typeId: null,
  exitStatus: 'EXIT',
  searchKey: 'email',
  searchValue: null,
  dateRange: [],
});

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});


onMounted(async ()=>{
  await fetchWithdrawUsers();
});

watch([
  () => pagination.value.pageSize,
  ()=>searchInfo.value.dateRange,
], setPageSize, {deep:true});
watch(()=>pagination.value.pageNumber,fetchWithdrawUsers);

async function fetchWithdrawUsers(){
  try {
    const {data} = await fetchUsers(searchInfo.value,{
      orderKey: getOrderBy()?.key,
      orderValue: getOrderBy()?.direction
    },pagination.value);

    console.log(data);

    pagination.value.pageNumber = data.pageNumber;
    pagination.value.pageSize = data.pageSize;
    pagination.value.totalCount = data.totalCount;

    withdrawUsers.value = data.UserMetaDataList;
    withdrawUsers.value.forEach(user => user.isSelected = false);

  } catch(e){
    console.error(e);
  }
}

function setPageSize() {
  resetOrderBy();
  pagination.value.pageNumber = 1;
  fetchWithdrawUsers();
}

function accountRecovery(){
  alert('서비스 준비중입니다.');
}

</script>

<style scoped></style>
