<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">이용약관 상세보기</div>
        <div class="description">이용약관을 조회 및 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <TermsForm 
              v-model:terms-info="termsInfo"
              v-model:effectiveDateValid="effectiveDateValid"/>
          </div>
          <div class="btn_bottom_a">
            <button @click="editTermsOfUse" type="button" class="on">저장</button>
            <button @click="deleteTermsOfUse" type="button">삭제</button>
            <button @click="listTermsOfUse" type="button">목록</button>
            <!-- <button @click="router.push('/admin/termsofuse/list')" type="button">목록</button> -->
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter, useRoute} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";
import {onMounted, ref, watch} from "vue";
import TermsForm from "@/components/admin/TermsForm.vue";
import {fetchTerms, modifyTerms, removeTermsList} from "@/api/policies";

const router = useRouter();
const route = useRoute();

// 락커수정 초기상태를 저장할 변수
const initialState = ref({});
const isEdited = ref(false);
const isDataLoaded = ref(false);

const termsInfo = ref({
  displayed: false,
  effectiveDate: new Date(),
  content: '',
});

const effectiveDateValid = ref({
  msg: '',
  valid: true,
  page: 'terms',
  origin: '',
});

onMounted(async () => {
  try {
    const {data} = await fetchTerms(route.params.id);
    console.log(data);

    termsInfo.value.displayed = data.isActive;
    termsInfo.value.effectiveDate = data.effectiveDate;
    termsInfo.value.content = data.content;

    initialState.value = JSON.parse(JSON.stringify(data));
    isDataLoaded.value = true;

    effectiveDateValid.value.origin = initialState._rawValue.effectiveDate;
  } catch (error) {
    console.error(error);
  }
});

function checkRequired() {
  return !!(termsInfo.value.content && termsInfo.value.effectiveDate && effectiveDateValid.value.valid);
}

function isStateChanged() {
  return !!(termsInfo.value.displayed != initialState._rawValue.isActive || termsInfo.value.effectiveDate != initialState._rawValue.effectiveDate || termsInfo.value.content != initialState._rawValue.content);
}

function listTermsOfUse() {
  if (isStateChanged()) {
    if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
  }
  router.push('/admin/termsofuse/list');
}

async function editTermsOfUse() {
  if (!checkRequired()) {
    alert('내용을 입력해 주세요.');
    return;
  }

  try {
    const {status} = await modifyTerms(route.params.id, {
      content: termsInfo.value.content,
      effectiveDate: termsInfo.value.effectiveDate,
      isActive: termsInfo.value.displayed,
    });
    console.log(status)
    if (status === 200) {
      alert('저장이 완료되었습니다.')
      await router.push('/admin/termsofuse/list');
    }
  } catch (e) {
    console.error(e);
  }
}

async function deleteTermsOfUse() {
  if (!confirm('삭제하시겠습니까?')) return;

  try{
    const resp = await removeTermsList(route.params.id);
    console.log(resp)
    if (resp.status === 204){
      alert('삭제되었습니다.');
      await router.push('/admin/termsofuse/list');
    }
  }catch(error){
    console.error(error);
  }
  // if (confirm('삭제하시겠습니까?')) {
  //   // 락커 구역 삭제...
  //   alert('삭제되었습니다.');
  //   router.push('/admin/termsofuse/list');
  // }
}
</script>

<style scoped></style>
