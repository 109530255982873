
const ACCESS_TOKEN = 'access';
const REFRESH_TOKEN = 'refresh';

// jwt토큰에 있는 정보를 js객체로 변환하는 함수
const parseJwt = (token) => {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};

function getRole() {
    return existAccessToken() ? parseJwt(getAccessToken()).role : null;
}

function saveToken(accessToken, refreshToken, isAutoLogin) {
    if (isAutoLogin) {
        localStorage.setItem(ACCESS_TOKEN, accessToken);
        localStorage.setItem(REFRESH_TOKEN, refreshToken);
    } else {
        sessionStorage.setItem(ACCESS_TOKEN, accessToken);
        sessionStorage.setItem(REFRESH_TOKEN, refreshToken);
    }
    localStorage.setItem('auto',isAutoLogin);
}

function isAutoLogin(){
    return JSON.parse(localStorage.getItem('auto'));
}

function existAccessToken() {
    return (localStorage.getItem(ACCESS_TOKEN)
        || sessionStorage.getItem(ACCESS_TOKEN));
}

function existRefreshToken() {
    return (localStorage.getItem(REFRESH_TOKEN)
        || sessionStorage.getItem(REFRESH_TOKEN));
}

function resetStorage() {
    localStorage.removeItem(ACCESS_TOKEN);
    sessionStorage.removeItem(ACCESS_TOKEN);
    localStorage.removeItem(REFRESH_TOKEN);
    sessionStorage.removeItem(REFRESH_TOKEN);
}

function getAccessToken() {
    return localStorage.getItem(ACCESS_TOKEN) || sessionStorage.getItem(ACCESS_TOKEN);
}

function getRefreshToken() {
    return localStorage.getItem(REFRESH_TOKEN) || sessionStorage.getItem(REFRESH_TOKEN);
}


export {getAccessToken,getRefreshToken,
    getRole, saveToken,
    existAccessToken, resetStorage,
    isAutoLogin,existRefreshToken
};