<template>
  <tr>
    <td class="o1">
      <input type="checkbox" id="check" v-model="companyInfo.isSelected"/>
    </td>
    <td class="no_mobile">{{companyInfo.number}}</td>
    <td class="detail_page_a o2 o_title">
      <div class="center_td">
        <router-link :to="`/company/view/${companyInfo.companyId}`" class="center_td">
          <p class="text_bold long_p">{{companyInfo.companyName}}</p>
          <img src="@/images/detail_page.png" />
        </router-link>
      </div>
    </td>
    <td class="seat o3">
      <span class="only_mobile">관리자계정 : </span>
      <span>{{companyInfo.managedUserLoginId}}</span>
    </td>
    <td class="num phone o4r">
      <span class="only_mobile">생성일 : </span>
      <span>{{companyInfo.createdAt}}</span>
    </td>
    <td class="num o_stat">
      <input
          type="checkbox"
          :id="`toggle${companyInfo.companyId}`"
          class="toggle"
          hidden
          v-model="companyInfo.isActive"

      />
      <label @click="setCompanyFlag"
             class="toggleSwitch">
        <span class="toggleButton"></span>
      </label>
    </td>
  </tr>
</template>

<script setup>

// import {ref} from "vue";

const props = defineProps({
  companyInfo:{
    type:Object,
    required:true
  }
});

const emit = defineEmits(['flag']);

function setCompanyFlag(){
  emit('flag',{
    companyId: props.companyInfo.companyId,
    isActive: !props.companyInfo.isActive
  })
}


</script>

<style scoped>

</style>