<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">회원 상세보기</div>
        <div class="description">
          회원 정보를 조회하고 이용중인 락커를 관리합니다.
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26"/>
                <col/>
              </colgroup>
              <tr v-if="authStore.isSuperAdmin">
                <th>업체</th>
                <td>
                  <div class="inner_td">{{ storeUser.companyName }}</div>
                </td>
              </tr>
              <tr>
                <th>이름</th>
                <td>{{ storeUser.storeUserName }}</td>
              </tr>
              <tr>
                <th>휴대폰번호</th>
                <td>
                  <div class="inner_td">{{  toHyphenPhoneNumber(String(storeUser.storeUserPhoneNumber)) }}</div>
                </td>
              </tr>
              <tr>
                <th>생년월일</th>
                <td>
                  <div class="inner_td">{{ formatDate(storeUser.birthday) || '-' }}</div>
                </td>
              </tr>
              <tr>
                <th>성별</th>
                <td>
                  <div class="inner_td">{{ gender || '-' }}</div>
                </td>
              </tr>
              <tr>
                <th>메모</th>
                <td>
                  {{ storeUser.description || '-' }}
                </td>
              </tr>
            </table>
          </div>
          <div class="part_title">락커정보</div>
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="건"
                                 v-model:searchResultCount="pagination.pageSize"
                                 @resultCount="setPageSize"
            />
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 12%"/>
                  <col style="width: 8%"/>
                  <col/>
                  <col style="width: 28%"/>
                  <col style="width: 13%"/>
                  <col style="width: 18%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th v-for="sortField in sortFieldsState" :key="sortField.key">
                    <TableHeaderItem :headData="sortField"/>
                  </th>
                </tr>
                </thead>
                <tbody>
                <template v-if="lockerUsages.length !== 0">
                  <lockerUsageListItem v-for="lockerUsage in lockerUsages"
                                       :key="lockerUsage.lockerUsageId"
                                       :lockerUsagesInfo="lockerUsage"
                                       @onExtensionModal="extensionModalOn"
                                       @retrieve="retrieveLocker"/>
                </template>
                <tr class="no_data" v-else>
                  <td colspan="6">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
          <div class="btn_bottom_a">
            <router-link :to="`/member/edit/${storeUser.storeUserId}`" class="on">수정</router-link>
            <a @click.prevent="deleteMember" href="###">삭제</a>
            <router-link to="/member/list">목록</router-link>
          </div>
        </article>
      </div>
    </div>
  </div>

  <!-- Modal: 락커연장 -->
  <ExtensionModal v-if="extensionModal" v-model:modal="extensionModal"
                  v-model:modalData="extensionModalData"
                  @complete="reloadDataOnModalClose"
  />
  <!-- //Modal: 락커연장 -->
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {fetchStoreUser, removeStoreUsers} from "@/api/storeUser";
import {fetchLockerUsagesList, retrieveLockerUsage} from "@/api/locker/lockerUsage";
import {useAuthStore} from "@/store/auth";
import {formatDate, toHyphenPhoneNumber} from "@/util/formatUtils";
import Pagination from "@/components/common/Pagination.vue";
import {useOrder} from "@/composables/orderBy";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import lockerUsageListItem from "@/components/locker/lockerUsageListItem.vue";
import {memberLockerUsageStatusListSortFields} from "@/util/sortFields";
import ExtensionModal from "@/components/locker/ExtensionModal.vue";

const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

// 기간연장 모달
const extensionModal = ref(false);
const extensionModalData = ref({});

const {sortFieldsState} = useOrder(memberLockerUsageStatusListSortFields, null);

const storeUser = ref({
  birthday: null,
  companyId: null,
  companyName: null,
  description: null,
  gender: null,
  storeUserId: null,
  storeUserName: null,
  storeUserPhoneNumber: null,
})

const lockerUsages = ref([]);

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});

onMounted(async () => {
  try {
    const resp = await fetchStoreUser(route.params.memberId);
    storeUser.value = resp.data;
  } catch (e) {
    console.error(e);
  }

  await fetchLockerUsagesListInfo();
});

const gender = computed(() => {
  switch (storeUser.value.gender) {
    case 'male':
      return '남성';
    case 'female':
      return '여성';
    default:
      return null;
  }
});

async function fetchLockerUsagesListInfo() {
  try {
    const resp = await fetchLockerUsagesList(storeUser.value.storeUserId, pagination.value);

    console.log(resp);

    pagination.value.pageNumber = resp.data.pageNumber;
    pagination.value.pageSize = resp.data.pageSize;
    pagination.value.totalCount = resp.data.totalCount;

    lockerUsages.value = resp.data.lockerUsageMetaDataList;
    lockerUsages.value.forEach(lockerUsage => lockerUsage.isSelected = false);
  } catch (e) {
    console.error(e);
  }
}

function setPageSize() {
  pagination.value.pageNumber = 1;
  fetchLockerUsagesListInfo();
}

function extensionModalOn(lockerInfo) {
  // extensionModalData.value = lockerInfo;

  extensionModalData.value = {
    ...lockerInfo,
    companyId: storeUser.value.companyId,
    companyName: storeUser.value.companyName,
    // lockerId: '',
    phoneNumber: storeUser.value.storeUserPhoneNumber,
    storeUserId: storeUser.value.storeUserId,
    storeUserName: storeUser.value.storeUserName,
  };
  extensionModal.value = true;
}

function reloadDataOnModalClose() {
  fetchLockerUsagesListInfo();
  extensionModal.value = false;
}

// 락커 회수
async function retrieveLocker(lockerUsageId) {
  if (!confirm('락커를 회수하시겠습니까?')) return;

  try {
    const {status} = await retrieveLockerUsage(lockerUsageId);
    if (status === 204) {
      alert('락커 회수가 완료되었습니다.');
      await fetchLockerUsagesListInfo();
    }
  } catch (e) {
    console.error(e);
  }
}

async function deleteMember() {
  if (!confirm('삭제하시겠습니까?')) return;

  try {
    const {status} = await removeStoreUsers(storeUser.value.storeUserId);
    if(status === 204){
      alert('삭제되었습니다.');
      await router.push('/member/list');
    }
  } catch(e){
    if(e.response.status===400 && e.response.data.errorCode ==='Store User Has Assigned Locker'){
      alert('해당 유저는 현재 할당된 락커가 있습니다.\n락커를 먼저 회수해 주세요.');
    }
    console.error(e);
  }
}


</script>

<style scoped></style>
