<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">공지사항 상세보기</div>
        <div class="description"></div>
        <article class="right_body"></article>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminNoticeView",
};
</script>

<style scoped></style>
