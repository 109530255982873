<template>
  <select class="w_80" v-model="hour">
    <option v-for="i in 24" :value="i-1">{{ i - 1 }}시간</option>
  </select>
  <select class="w_80" v-model="minute">
    <option :disabled="isPresent && hour===0" :value="0">0분</option>
    <option :value="15">15분</option>
    <option :value="30">30분</option>
    <option :value="45">45분</option>
  </select>
</template>

<script setup>

import {computed, ref, watch} from "vue";

const time = defineModel('time');
const props = defineProps({
  isPresent:{
    default:false,
  }
});

const hour = ref(Math.floor(time.value/60));
const minute = ref(Math.floor(time.value%60));

const timeInMinutes = computed(()=>hour.value *60 + minute.value);
watch(timeInMinutes, (newTime) => {
  time.value = newTime;
});

// time 값이 외부에서 변경되었을 때, hour와 minute 업데이트
watch(
    time, (newTime) => {
      hour.value = Math.floor(newTime / 60);
      minute.value = newTime % 60;
    },
    { immediate: true } // 초기화 시에도 실행
);

</script>

<style scoped>

</style>