<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 등록</div>
        <div class="description">새로운 락커를 등록합니다.</div>

        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26"/>
                <col/>
              </colgroup>
              <tbody>
              <tr v-if="authStore.isSuperAdmin">
                <th>업체선택<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <CompanySelector :isViewMode="false" v-model:companyId="companyId" @change="getAreaList"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>구역 / 중계기<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <select class="w_250" v-model="areaId"
                            @change="getTabletList"
                            :disabled="companyId===null">
                      <option disabled :value="null">구역을 선택하세요</option>
                      <option v-for="area in areas" :value="area.areaId">{{ area.areaName }}</option>
                    </select>
                    <select class="w_250" v-model="tabletId"
                            :disabled="companyId===null || areaId === null">
                      <option disabled :value="null">중계기를 선택하세요</option>
                      <option v-for="tablet in tablets" :value="tablet.tabletId">{{ tablet.tabletName }}</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>락커번호<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                        class="w_250"
                        placeholder="락커번호를 입력하세요"
                        v-model="lockerNum"
                        ref="lockerInput"
                    />
                    <div class="validation"
                         :class="lockerNumbValid.valid?'text_green':'text_red'">
                      {{ lockerNumbValid.msg }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>시리얼넘버<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                        type="text"
                        class="w_250"
                        placeholder="시리얼넘버를 입력하세요"
                        v-model="serialNumber"
                        ref="serialInput"
                    />
                    <div class="validation"
                         :class="serialNumValid.valid?'text_green':'text_red'">
                      {{ serialNumValid.msg }}
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>PIN번호<span class="text_red">*</span></th>
                <td>
                  <div class="inner_td">
                    <input
                        type="text"
                        class="w_250"
                        placeholder="PIN번호를 입력하세요"
                        v-model="pinNumber"
                        ref="pinInput"
                    />
                    <!--                    <div class="validation text_red">-->
                    <!--                      PIN 번호를 입력해주세요-->
                    <!--                    </div>-->
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="addLocker" href="#" class="on">등록</a>
            <router-link to="/locker/admin/list">취소</router-link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>

import {onBeforeRouteLeave, useRouter} from "vue-router";
import {useAuthStore} from "@/store/auth";
import {onMounted, ref, watch} from "vue";
import CompanySelector from "@/components/common/CompanySelector.vue";
import {fetchAreaList} from "@/api/area";
import {fetchTabletList} from "@/api/tablet";
import {debounce} from "lodash";
import {checkLockerNumber, checkSerialNumber, registerLocker} from "@/api/locker/locker";

const router = useRouter();
const authStore = useAuthStore();

const areas = ref([]);
const tablets = ref([]);

const companyId = ref(null);
const areaId = ref(null);
const tabletId = ref(null);
const lockerNum = ref(null);
const serialNumber = ref(null);
const pinNumber = ref(null);

const lockerInput = ref(null);
const serialInput = ref(null);
const pinInput = ref(null);

const lockerNumbValid = ref({
  msg: '',
  valid: false,
});
const serialNumValid = ref({
  msg: '',
  valid: false,
});

const isAdded = ref(false);

onMounted(() => {
  if (!authStore.isSuperAdmin) {
    companyId.value = authStore.companyId;
  }
  getAreaList();
});

onBeforeRouteLeave((to, from, next) => {
  if (isStateChanged() && !isAdded.value) {
    if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
  }
  next();
});

watch(lockerNum, (newNum) => {debouncedCheckLockerNumber(newNum);});

const debouncedCheckLockerNumber = debounce(async (newNum) => {
  if (!newNum) {
    lockerNumbValid.value.valid = false;
    lockerNumbValid.value.msg = '락커번호를 입력해주세요';
    return;
  }
  if (!areaId.value) {
    alert('구역을 먼저 선택해주세요');
    lockerNum.value = null;
    return;
  }

  try {
    const {data} = await checkLockerNumber(areaId.value, newNum);
    if (data.exists) {
      lockerNumbValid.value.valid = false;
      lockerNumbValid.value.msg = '이미 등록된 락커번호입니다.';
    } else {
      lockerNumbValid.value.valid = true;
      lockerNumbValid.value.msg = '등록 가능한 락커번호입니다.';
    }
  } catch (e) {
    lockerNumbValid.value.valid = false;
    console.error(e);
  }
},300);


watch(serialNumber, (newNum)=>{debouncedCheckSerialNumber(newNum);});

const debouncedCheckSerialNumber = debounce(async (newNum)=>{
  if (!newNum) {
    serialNumValid.value.valid = false;
    serialNumValid.value.msg = '시리얼넘버를 입력해주세요';
    return;
  }

  try {
      const {data} = await checkSerialNumber(newNum);
      if (data.exists) {
        serialNumValid.value.valid = false;
        serialNumValid.value.msg = '이미 등록된 시리얼넘버입니다.';
      } else {
        serialNumValid.value.valid = true;
        serialNumValid.value.msg = '등록 가능한 시리얼넘버입니다.';
      }
  } catch (e) {
    serialNumValid.value.valid = false;
    console.error(e);
  }
},300)

async function getAreaList() {
  if (companyId.value === null) return;
  areaId.value = null;
  tabletId.value = null;
  lockerNum.value = null;
  try {
    const {data, status} = await fetchAreaList(companyId.value);
    if (status === 200) {
      areas.value = data;
    }
  } catch (e) {
    console.error(e);
  }
}

async function getTabletList() {
  if (areaId.value === null) return;
  tabletId.value = null;
  lockerNum.value = null;
  try {
    const {data, status} = await fetchTabletList(areaId.value);
    if (status === 200) {
      tablets.value = data;
      debouncedCheckLockerNumber(lockerNum.value);
    }
  } catch (e) {
    console.error(e);
  }
}

function checkRequired() {
  if(!lockerNumbValid.value.valid){
    lockerInput.value.focus();
    return false;
  }
  if(!serialNumValid.value.valid){
    serialInput.value.focus();
    return false;
  }
  if(!pinNumber.value){
    pinInput.value.focus();
    return false;
  }
  return !!(companyId.value && areaId.value && tabletId.value);
}

function isStateChanged() {
  return !!(companyId.value || areaId.value || tabletId.value ||
      lockerNumbValid.value.valid || serialNumValid.value.valid || pinNumber.value);
}

async function addLocker() {
  if (!checkRequired()) {
    alert('락커정보를 모두 입력해 주세요');
    return;
  }

  try {
    const {status} = await registerLocker({
      companyId: companyId.value,
      areaId: areaId.value,
      tabletId: tabletId.value,
      lockerNum: lockerNum.value,
      serialNumber: serialNumber.value,
      pinNumber: pinNumber.value,
    });
    if (status === 201) {
      isAdded.value = true;
      alert('등록되었습니다.')
      await router.push('/locker/admin/list');
    }
  } catch (e) {
    console.error(e);
  }
}

</script>

<style scoped>
</style>
