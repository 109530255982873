<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">업체 목록</div>
        <div class="description">업체를 조회하고 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr>
                <th>활성화여부</th>
                <td>
                  <FilterTabs :tabs="tabOptions"
                              v-model:selectTab="searchInfo.filterValue"
                              @tab-selected="onTabSelected"/>
                </td>
              </tr>
              <tr>
                <th>키워드검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="searchInfo.searchKey">
                      <option value="companyName">상호명</option>
                      <option value="loginId">관리자계정</option>
                    </select>
                    <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                        v-model="searchInfo.searchValue"
                        @keyup.enter="fetchCompanyInfo"
                    />
                    <button @click="fetchCompanyInfo" type="button">검색</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="건"
                                 v-model:search-result-count="pagination.pageSize"
                                 @resultCount="setPageSize"/>
            <button type="button" @click="router.push('/company/add')">업체등록</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%"/>
                  <col style="width: 6%"/>
                  <col/>
                  <col style="width: 16%"/>
                  <col style="width: 14%"/>
                  <col style="width: 11%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th>
                    <input type="checkbox" id="checkAll" v-model="isAllSelected"/>
                  </th>
                  <th v-for="sortField in sortFieldsState" :key="sortField.key">
                    <TableHeaderItem :headData="sortField" @sort="setOrderBy"/>
                  </th>
                </tr>
                </thead>
                <tbody>
                <template v-if="companys.length !==0">
                  <CompanyListItem v-for="company in companys"
                                   :key="company.companyName"
                                   :companyInfo="company"
                                   @flag="setCompanyFlag"
                  />
                </template>
                <tr class="no_data" v-else>
                  <td colspan="8">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="removeCompany">삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";
import {computed, ref, watch} from "vue";
import CompanyListItem from "@/components/company/CompanyListItem.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {fetchCompanys, modifyCompanyActive, removeCompanys} from "@/api/company";
import {formatDate} from "@/util/formatUtils";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import Pagination from "@/components/common/Pagination.vue";
import {companyListSortFields, memberListSortFields} from "@/util/sortFields";
import {useOrder} from "@/composables/orderBy";
import {useSelectAll} from "@/composables/selectAll";

const router = useRouter();

const tabOptions = [
  {label: '전체보기', value: null},
  {label: '활성', value: true},
  {label: '비활성', value: false}
];


const companys = ref([]);
const {sortFieldsState, setOrderBy, resetOrderBy,getOrderBy} = useOrder(companyListSortFields,fetchCompanyInfo);
const {isAllSelected, getSelectedIds} = useSelectAll(companys,'companyId');

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});

const searchInfo = ref({
  filterValue: null,
  searchKey: 'companyName',
  searchValue: '',
});

// 초기 업체정보 로드
fetchCompanyInfo();

// 페이지네이션 버튼 클릭시 호출
watch(()=>pagination.value.pageNumber,(newValue,oldValue)=>{
      if(newValue !== oldValue){
        fetchCompanyInfo();
      }
    },);

async function fetchCompanyInfo() {
  try {
    const resp = await fetchCompanys({
          filterValue: searchInfo.value.filterValue,
          searchKey: searchInfo.value.searchKey || null,
          searchValue: searchInfo.value.searchValue || null
        },
        {
          orderKey: getOrderBy()?.key,
          orderValue: getOrderBy()?.direction
        },
        {
          pageNumber: pagination.value.pageNumber,
          pageSize: pagination.value.pageSize,
        });
    console.log(resp);
    pagination.value.pageNumber = resp.data.pageNumber;
    pagination.value.pageSize = resp.data.pageSize;
    pagination.value.totalCount = resp.data.totalCount;

    companys.value = resp.data.companyMetaDataList;
    companys.value.forEach(company => {
      company.isSelected = false;
      company.createdAt = formatDate(company.createdAt);
    });


  } catch (e) {
    console.error(e);
  }
}

function onTabSelected() {
  pagination.value.pageNumber = 1;
  resetOrderBy();
  fetchCompanyInfo();
}

function setPageSize() {
  resetOrderBy();
  pagination.value.pageNumber = 1;
  fetchCompanyInfo();
}

async function removeCompany() {
  const ids = getSelectedIds();
  if(ids.length === 0){
    alert('선택된 항목이 없습니다.')
    return;
  }

  const activatedCompanies = companys.value
      .filter(company=>ids.includes(company.companyId) && company.isActive);
  if(activatedCompanies.length>0){
    alert('활성화된 업체는 삭제가 불가합니다.\n비활성화 처리 후 다시 시도해 주세요.')
    return;
  }

  if(!confirm('삭제하시겠습니까?')) return;

  try{
    const resp = await removeCompanys(ids.join(','));
    console.log(resp)
    if(resp.status === 204){
      alert('삭제되었습니다.');
      await fetchCompanyInfo();
    }
  }catch(error){
    console.error(error);
  }

}

async function setCompanyFlag({companyId, isActive}){
  let prefix = isActive?'활성화':'비활성화';

  if(!confirm(`${prefix} 처리하시겠습니까?`)) {
    companys.value.forEach(company=>{
      if(company.companyId === companyId){
        company.isActive =!isActive;
      }
    });
    return;
  }

  try{
    const resp = await modifyCompanyActive(companyId,isActive);

    if(resp.status === 200){
      alert(`${prefix}되었습니다.`);
      companys.value.forEach(company=>{
        if(company.companyId === companyId){
          company.isActive =isActive;
        }
      });
    }
  }catch(error){
    console.error(error);
  }
}


</script>

<style scoped></style>
