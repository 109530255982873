<template>
  <header class="header">
    <div class="header_top">
      <div class="header_topbox">
        <div class="header_top_column">
          <a href="https://mizeinc.co.kr" target="_blank">MIZE INC.</a>
        </div>
        <div class="header_top_column">
          <!-- Vue 데이터 바인딩해야 함 -->
          <span class="top_name">{{ authStore.username }}님 </span>
          <router-link to="/myinfo/management"
                       :class="{'active-myinfo' : checkHeaderActive('/myinfo')}"
          >
            내 정보
          </router-link>
          <a class="link" href="#" @click.prevent="logout">로그아웃</a>
        </div>
      </div>
    </div>
    <nav class="header_bottom">
      <div class="header_bottombox">
        <div @click="router.push('/locker/use/usestatus')" class="link">
          <img src="@/images/logo_w.png" />
        </div>
        <div class="menu">

          <template v-for="menu in menus">
            <a v-if="isRendering(menu.super)" @click.prevent="navigateWithReload(menu.link)"
               class="link" :class="{ on: checkHeaderActive(menu.division) }"
            >
              <img :src="require(`@/images/${menu.imgName}`)" alt="" />{{menu.label}}
            </a>
          </template>

        </div>
      </div>
    </nav>
  </header>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {checkHeaderActive} from "@/components/Layout/common/layoutCommon";
import {useAuthStore} from "@/store/auth";
import {computed, ref} from "vue";
import {useRouteHandle} from "@/composables/routeHadler";

const authStore = useAuthStore();
const router = useRouter();
const route = useRoute();
const {navigateWithReload} = useRouteHandle();

const isSuperAdmin = computed(()=>authStore.isSuperAdmin);

const menus = ref([
  {label: '업체관리', link: '/company/list', super: true, division: '/company',imgName:'topic_thum_69.png'},
  {label: '사용자관리', link: '/user/list', super: true, division: '/user',imgName:'topic_thum_65.png'},
  {label: '락커관리', link: '/locker/use/usestatus', division: '/locker',imgName:'topic_thum_57.png'},
  {label: '회원관리', link: '/member/list', division: '/member',imgName:'topic_thum_75.png'},
  {label: '관리자', link: '/admin/setting', division: '/admin',imgName:'topic_thum_73.png'},
  {label: '모티터링', link: '/monitoring/view', division: '/monitoring',imgName:'topic_thum_55.png'},
]);

function logout() {
  if (confirm("로그아웃 하시겠습니까?")) {
    alert("로그아웃 되었습니다.");
    authStore.logout();
    router.push('/login');
  }
}

function isRendering(isSuperPage) {
  if (!isSuperPage) return true;
  return isSuperAdmin.value;
}

</script>

<style scoped>

.active-myinfo{
  color:#fff;
}
</style>
