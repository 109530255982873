// 업체유저  요청 페이징 API
import {storeUserRequests} from "@/api/index";
import {formatDate} from "@/util/formatUtils";

function fetchStoreUserRequests(companyId,searchInfo,filterInfo,orderInfo,pagination){
    return storeUserRequests.get('',{
        params:{
            companyId,
            approvalStatus: filterInfo.approvalStatus,
            filterDateBy: filterInfo.filterDateBy,
            startDate: formatDate(filterInfo.dateRange[0]||null),
            endDate: formatDate(filterInfo.dateRange[1]||null),
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            orderKey: orderInfo.orderKey,
            orderValue: orderInfo.orderValue,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
        }
    });
}

// 업체유저 요청 승인 API(다건도 고려한 API)
function approveStoreUsers(storeUserRequestIds){
    return storeUserRequests.post('/approve',{
        storeUserRequestIds
    });
}

// 업체유저 요청 반려 API
function approveReject(id,rejectionReason){
    return storeUserRequests.patch(`/${id}/reject`,{rejectionReason})
}

// 반려 사유 조회 API
function fetchRejectionReason(id){
    return storeUserRequests.get(`/${id}/rejection-reason`);
}

export {
    fetchStoreUserRequests,
    approveStoreUsers,
    approveReject,
    fetchRejectionReason,
}