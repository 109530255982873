import {policies} from "@/api/index";
import {formatDate, formatDateTime} from "@/util/formatUtils";

// 이용약관
export function fetchTermsList(filterInfo, pagination, orderInfo){
  return policies.get('/terms-of-services', {
      params: {
          pageNumber: pagination.pageNumber,
          pageSize: pagination.pageSize,
          startDate: filterInfo.dateRange ? formatDate(filterInfo.dateRange[0]) : null,
          endDate: filterInfo.dateRange ? formatDate(filterInfo.dateRange[1]) : null,
          isActive: filterInfo.isActive,
          orderKey: orderInfo.orderKey,
          orderValue: orderInfo.orderValue,
      }
  });
}

export function registerTerms(termsInfo){
  return policies.post('/terms-of-services', termsInfo);
}

export function fetchTerms(id){
  return policies.get(`/terms-of-services/${id}`);
}

export function modifyTerms(id, termsInfo){
  return policies.put(`/terms-of-services/${id}`, termsInfo);
}

export function changeTermsStatus(termsIds, isActive){
  return policies.patch('/terms-of-services/active-status', {
    termsOfServiceIds: termsIds,
    isActive: isActive,
  });
}

export function removeTermsList(termsIds){
  return policies.delete('/terms-of-services', {
      params: {ids: termsIds}
  });
}

export function checkEffectiveDateTerms(effectiveDate){
  return policies.get('/terms-of-services/check-effective-date', {params: {effectiveDate: effectiveDate}});
}



// 개인정보 처리방침
export function fetchPrivacyList(filterInfo, pagination, orderInfo){
  return policies.get('/privacy-policies', {
      params: {
          pageNumber: pagination.pageNumber,
          pageSize: pagination.pageSize,
          startDate: filterInfo.dateRange ? formatDate(filterInfo.dateRange[0]) : null,
          endDate: filterInfo.dateRange ? formatDate(filterInfo.dateRange[1]) : null,
          isActive: filterInfo.isActive,
          orderKey: orderInfo.orderKey,
          orderValue: orderInfo.orderValue,
      }
  });
}

export function registerPrivacy(termsInfo){
  return policies.post('/privacy-policies', termsInfo);
}

export function fetchPrivacy(id){
  return policies.get(`/privacy-policies/${id}`);
}

export function modifyPrivacy(id, termsInfo){
  return policies.put(`/privacy-policies/${id}`, termsInfo);
}

export function changePrivacyStatus(termsIds, isActive){
  return policies.patch('/privacy-policies/active-status', {
    privacyPolicyIds: termsIds,
    isActive: isActive,
  });
}

export function removePrivacyList(termsIds){
  return policies.delete('/privacy-policies', {
      params: {ids: termsIds}
  });
}

export function checkEffectiveDatePrivacy(effectiveDate){
  return policies.get('/privacy-policies/check-effective-date', {params: {effectiveDate: effectiveDate}});
}