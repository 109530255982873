// 로그인 페이지
import LoginPage from "@/views/LoginPage.vue";

// 내 정보 관련 페이지
import MyInfoManagement from "@/views/myinfo/MyInfoManagement.vue";
import MyInfoView from "@/views/myinfo/MyInfoEdit.vue";

// 업체관리 관련 페이지
import CompanyManagement from "@/views/company/CompanyManagement.vue";
import CompanyList from "@/views/company/CompanyList.vue";
import CompanyAdd from "@/views/company/CompanyAdd.vue";
import CompanyView from "@/views/company/CompanyView.vue";

// 사용자 관리 관련 페이지
import UserManagement from "@/views/user/UserManagement.vue";
import UserList from "@/views/user/UserList.vue";
import UserView from "@/views/user/UserView.vue";
import UserWithdrawList from "@/views/user/UserWithdrawList.vue";

// 락카관리 // 락커사용관리
import LockerManagement from "@/views/locker/LockerManagement.vue";
import LockerUseStatus from "@/views/locker/use/LockerUseStatus.vue";
import LockerUseControlStatus from "@/views/locker/use/LockerUseControlStatus.vue";
import LockerUseAssignmentHistory from "@/views/locker/use/LockerUseAssignmentHistory.vue";

// 락카관리 // 락커관리
import LockerAdminList from "@/views/locker/admin/LockerAdminList.vue";
import LockerAdminView from "@/views/locker/admin/LockerAdminView.vue";
import LockerAdminAdd from "@/views/locker/admin/LockerAdminAdd.vue";
import LockerAdminBatchAdd from "@/views/locker/admin/LockerAdminBatchAdd.vue";

// 락카관리 // 락커구역관리
import LockerAreaList from "@/views/locker/area/LockerAreaList.vue";
import LockerAreaView from "@/views/locker/area/LockerAreaView.vue";
import LockerAreaAdd from "@/views/locker/area/LockerAreaAdd.vue";

// 락카관리 // 락커중계기관리
import LockerRelayList from "@/views/locker/relay/LockerRelayList.vue";
import LockerRelayView from "@/views/locker/relay/LockerRelayView.vue";
import LockerRelayAdd from "@/views/locker/relay/LockerRelayAdd.vue";

// 회원관리
import MemberManagement from "@/views/member/MemberManagement.vue";
import MemberList from "@/views/member/MemberList.vue";
import MemberAdd from "@/views/member/MemberAdd.vue";
import MemberView from "@/views/member/MemberView.vue";
import MemberEdit from "@/views/member/MemberEdit.vue";
import MemberApprovalList from "@/views/member/MemberApprovalList.vue";

// 설정 관련 페이지
import AdminManagement from "@/views/admin/AdminManagement.vue";
import AdminNoticeList from "@/views/admin/notice/list.vue";
import AdminNoticeAdd from "@/views/admin/notice/add.vue";
import AdminNoticeView from "@/views/admin/notice/view.vue";
import AdminPrivacyList from "@/views/admin/privacy/AdminPrivacyList.vue";
import AdminPrivacyAdd from "@/views/admin/privacy/AdminPrivacyAdd.vue";
import AdminPrivacyView from "@/views/admin/privacy/AdminPrivacyView.vue";
import AdminTermsofuseList from "@/views/admin/termsofuse/AdminTermsOfUseList.vue";
import AdminTermsofuseAdd from "@/views/admin/termsofuse/AdminTermsOfUseAdd.vue";
import AdminTermsofuseView from "@/views/admin/termsofuse/AdminTermsOfUseView.vue";
import AdminSetting from "@/views/admin/AdminSetting.vue";
import AdminSettingHoliday from "@/views/admin/AdminSettingHoliday.vue";

// 모니터링 관련 페이지
import MonitoringManagement from "@/views/monitoring/MonitoringManagement.vue";
import MonitoringView from "@/views/monitoring/MonitoringView.vue";

import HeaderMenu from "@/components/Layout/HeaderMenu.vue";
import FooterMenu from "@/components/Layout/FooterMenu.vue";
import MyinfoLeftMenu from "@/components/Layout/Myinfo_Left.vue";
import CompanyLeftMenu from "@/components/Layout/Company_Left.vue";
import LockerLeftMenu from "@/components/Layout/Locker_Left.vue";
import UserLeftMenu from "@/components/Layout/User_Left.vue";
import MemberLeftMenu from "@/components/Layout/Member_Left.vue";
import AdminLeftMenu from "@/components/Layout/Admin_Left.vue";
import MonitoringLeftMenu from "@/components/Layout/Monitoring_Left.vue";

import { createRouter, createWebHistory } from "vue-router";
import {useAuthStore} from "@/store/auth";

const routes = [
  {
    path: "/",
    redirect: '/locker/use/usestatus'
  },
  {
    path: "/login",
    name: "LoginPage",
    component: LoginPage,
  },
  {
    path: "/layout/header",
    name: "HeaderMenu",
    component: HeaderMenu,
  },
  {
    path: "/layout/footer",
    name: "FooterMenu",
    component: FooterMenu,
  },
  {
    path: "/layout/myinfo",
    name: "MyinfoLeftMenu",
    component: MyinfoLeftMenu,
  },
  {
    path: "/layout/company",
    name: "CompanyLeftMenu",
    component: CompanyLeftMenu,
  },
  {
    path: "/layout/user",
    name: "UserLeftMenu",
    component: UserLeftMenu,
  },
  {
    path: "/layout/locker",
    name: "LockerLeftMenu",
    component: LockerLeftMenu,
  },
  {
    path: "/layout/member",
    name: "MemberLeftMenu",
    component: MemberLeftMenu,
  },
  {
    path: "/layout/admin",
    name: "AdminLeftMenu",
    component: AdminLeftMenu,
  },
  {
    path: "/layout/monitoring",
    name: "MonitoringLeftMenu",
    component: MonitoringLeftMenu,
  },
  {
    path: "/myinfo/management",
    name: "MyInfoManagement",
    component: MyInfoManagement,
    children: [
      {
        path: "",
        name: "Default",
        component: MyInfoView, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/myinfo/view",
        name: "MyInfoView",
        component: MyInfoView,
      },
    ],
  },
  {
    path: "/company/management",
    name: "CompanyManagement",
    component: CompanyManagement,
    meta: {super:true},
    children: [
      {
        path: "",
        redirect: '/company/list'
        // name: "CompanyDefault",
        // component: CompanyList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/company/list",
        name: "CompanyList",
        component: CompanyList,
      },
      {
        path: "/company/add",
        name: "CompanyAdd",
        component: CompanyAdd,
      },
      {
        path: "/company/view/:companyId", // 해당 페이지로 이동할때 파라미터가 필요할 경우
        name: "CompanyView",
        component: CompanyView,
        props: true,
      },
    ],
  },
  {
    path: "/user/management",
    name: "UserManagement",
    component: UserManagement,
    meta: {super:true},
    children: [
      {
        path: "",
        redirect:'/user/list'
        // name: "UserDefault",
        // component: UserList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/user/list",
        name: "UserList",
        component: UserList,
      },
      {
        path: "/user/view/:userId",
        name: "UserView",
        component: UserView,
      },
      {
        path: "/user/withdraw/list",
        name: "UserWithdrawList",
        component: UserWithdrawList,
      },
    ],
  },
  {
    path: "/locker/management",
    name: "LockerManagement",
    component: LockerManagement,
    children: [
      {
        path: "",
        redirect: '/locker/use/usestatus'
        // name: "LockerDefault",
        // component: LockerUseStatus, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/locker/use/usestatus",
        name: "LockerUseStatus",
        component: LockerUseStatus,
      },
      {
        path: "/locker/use/assignmenthistory",
        name: "LockerUseAssignmentHistory",
        component: LockerUseAssignmentHistory,
      },
      {
        path: "/locker/use/controlstatus",
        name: "LockerUseControlStatus",
        component: LockerUseControlStatus,
      },
      {
        path: "/locker/admin/list",
        name: "LockerAdminList",
        component: LockerAdminList,
      },
      {
        path: "/locker/admin/add",
        name: "LockerAdminAdd",
        component: LockerAdminAdd,
      },
      {
        path: "/locker/admin/view/:lockerId",
        name: "LockerAdminView",
        component: LockerAdminView,
      },
      {
        path: "/locker/admin/batchadd",
        name: "LockerAdminBatchAdd",
        component: LockerAdminBatchAdd,
      },
      {
        path: "/locker/area/list",
        name: "LockerAreaList",
        component: LockerAreaList,
      },
      {
        path: "/locker/area/add",
        name: "LockerAreaAdd",
        component: LockerAreaAdd,
      },
      {
        path: "/locker/area/view/:areaId",
        name: "LockerAreaView",
        component: LockerAreaView,
      },
      {
        path: "/locker/relay/list",
        name: "LockerRelayList",
        component: LockerRelayList,
      },
      {
        path: "/locker/relay/add",
        name: "LockerRelayAdd",
        component: LockerRelayAdd,
      },
      {
        path: "/locker/relay/view/:tabletId",
        name: "LockerRelayView",
        component: LockerRelayView,
      },
    ],
  },
  {
    path: "/member/management",
    name: "MemberManagement",
    component: MemberManagement,
    children: [
      {
        path: "",
        redirect: '/member/list'
        // name: "MemberDefault",
        // component: MemberList, // 기본적으로 렌더링될 컴포넌트
      },
      {
        path: "/member/list",
        name: "MemberList",
        component: MemberList,
      },
      {
        path: "/member/add",
        name: "MemberAdd",
        component: MemberAdd,
      },
      {
        path: "/member/view/:memberId",
        name: "MemberView",
        component: MemberView,
        props: true,
      },
      {
        path: "/member/edit/:memberId",
        name: "MemberEdit",
        component: MemberEdit,
        props: true,
      },
      {
        path: "/member/approval/list",
        name: "MemberApprovalList",
        component: MemberApprovalList,
      },
    ],
  },
  {
    path: "/admin/management",
    name: "admin",
    component: AdminManagement,
    children: [
      {
        path: "",
        redirect: '/admin/setting'
      },
      {
        path: "/admin/notice/list",
        name: "AdminNoticeList",
        component: AdminNoticeList,
        meta: {super:true},
      },
      {
        path: "/admin/notice/add",
        name: "AdminNoticeAdd",
        component: AdminNoticeAdd,
        meta: {super:true},
      },
      {
        path: "/admin/notice/view",
        name: "AdminNoticeView",
        component: AdminNoticeView,
        meta: {super:true},
      },
      {
        path: "/admin/privacy/list",
        name: "AdminPrivacyList",
        component: AdminPrivacyList,
        meta: {super:true},
      },
      {
        path: "/admin/privacy/add",
        name: "AdminPrivacyAdd",
        component: AdminPrivacyAdd,
        meta: {super:true},
      },
      {
        path: "/admin/privacy/view/:id",
        name: "AdminPrivacyView",
        component: AdminPrivacyView,
        meta: {super:true},
      },
      {
        path: "/admin/termsofuse/list",
        name: "AdminTermsofuseList",
        component: AdminTermsofuseList,
        meta: {super:true},
      },
      {
        path: "/admin/termsofuse/add",
        name: "AdminTermsofuseAdd",
        component: AdminTermsofuseAdd,
        meta: {super:true},
      },
      {
        path: "/admin/termsofuse/view/:id",
        name: "AdminTermsofuseView",
        component: AdminTermsofuseView,
        meta: {super:true},
      },
      {
        path: "/admin/setting",
        name: "AdminSetting",
        component: AdminSetting,
      },
      {
        path: "/admin/settingtime",
        name: "AdminSettingTime",
        component: AdminSettingHoliday,
      },
    ],
  },
  {
    path: "/monitoring/management",
    name: "monitoring",
    component: MonitoringManagement,
    children: [
      {
        path: "/monitoring/view",
        name: "MonitoringView",
        component: MonitoringView,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

// 전역 네비게이션 가드
router.beforeEach(async (to, from, next) => {
  const authStore = useAuthStore();
  if(to.path==="/login"){
    next();
    return;
  }
  if (!authStore.isLogin) {
    next("/login");
    return ;
  }
  if(to.meta.super && !authStore.isSuperAdmin){
    // alert('접근 권한이 없는 관리자 입니다.');
    next("/");
    return;
  }

  // 새로고침 될 때에 전역상태에 companyId가 null 들어가면서 조회시 로그인된 업체id에대한 조건이 없어지는 경우방지
  try {
    // companyId가 없을 경우에만 hydrate
    if (authStore.companyId === null) {
      await authStore.hydrate();
    }
    next(); // 모든 조건을 통과했을 때만 라우팅
  } catch (error) {
    next("/login"); // 에러가 발생하면 로그인으로 리다이렉트
  }
});

export default router;
