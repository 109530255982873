import {lockers} from "@/api";
import {formatDate, formatDateTime} from "@/util/formatUtils";

// 구역별 락커번호 중복여부 체크 API
export function checkLockerNumber(areaId,lockerNum) {
    return lockers.get(`/locker-num`, {
        params: {areaId,lockerNum}
    });
}

// 시리얼번호 중복여부 체크API
export function checkSerialNumber(serialNumber){
    return lockers.get('/serial-number',{params:{serialNumber}});
}

// 락커 등록 API
export function registerLocker(lockerInfo){
    return lockers.post('',lockerInfo);
}

// 락커 페이징 조회
export function fetchLockers(companyId,areaId,tabletId,searchInfo,pagination){
    return lockers.get('',{
        params:{
            companyId,areaId, tabletId,
            batteryStatus: searchInfo.batteryStatus,
            isActive: searchInfo.isActive,
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
        }
    });
}

// 락커 삭제 (lockerIds는 문자열. ','로 구분하여 여러개 삭제 가능 ex)1,2,3 or 5 )
export function removeLockers(lockerIds){
    return lockers.delete('',{
        params:{ids:lockerIds}
    });
}

export function modifyLockerActive(id,isActive){
    return lockers.patch(`/${id}/active`,{isActive});
}

// 락커 id로 락커 정보 조회
export function fetchLocker(lockerIds){
    return lockers.get(`/${lockerIds}`);
}

// 락커 수정 API
export function modifyLocker(lockerIds, lockerInfo){
    return lockers.patch(`/${lockerIds}`, lockerInfo);
}

// 다건의 락커번호와 시리얼번호 중복 여부 확인 API
export function checkLockerDuplicates(lockerList) {
    // 기존 데이터에서 api요청에 필요한 데이터로만 변환
    const requestData = lockerList.map(row =>
        (({__rowNum__: number, areaId, lockerNum, serialNumber}) =>
            ({number, areaId, lockerNum:String(lockerNum), serialNumber:String(serialNumber)}))(row)
    );
    return lockers.post('/check-duplicates', {
        requests: requestData
    });
}

// 락커 다건 등록 API(최대 200건)
export function registerLockerBulk(lockerList) {
    const requestData = lockerList.map(row =>
        (({companyId, areaId, tabletId, lockerNum, serialNumber, pinNumber}) =>
            ({companyId, areaId, tabletId, lockerNum:String(lockerNum), serialNumber:String(serialNumber), pinNumber}))(row)
    );

    return lockers.post('/bulk', {
        requests: requestData,
    });
}

// 락커 제어이력
export function fetchLockerControlLogs(companyId, filterInfo, orderInfo, searchInfo, pagination){
    return lockers.get('/logs', {
        params: {
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            companyId,
            controlSource: filterInfo.controlSource,
            startDate: filterInfo.dateRange ? formatDate(filterInfo.dateRange[0]) : null,
            endDate: filterInfo.dateRange ? formatDate(filterInfo.dateRange[1]) : null,
            orderKey: orderInfo.orderKey,
            orderValue: orderInfo.orderValue,
        }
    });
  }
  