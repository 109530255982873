<template>
  <tr>
    <td class="no_mobile">{{assignmentInfo.number}}</td>
    <td class="seat o4r" v-if="authStore.isSuperAdmin">
      <span class="only_mobile">상호명 : </span>
      <span>{{assignmentInfo.companyName}}</span>
    </td>
    <td class="seat o5">
      <span class="only_mobile">구분 : </span>
      <template v-if="assignmentInfo.usageEventType.includes('extend')">
        <span class="text_blue bold">연장</span>
        <br />
        <span v-if="assignmentInfo.usageEventType === 'extendByAdmin'" class="text_grey">(관리자)</span>
        <span v-else class="text_grey">(사용자)</span>
      </template>
      <span v-else-if="assignmentInfo.usageEventType === 'assign'" class="bold">배정</span>
      <span v-else-if="assignmentInfo.usageEventType === 'forcedRetrieval'" class="text_green bold">강제회수</span>
      <span v-else-if="assignmentInfo.usageEventType === 'return'" class="text_grey bold">반납</span>
    </td>
    <td class="o_title o2">
      <span class="only_mobile">락커번호 : </span>
      <span>[{{assignmentInfo.areaName}}] {{assignmentInfo.lockerNum}}</span>
    </td>
    <td class="seat o3 detail_page_a">
      <span class="only_mobile">이름 : </span>
      <div class="center_td">
        <router-link :to="`/member/view/${assignmentInfo.storeUserId}`" class="center_td">
          <p class="text_bold long_p">{{assignmentInfo.storeUserName}}</p>
          <img src="@/images/detail_page.png" />
        </router-link>
      </div>
    </td>
    <td class="o1 o_br">{{lockerType}}</td>
    <td class="num seat o7r">
      <span class="only_mobile">처리일시 : </span>
      <span>{{formatDateTime(assignmentInfo.createdAt)}}</span>
    </td>
    <td class="num seat o7">
      <span class="only_mobile">시작일시 : </span>
      <span>{{isDaily?formatDateTime(assignmentInfo.usageStartTime):formatDate(assignmentInfo.usageStartTime)}}</span>
    </td>
    <td class="num seat o7r">
      <span class="only_mobile">만료일시 : </span>
      <span>{{isDaily?formatDateTime(assignmentInfo.usageEndTime):formatDate(assignmentInfo.usageEndTime)}}</span>
    </td>

  </tr>
</template>

<script setup>

import {useAuthStore} from "@/store/auth";
import {formatDate, formatDateTime} from "@/util/formatUtils";
import {computed} from "vue";

const authStore = useAuthStore();
const props = defineProps({
  assignmentInfo:{
    type:Object,
  }
});

const isDaily = computed(()=>props.assignmentInfo.usageType==='daily');
const lockerType = computed(()=>isDaily.value?'일일락커':'개인락커');



</script>

<style scoped>

</style>