<template>
  <div class="w_250">
    <input
        :type="isShow?'text':'password'"
        class="w_250"
        :placeholder="placeholder"
        v-model="password"
        ref="passwordInput"
    />
    <span class="visibility" @click="()=>isShow = !isShow">
      <img v-show="!isShow" src="@/images/visibility.png" alt="">
      <img v-show="isShow" src="@/images/visibility_off.png" alt="">
    </span>
  </div>
</template>

<script setup>
import {ref} from "vue";

const props = defineProps({
  placeholder:{
    type: String,
    required: false,
    default: '비밀번호를 입력하세요'
  }
});
const isShow = ref(false);
const password = defineModel();
const passwordInput = ref(null);

defineExpose({
  inputFocus: () => passwordInput.value?.focus(),
});

</script>

<style scoped>
div {
  position: relative;
}
img{
  width: 24px;
}
.visibility{
  position: absolute;
  left: 87.5%;
  top: 14%;
  cursor:pointer;
}
input{
  padding-right: 40px;
}

</style>