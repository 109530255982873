export const companyListSortFields = [
    {label: '번호'}, {label: '상호명', key: 'companyName', direction: null},
    {label: '관리자계정', key: 'loginId', direction: null},
    {label: '생성일', key: 'createdAt', direction: null}, {label: '활성화 여부'},
];
export const memberListSortFields = [
    {label: '번호'},
    {label: '상호명', key: 'companyName', direction: null, super: true},
    {label: '이름', key: 'storeUserName', direction: null},
    {label: '휴대폰번호', key: 'storeUserPhoneNumber', direction: null},
    {label: '락커번호'}, {label: '배정락커 수'},
    {label: '배정이력'}, {label: '상태'},
];

export const memberApprovalListSortFields = [
    {label: '번호'},
    {label: '상호명', key: 'companyName', direction: null, super: true},
    {label: '이름', key: 'storeUserName', direction: null},
    {label: '휴대폰번호', key: 'storeUserPhoneNumber', direction: null},
    {label: '요청일시', key: 'requestedAt', direction: null},
    {label: '처리일시', key: 'processedAt', direction: null},
    {label: '상태'},
    {label: '관리'},
];

export const lockerListSortFields = [
    {label: '번호'},
    {label: '상호명', super: true},
    {label: '락커번호'},
    {label: '중계기'},
    {label: '시리얼넘버/\nPIN번호'},
    {label: '배터리상태'},
    {label: '락커제어'},
    {label: '활성화여부'},
];

export const lockerUsageStatusListSortFields = [
    {label: '상호명', key: 'companyName', direction: null, super: true},
    {label: '락커번호'},
    {label: '시작일시', key: 'usageStartTime', direction: null},
    {label: '만료일시', key: 'usageEndTime', direction: null},
    {label: '유형'},
    {label: '이름', key: 'storeUserName', direction: null},
    {label: '사용상태'},
    {label: '관리'},
];

export const areaListSortFields = [
    {label: '번호'},
    {label: '상호명', key: 'companyName', direction: null, super: true},
    {label: '구역명', key: 'areaName', direction: null},
    {label: '등록된 락커'},
];

export const lockerBatchFields = [
    {label: '번호'},
    {label: '락커번호'},
    {label: '시리얼넘버'},
    {label: 'PIN번호'},
    {label: '중계기'},
    {label: '상태'},
    {label: '삭제'},
];

export const tabletListSortFields = [
    {label: '번호'},
    {label: '상호명', key: 'companyName', direction: null, super: true},
    {label: '중계기명', key: 'tabletName', direction: null},
    {label: '시리얼넘버', key: 'serialNumber', direction: null},
    {label: 'IP주소', key: 'ipAddress', direction: null},
    {label: '설치구역명', key: 'areaName', direction: null},
    {label: '락커 목록'},
];

export const memberLockerUsageStatusListSortFields = [
    {label: '등록일'},
    {label: '유형'},
    {label: '락커정보'},
    {label: '이용기간'},
    {label: '상태'},
    {label: '관리'},
];

export const lockerControlStatusListSortFields = [
    {label: '번호'},
    {label: '상호명', key: 'companyName', direction: null, super: true},
    {label: '제어일시', key:'createdAt', direction: null},
    {label: '제어유형'},
    {label: '락커번호', key: 'lockerNum', direction: null},
    {label: '회원명', key: 'storeUserName', direction: null},
];

export const termsListSortFields = [
    {label: '번호'},
    {label: '시행일', key: 'effectiveDate', direction: null},
    {label: '상태'},
];

export const userListSortFields = [
    {label: '번호'},
    {label: '이메일',},
    {label: '이름', key: 'name', direction: null},
    {label: '휴대폰번호',},
    {label: '가입수단',},
    {label: '가입일', key: 'createdAt', direction: null},
    {label: '수정일', key: 'updatedAt', direction: null},
    {label: '간편\n비밀번호'},
    {label: '관리'},
];

export const withdrawListSortFields = [
    {label: '번호'},
    {label: '이메일',},
    {label: '이름', key: 'name', direction: null},
    {label: '휴대폰번호',},
    {label: '가입수단',},
    {label: '탈퇴유형',},
    {label: '탈퇴일', key: 'deletedAt', direction: null},
    {label: '관리'},
];

// 상호명, 이용기간, 이름, 휴대폰번호 정렬
export const assignmentHistoryListSortFields = [
    {label: '번호'},
    {label: '상호명', key: 'companyName', direction: null, super: true},
    {label: '구분',},
    {label: '락커번호'},
    {label: '이름', key: 'storeUserName', direction: null},
    {label: '유형',},
    {label: '처리일시',key: 'createdAt', direction: null},
    {label: '시작일시', key: 'usageStartTime', direction: null},
    {label: '만료일시',key: 'usageEndTime', direction: null},
];