<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_54.png" alt="" />모니터링
    </div>
    <div class="left_body">
      <ul class="menu">
        <li  v-for="page in pages">
          <a @click.prevent="navigateWithReload(page.link)"
             class="link" :class="{ on: checkSideActive(page.matchLinks)}"
          >
            {{ page.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";
import {ref} from "vue";
import {useRouteHandle} from "@/composables/routeHadler";

const {navigateWithReload} = useRouteHandle();

const pages = ref([
  {label:'현황',link:'/monitoring/view',matchLinks:['/monitoring/view']},
]);
</script>

<style scoped></style>
