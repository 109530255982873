<template>
  <div>
    <div class="container">
      <!-- 헤더 -->
      <keep-alive>
        <HeaderMenu />
      </keep-alive>
      <!-- //헤더 -->

      <!-- 바디 -->
      <div class="body">
        <div class="body_box">
          <!-- 바디레프트 -->
          <MyInfoLeftMenu />
          <!-- //바디레프트 -->

          <!-- 바디라이트 -->
          <router-view :key="route.fullPath"/>
          <!-- //바디라이트 -->
        </div>
      </div>
      <!-- //바디 -->

      <!-- 푸터 -->
      <FooterMenu />
      <!-- //푸터 -->
    </div>
  </div>
</template>

<script setup>
import HeaderMenu from "@/components/Layout/HeaderMenu.vue";
import MyInfoLeftMenu from "@/components/Layout/Myinfo_Left.vue";
import FooterMenu from "@/components/Layout/FooterMenu.vue";
import {useRoute} from "vue-router";

const route = useRoute();
</script>

<style></style>
