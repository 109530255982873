<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">내 정보</div>
        <div class="description">내 정보를 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 15%" class="colspan25"/>
                <col/>
              </colgroup>
              <tbody>
              <tr>
                <th>이름</th>
                <td>
                  <div class="inner_td">{{ name }}</div>
                </td>
              </tr>
              <tr>
                <th>아이디</th>
                <td>
                  <div class="inner_td">{{ loginId }}</div>
                </td>
              </tr>
              <tr>
                <th>기존비밀번호</th>
                <td>
                  <PasswordInput v-model="currentPassword" :placeholder="'기존 비밀번호를 입력하세요'"/>
                </td>
              </tr>
              <tr>
                <th>변경할 비밀번호</th>
                <td class="flex align-center">
                  <PasswordInput v-model="newPassword" :placeholder="'변경할 비밀번호를 입려하세요'"/>
                </td>
              </tr>
              <tr>
                <th>비밀번호확인</th>
                <td class="flex align-center">
                  <PasswordInput v-model="checkPassword"
                                 :placeholder="'비밀번호를 한번 더 입력하세요'"/>
                  <div class="validation text_red">
                    {{ pwCheckMsg }}
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <button :disabled="!isValidPassword"
                    type="button" class="on"
                    :class="{disabled: !isValidPassword}"
                    @click="editPassword">
              저장
            </button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
// import {useRouter} from "vue-router";
import {computed, onMounted, ref, watch} from "vue";
import {fetchMyInfo, modifyManagedUserPassword} from "@/api/managedUser";
import {useRouter} from "vue-router";
import PasswordInput from "@/components/common/PasswordInput.vue";
import {useAuthStore} from "@/store/auth";

const router = useRouter();
const authStore = useAuthStore();

const loginId = ref("");
const name = ref("");
const currentPassword = ref("");
const newPassword = ref("");
const checkPassword = ref("");

const pwCheckMsg = ref('');

const isValidPassword = computed(()=>
    (newPassword.value !==''
    &&currentPassword.value !== ''
    &&checkPassword.value !==''
    && newPassword.value === checkPassword.value));

watch([newPassword, checkPassword],
    ([newPw, checkPw]) => {
      if (newPw === checkPw) {
        pwCheckMsg.value = '';
      } else {
        pwCheckMsg.value = '비밀번호와 비밀번호 확인이 일치하지 않습니다.'
      }
    }
)

onMounted(async () => {
  try {
    const resp = await fetchMyInfo();
    if (resp.status !== 200) {
      console.log(resp);
      await router.push('/login')
    }
    loginId.value = resp.data.loginId;
    name.value = resp.data.name;

  } catch (e) {
    console.error(e);
  }
});


async function editPassword() {
  try{
    const resp = await modifyManagedUserPassword(
        currentPassword.value,newPassword.value);
    if(resp.status === 204){
      alert('비밀번호 변경이 완료되었습니다.\n다시 로그인해 주세요');
      authStore.logout();
      await router.push('/login');
    }
  }catch (e){
    alert(e.response.data.details);
  }
}
</script>

<style scoped>
.readonly-input {
  background-color: #f5f5f5; /* 연한 회색 배경 */
  border: 1px solid #ccc; /* 연한 회색 테두리 */
  color: #333; /* 어두운 텍스트 색상 */
  cursor: not-allowed; /* 커서 모양 변경 */
}

.validation {
  margin-left: 8px;
}

.disabled,.disabled:hover{
  opacity: 0.5!important;
  cursor:no-drop!important;
}
</style>
