
// 사업자 등록번호 유효성 검사 10자리 숫자인지 확인
import {formatWithHyphen} from "@/util/formatUtils";

export function validateBusinessNum (value){
    if(typeof value === 'object'){
        value = formatWithHyphen(value);
    }
    return /([0-9]{3})-?([0-9]{2})-?([0-9]{5})/.test(value);
}

export function validatePhoneNum (value){
    if(typeof value === 'object'){
        value = formatWithHyphen(value);
    }
    return /([0-9]{3})-?([0-9]{4})-?([0-9]{4})/.test(value);
}

