<template>
  <tr>
    <td><input type="checkbox" v-model="memberInfo.isSelected"/></td>
    <td class="o1 num no_mobile">{{memberInfo.number}}</td>
    <td class="seat o3" v-if="authStore.isSuperAdmin">
      <span class="only_mobile">상호명 : </span>
      <span>{{memberInfo.companyName}}</span>
    </td>
    <td class="seat o2 o_title detail_page_a">
      <div class="center_td">
        <router-link :to="`/member/view/${memberInfo.storeUserId}`" class="center_td">
          <p class="text_bold long_p">{{memberInfo.storeUserName}}</p>
          <img src="@/images/detail_page.png"/>
        </router-link>
      </div>
    </td>
    <td class="num o7">
      <span class="only_mobile">휴대폰번호 : </span>
      <span>{{ toHyphenPhoneNumber(memberInfo.storeUserPhoneNumber) }}</span>
    </td>
    <td class="num o7">
      <span class="only_mobile">락커번호 : </span>
      <span>{{lockers}}</span>
    </td>
    <td class="num o7">
      <span class="only_mobile">배정락커 수 : </span>
      <span>{{memberInfo.lockerUsageCount}}</span>
    </td>
    <td class="o_stat">
      <button @click="goDetail" type="button">상세보기</button>
    </td>
    <td class="num o7">
      <span class="only_mobile">상태 : </span>
      <span class="bolder" :class="statusInfo.class">{{statusInfo.text}}</span>
    </td>
  </tr>
</template>

<script setup>

import {computed} from "vue";
import {useAuthStore} from "@/store/auth";
import {useRoute, useRouter} from "vue-router";
import {toHyphenPhoneNumber} from "@/util/formatUtils";

const router = useRouter();
const authStore = useAuthStore();
const props = defineProps({
  memberInfo:{
    type:Object,
    required:true
  }
});

const lockers = computed(()=>{

  return props.memberInfo.lockerUsageMetaDataList.length === 0?
      '-':
      props.memberInfo.lockerUsageMetaDataList
          .map(locker=>`[${locker.areaName}] ${locker.lockerNumber}`)
          .join(',\n');
})

const statusInfo = computed(() => {
  switch (props.memberInfo.accountLinkStatus) {
    case 'unlinked':
      return {
        class: 'text_yellow',
        text: '미연동'
      };
    case 'linked':
      return {
        class: 'text_green',
        text: '연동완료'
      };
    default:
      return {
        class: '',
        text: '연동해제'
      };
  }
});

function goDetail(){
  router.push({
    path:'/locker/use/assignmenthistory',
    query:{
      name: props.memberInfo.storeUserName
    }
  });
}



</script>

<style scoped>
.bolder {
  font-weight: bolder;
}
</style>