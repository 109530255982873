<template>
  <div class="count_num">
    <span>총</span>
    <span class="accent_color"> {{total}} </span>
    <span>{{unit}}</span>
    <select v-model="searchResultCount" @change="emit('resultCount')">
      <option v-for="count in counts" :value="count">{{count}}</option>
    </select>
    <span>{{unit}}씩</span>
  </div>
</template>

<script setup>


const {unit,total,defaultSize} = defineProps(['unit','total','defaultSize']);

const emit = defineEmits(['resultCount']);

const searchResultCount = defineModel('searchResultCount');

const counts = [5,10,30,50,100,200];


</script>

<style scoped>

</style>