<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_66.png" alt=""/>관리자
    </div>
    <div class="left_body">
      <ul class="menu">
        <template v-for="page in pages">
          <li v-if="isRendering(page.super)">
            <a class="link" @click.prevent="navigateWithReload(page.link)"
               :class="{ on: checkSideActive(page.matchLinks)}"
            >
              {{ page.label }}
            </a>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";
import {computed, ref} from "vue";
import {useAuthStore} from "@/store/auth";
import {useRouteHandle} from "@/composables/routeHadler";

const authStore = useAuthStore();
const isSuperAdmin = computed(() => authStore.isSuperAdmin);
const {navigateWithReload} = useRouteHandle();

const pages = ref([
  {label: '이용약관 목록', link: '/admin/termsofuse/list', super: true, matchLinks: ['/admin/termsofuse/list','/admin/termsofuse/add','/admin/termsofuse/view']},
  // {label: '이용약관 등록', link: '/admin/termsofuse/add', super: true, matchLinks: ['/admin/termsofuse/add']},
  // {label: '이용약관 상세보기', link: '/admin/termsofuse/view', super:true},
  {label: '개인정보처리방침 목록', link: '/admin/privacy/list', super: true, matchLinks: ['/admin/privacy/list','/admin/privacy/add','/admin/privacy/view']},
  // {label: '개인정보처리방침 등록', link: '/admin/privacy/add', super: true, matchLinks: ['/admin/privacy/add']},
  // {label: '개인정보처리방침 상세보기', link: '/admin/privacy/view', super:true},
  {label: '설정', link: '/admin/setting', matchLinks: ['/admin/setting']},
  // {label: '휴무일·영업시간 설정', link: '/admin/settingtime'},
]);

function isRendering(isSuperPage) {
  if (!isSuperPage) return true;
  return isSuperAdmin.value;
}


</script>

<style scoped></style>
