<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">개인정보처리방침 목록</div>
        <div class="description">개인정보처리방침을 관리 합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr>
                <th>기간별</th>
                <td>
                  <div class="inner_td">
                    <DatePicker class="w_300" v-model="filterInfo.dateRange"
                                :format="'yyyy-MM-dd'"
                                :enable-time-picker="false" auto-apply
                                placeholder="기간별 조회"
                                locale="ko" range
                                week-start="0"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>상태별</th>
                <td>
                  <FilterTabs :tabs="termsFilterOptions"
                              v-model:select-tab="filterInfo.status" 
                              @tab-selected="onStatusSelected"/>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="건"
                                 v-model:searchResultCount="pagination.pageSize"
                                 @resultCount="setPageSize"/>
            <button @click="router.push('/admin/privacy/add')" type="button">등록하기</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 6%"/>
                  <col style="width: 7%"/>
                  <col/>
                  <col style="width: 12%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th>
                    <input type="checkbox" id="checkAll" v-model="isAllSelected"/>
                  </th>
                  <th v-for="sortField in sortFieldsState" :key="sortField.key">
                    <TableHeaderItem :headData="sortField" @sort="setOrderBy" />
                  </th>
                </tr>
                </thead>
                <tbody>
                  <template v-if="termsList.length !==0">
                    <TermsListItem v-for="terms in termsList"
                                    :key="terms.privacyPolicyId"
                                    :termsInfo="terms"
                                    :detail-path="`/admin/privacy/view/${terms.privacyPolicyId}`" 
                    />
                  </template>
                  <tr class="no_data" v-else>
                    <td colspan="4">데이터가 없습니다</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="removePrivacy">삭제</a>
              <a @click.prevent="changeStatus(true)">표시</a>
              <a @click.prevent="changeStatus(false)">미표시</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {termsFilterOptions} from "@/util/filterFields";
import TermsListItem from "@/components/admin/TermsListItem.vue";
import {onMounted, ref, watch} from "vue";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import {useOrder} from "@/composables/orderBy";
import {termsListSortFields} from "@/util/sortFields";
import Pagination from "@/components/common/Pagination.vue";
import {useSelectAll} from "@/composables/selectAll";
import {changePrivacyStatus, fetchPrivacyList, removePrivacyList} from "@/api/policies";

const router = useRouter();
const termsList = ref([]);
const {sortFieldsState, getOrderBy, resetOrderBy, setOrderBy} = useOrder(termsListSortFields, fetchTermsInfo);
const {isAllSelected, getSelectedIds} = useSelectAll(termsList, 'privacyPolicyId');

const filterInfo = ref({
  status: null,
  dateRange: [],
});

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});

onMounted(async () => {
  await fetchTermsInfo();
});

watch([filterInfo,
  () => pagination.value.pageSize,
  () => pagination.value.pageNumber,
], fetchTermsInfo, {deep: true});

async function fetchTermsInfo() {
  try {

    const {data} = await fetchPrivacyList({
          isActive: filterInfo.value.status,
          dateRange: filterInfo.value.dateRange || [],
        }, {
          orderKey: getOrderBy()?.key,
          orderValue: getOrderBy()?.direction
        },
        pagination.value);
    console.log(data);
    pagination.value.pageNumber = data.pageNumber;
    pagination.value.pageSize = data.pageSize;
    pagination.value.totalCount = data.totalCount;

    termsList.value = data.privacyPolicyMetadataList;
    termsList.value.forEach(terms => terms.isSelected = false);
  } catch (e) {
    console.error(e);
  }
}

function onStatusSelected(statusValue){
  pagination.value.pageNumber = 1;
  resetOrderBy();
  fetchTermsInfo();
}

function setPageSize() {
  resetOrderBy();
  pagination.value.pageNumber = 1;
  fetchTermsInfo();
}

async function changeStatus(isActive) {
  const selectedIds  = getSelectedIds();
  const ids = selectedIds.map(id => Number(id));

  if (ids.length === 0) {
    alert('선택된 항목이 없습니다.')
    return;
  }
  
  try {
    const resp = await changePrivacyStatus(ids, isActive);
    console.log(resp)
    if (resp.status === 204) {
      alert('상태가 변경되었습니다.');
      await fetchTermsInfo();
    }
  } catch (error) {
    console.error(error);
  }
}

async function removePrivacy() {
  const ids = getSelectedIds();

  if(ids.length === 0){
    alert('선택된 항목이 없습니다.')
    return;
  }
  if(!confirm('삭제하시겠습니까?')) return;

  try{
    const resp = await removePrivacyList(ids.join(','));
    console.log(resp)
    if(resp.status === 204){
      alert('삭제되었습니다.');
      await fetchTermsInfo();
    }
  }catch(error){
    console.error(error);
  }
}
</script>

<style scoped>

</style>
