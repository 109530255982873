<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">업체 상세보기</div>
        <div class="description">(*) 표시는 필수 입력 정보입니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info" v-if="isDataLoaded">
            <CompanyInfo :isEdit="true"/>
          </div>
          <div class="tbl_search tbl_info">
            <ManagerInfo/>
          </div>
          <div class="tbl_search tbl_info" v-if="isDataLoaded">
            <ManagedUserAccountEdit/>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="editCompany" class="on">저장</a>
            <a @click.prevent="deleteCompany">삭제</a>
            <router-link to="/company/list">목록</router-link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>

import {onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
import CompanyInfo from "@/components/company/CompanyInfo.vue";
import ManagerInfo from "@/components/company/ManagerInfo.vue";
import ManagedUserAccountEdit from "@/components/company/ManagedUserAccountEdit.vue";
import {useCompanyStore} from "@/store/company";
import {nextTick, onMounted, ref, watch} from "vue";
import {modifyCompany, removeCompanys} from "@/api/company";
import {storeToRefs} from "pinia";

const router = useRouter();
const route = useRoute();
const companyStore = useCompanyStore();
const {findAddressBtn, addressInfo} = storeToRefs(companyStore);

const isEdited = ref(false);
const isChanged = ref(false);
const isDataLoaded = ref(false);

onMounted(async () => {
  // 상태 깊은 복사
  await companyStore.fetchCompanyData(route.params.companyId);
  isDataLoaded.value = true;
  // 초기 상태 이후의 변경만 감지

  let isInitialized = false; // 초기화 상태를 추적

  const unwatch = watch(
      () => companyStore.$state, // 감시 대상
      async () => {
        if (!isInitialized) {
          // 초기 로드가 완료된 후 감지 시작
          isInitialized = true;
          return;
        }
        await nextTick();
        isChanged.value = true;
        unwatch();
      },
      { deep: true } // 깊은 감시 옵션
  );
});


onBeforeRouteLeave((to, from, next) => {
  if (isChanged.value && !isEdited.value) {
    if (!confirm('변경사항이 저장되지 않을 수 있습니다. 계속 하시겠습니까?')) return;
  }
  companyStore.$reset();
  next();
});

async function editCompany() {
  if (!companyStore.checkRequiredFields()) {
    alert('필수 입력 정보를 모두 입력/확인해 주세요');
    return;
  } else if (!addressInfo.value.zipCode) {
    alert('필수 입력 정보를 모두 입력/확인해 주세요');
    findAddressBtn.value.click();
    return;
  }
  isEdited.value = true;
  try {
    const {status} = await modifyCompany(companyStore.companyId,
        companyStore.getEditData());
    if (status === 200) {
      alert('저장이 완료되었습니다.')
      await router.push('/company/list');
    }
  } catch (e) {
    console.error(e);
  }
}

async function deleteCompany() {
  if (!confirm('삭제하시겠습니까?')) return;
  isEdited.value = true;
  try {
    const resp = await removeCompanys(companyStore.companyId);
    if (resp.status === 204) {
      alert('삭제되었습니다.');
      await router.push('/company/list');
    }
  } catch (error) {
    console.error(error);
  }
}
</script>

<style scoped></style>
