import {lockerUsages} from "@/api";
import {formatDate, formatDateTime} from "@/util/formatUtils";

// 락커 이용 현황 페이징 API(검색, 필터, 정렬기능)
export function fetchLockerUsages(companyId,filterInfo,orderInfo,searchInfo,pagination){
    return lockerUsages.get('',{
        params:{
            companyId,
            filterDateBy:filterInfo.filterDateBy,
            startDate: filterInfo.dateRange?formatDate(filterInfo.dateRange[0]):null,
            endDate: filterInfo.dateRange?formatDate(filterInfo.dateRange[1]):null,
            usageType: filterInfo.usageType,
            usageStatus:filterInfo.usageStatus,
            orderKey: orderInfo.orderKey,
            orderValue: orderInfo.orderValue,
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
        }
    });
}

// 락커 이용 등록 API
export function registerLockerUsage(usageInfo){
    return lockerUsages.post('',usageInfo);
}

// 락커 사용 기간 연장 API
export function extensionLockerUsage(lockerUsageId,extensionDate){
    return lockerUsages.patch(`/${lockerUsageId}/extend`,{
        extensionDate: formatDateTime(extensionDate, '-','+09:00')
    });
}

// 락커 회수 API
export function retrieveLockerUsage(lockerUsageId){
    return lockerUsages.patch(`/${lockerUsageId}/retrieve`);
}

// 특정 업체유저 락커 이용현황 페이징API(사용 완료 데이터 포함)
export function fetchLockerUsagesList(storeUserId, pagination){
    return lockerUsages.get('/all',{
        params:{
            storeUserId,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
        }
    });
}

// 락커 이용 로그 페이징 API(검색, 필터, 정렬 가능)
export function fetchLockerUsageLogs(companyId,filterInfo,orderInfo,searchInfo,pagination){
    return lockerUsages.get('/logs',{
       params:{
           companyId,
           usageEventType: filterInfo.usageEventType,
           usageType: filterInfo.usageType,
           filterDateBy: filterInfo.filterDateBy,
           startDate: filterInfo.dateRange?formatDate(filterInfo.dateRange[0]):null,
           endDate: filterInfo.dateRange?formatDate(filterInfo.dateRange[1]):null,
           orderKey: orderInfo.orderKey,
           orderValue: orderInfo.orderValue,
           searchKey: searchInfo.searchKey,
           searchValue: searchInfo.searchValue,
           pageNumber: pagination.pageNumber,
           pageSize: pagination.pageSize,
       }
    });
}

// 락커 이용시간 수정 API
export function modifyLockerUsageTime(lockerUsageId,modifyInfo){
    return lockerUsages.patch(`/${lockerUsageId}/attributes`,modifyInfo);
}



