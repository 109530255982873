<template>
  <tr>
    <td class="o1">
      <input type="checkbox" :disabled="!isPending" v-model="approvalInfo.isSelected"/>
    </td>
    <td class="detail_page_a num no_mobile">
      <div class="center_td">
        <p class="text_bold long_p">{{ approvalInfo.number }}</p>
      </div>
    </td>
    <td class="seat o3" v-if="authStore.isSuperAdmin">
      <span class="only_mobile">상호명 : </span>
      <span>{{ approvalInfo.companyName }}</span>
    </td>
    <td class="seat o2 o_title">
      <span class="only_mobile">이름 : </span>
      <span>{{ approvalInfo.storeUserName || '-' }}</span>
    </td>
    <td class="num o7r">
      <span class="only_mobile">휴대폰번호 : </span>
      <span>{{ approvalInfo.storeUserPhoneNumber }}</span>
    </td>
    <td class="num o7">
      <span class="only_mobile">요청일시 : </span>
      <span>{{ timeToString(approvalInfo.requestedAt) || '-' }}</span>
    </td>
    <td class="num o7r">
      <span class="only_mobile">처리일시 : </span>
      <span>{{ timeToString(approvalInfo.processedAt) || '-' }}</span>
    </td>
    <td class="o7">
      <span class="only_mobile">상태 : </span>
      <span v-if="approvalInfo.approvalStatus === 'rejected'" class="bold"
            @click="emit('viewModalOn',approvalInfo.storeUserId)"
            :class="statusInfo.class">
        {{ statusInfo.text }}
      </span>
      <span v-else class="bold" :class="statusInfo.class">{{ statusInfo.text }}</span>
    </td>
    <td class="o_stat">
      <div class="center_td inner_td">
        <template v-if="isPending">
          <button type="button" @click="emit('approve',[props.approvalInfo.storeUserId])">승인</button>
          <!--        <button @click="writeModalOn" type="button">반려</button>-->
          <button type="button" @click="emit('rejectModalOn',approvalInfo.storeUserId)">반려</button>
        </template>
        <p v-else>-</p>
      </div>

    </td>
  </tr>
</template>

<script setup>

import {computed} from "vue";
import {timeToString} from "@/util/formatUtils";
import {useAuthStore} from "@/store/auth";

const authStore = useAuthStore();
const props = defineProps({
  approvalInfo: {
    type: Object,
    required: true
  }
});

const emit = defineEmits(['approve', 'rejectModalOn', 'viewModalOn']);

const isPending = computed(()=>props.approvalInfo.approvalStatus === 'pending')

const statusInfo = computed(() => {
  switch (props.approvalInfo.approvalStatus) {
    case'approved':
      return {
        class: 'text_green',
        text: '승인'
      };
    case'rejected':
      return {
        class: 'text_red underline',
        text: '반려'
      };
    default:
      return {
        class: 'text_blue',
        text: '미승인'
      };
  }
});


</script>

<style scoped>
.text_red.underline {
  color: #666;
  text-decoration-color: #e05353;
  cursor: pointer;
}
</style>