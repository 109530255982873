// 회원 승인페이지
export const memberStatusOption = [
    {label: '전체보기', value: null},
    {label: '승인', value: 'approved'},
    {label: '미승인', value: 'pending'},
    {label: '반려', value: 'rejected'},
];

// 락커 목록페이지
export const batteryStatusOptions = [
    {label: '전체보기', value: null},
    {label: '양호', value: 'good'}, {label: '교체필요', value: 'replace'},
]
export const activeStatusOptions = [
    {label: '전체보기', value: null},
    {label: '활성', value: true}, {label: '비활성', value: false},
]

// 락커 사용현황페이지
export const usageTypeOptions = [
    { label: '전체보기', value: null },
    { label: '일일락커', value: 'daily' },
    { label: '개인락커', value: 'personal' },
];

export const usageStatusOptions = [
    { label: '전체보기', value: null },
    { label: '사용가능', value: 'available' },
    { label: '사용중', value: 'inUse' },
    { label: '사용예정', value: 'plannedUse' },
    { label: '기간만료', value: 'expired' },
];

export const controlTypeOptions = [
    { label: '전체보기', value: null },
    { label: 'App', value: 'USER_APP' },
    { label: '중계기', value: 'TABLET' },
    { label: '관리자', value: 'ADMIN_WEB' },
];

export const termsFilterOptions = [
    {label:'전체보기',value:null},
    {label:'표시',value:true},
    {label:'미표시',value:false},
];

export const termsOptions = [
    {label:'표시',value:true},
    {label:'미표시',value:false},
];

export const signupOptions = [
    { label: '전체보기', value: null },
    { label: '이메일', value: 1 },
    { label: '카카오톡', value: 2 },
    { label: '네이버', value: 3 },
    { label: '구글', value: 4 },
    { label: '애플', value: 5 },
];

export const withdrawalTypeOptions = [
    { label: '전체보기', value: 'EXIT' },
    { label: '본인탈퇴', value: 'USER_EXIT' },
    { label: '강제탈퇴', value: 'ADMIN_EXIT' },
];

export const divisionOptions = [
    { label: '전체', value: null },
    { label: '배정', value: 'assign' },
    { label: '연장(관리자)', value: 'extendByAdmin' },
    { label: '연장(회원)', value: 'extendByUser' },
    { label: '강제회수', value: 'forcedRetrieval' },
    { label: '반납', value: 'return' },
];

export const assigmentOptions = [
    { label: '전체보기', value: null },
    { label: '일일락커', value: 'daily' },
    { label: '개인락커', value: 'personal' },
];

export const linkOptions = [
    {label: '전체보기', value: null}, {label: '미연동', value: 'unlinked'},
    {label: '연동완료', value: 'linked'}, {label: '연동해제', value: 'disconnected'},
];



