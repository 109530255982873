import {computed, ref} from "vue";

export function useSelectAll(items, idKey) {
    const selectItems = ref(items);

    const isAllSelected = computed({
        get: () => {
            const selectableItems = selectItems.value
                .filter(item => 'isSelected' in item); // isSelected 필드가 있는 항목만 대상

            return selectableItems.length > 0
                && selectItems.value.length > 0
                && selectableItems.every(item => item.isSelected);
        },
        set: (val) => selectItems.value.forEach(item => {
            if ('isSelected' in item) {
                item.isSelected = val
            }
        }),
    });

    const isNonAllSelectable = computed(() =>
        selectItems.value.filter(item => 'isSelected' in item).length <= 0);

    function getSelectedIds() {
        return selectItems.value
            .filter(item => item.isSelected && 'isSelected' in item)
            .map(item => item[idKey]);
    }

    return {isAllSelected,isNonAllSelectable, getSelectedIds};
}