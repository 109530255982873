<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">휴무일·영업시간 설정</div>
        <div class="description"></div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">업체선택</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>업체</th>
                  <td>
                    <div class="inner_td">
                      <select class="w_250">
                        <option>업체를 선택하세요</option>
                        <option selected>주식회사마이즈</option>
                      </select>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search left tbl_info">
            <div class="part_title">일일락커 설정</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>
                    <div class="reldiv">
                      정기휴무일
                      <InfoIcon width="5" :size="15" />
                      <div class="descbox">
                        월 단위로 발생하는 정기 휴무일을 설정합니다.
                        <br />요일별 정기 휴무일은 영업시간에서 설정해 주세요.
                      </div>
                    </div>
                  </th>
                  <td>
                    <div class="inner_td row">
                      <div class="row">
                        <span>정기휴무</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                          checked
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                        <select class="w_80">
                          <option>주기선택</option>
                          <option selected>매월 첫번째</option>
                          <option>매월 두번째</option>
                          <option>매월 세번째</option>
                          <option>매월 네번째</option>
                          <option>매월 다섯번째</option>
                        </select>
                        <select class="w_80">
                          <option>요일선택</option>
                          <option selected>월요일</option>
                          <option>화요일</option>
                          <option>수요일</option>
                          <option>목요일</option>
                          <option>금요일</option>
                          <option>토요일</option>
                          <option>일요일</option></select
                        ><button type="button">추가</button>
                      </div>
                      <div class="row">
                        <span></span>
                        <select class="w_80">
                          <option>주기선택</option>
                          <option selected>매월 첫번째</option>
                          <option>매월 두번째</option>
                          <option>매월 세번째</option>
                          <option>매월 네번째</option>
                          <option>매월 다섯번째</option>
                        </select>
                        <select class="w_80">
                          <option>요일선택</option>
                          <option>월요일</option>
                          <option>화요일</option>
                          <option>수요일</option>
                          <option>목요일</option>
                          <option>금요일</option>
                          <option>토요일</option>
                          <option>일요일</option></select
                        ><button type="button">추가</button
                        ><button type="button">삭제</button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>
                    <div class="reldiv">
                      임시휴무일
                      <InfoIcon width="5" :size="15" @click="" />
                      <div class="descbox">
                        업체 사정으로 발생하는 임시 휴무일을 설정합니다.
                        <br />기간이 지난 임시휴무일은 자동으로 삭제됩니다.
                      </div>
                    </div>
                  </th>
                  <td>
                    <div class="inner_td row">
                      <div class="row">
                        <span>임시휴무</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                          checked
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                        <input type="time" /> ~ <input type="time" /><button
                          type="button"
                        >
                          추가
                        </button>
                      </div>
                      <div class="row">
                        <span></span>
                        <input type="time" /> ~ <input type="time" /><button
                          type="button"
                        >
                          추가</button
                        ><button type="button">삭제</button>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search left tbl_info">
            <div class="part_title">영업시간 설정</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr>
                  <th>월요일</th>
                  <td>
                    <div class="inner_td row">
                      <div class="row">
                        <span>영업일</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                          checked
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                        <input type="time" /> ~ <input type="time" /><button
                          type="button"
                        >
                          추가
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>화요일</th>
                  <td>
                    <div class="inner_td row">
                      <div class="row">
                        <span>영업일</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                          checked
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                        <input type="time" /> ~ <input type="time" />
                      </div>
                    </div>
                    <div class="inner_td row">
                      <div class="row">
                        <span>휴게시간</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>수요일</th>
                  <td>
                    <div class="inner_td row">
                      <div class="row">
                        <span>영업일</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                          checked
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                        <input type="time" /> ~ <input type="time" />
                      </div>
                    </div>
                    <div class="inner_td row">
                      <div class="row">
                        <span>휴게시간</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                          checked
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                        <input type="time" /> ~ <input type="time" /><button
                          type="button"
                        >
                          추가
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>목요일</th>
                  <td>
                    <div class="inner_td row">
                      <div class="row">
                        <span>영업일</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                          checked
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                        <input type="time" /> ~ <input type="time" />
                      </div>
                    </div>
                    <div class="inner_td row">
                      <div class="row">
                        <span>휴게시간</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                          checked
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                        <input type="time" /> ~ <input type="time" /><button
                          type="button"
                        >
                          추가
                        </button>
                      </div>
                      <div class="row">
                        <span></span>
                        <input type="time" /> ~ <input type="time" /><button
                          type="button"
                        >
                          추가</button
                        ><button type="button">삭제</button>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>금요일</th>
                  <td>
                    <div class="inner_td row">
                      <div class="row">
                        <span>영업일</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>토요일</th>
                  <td>
                    <div class="inner_td row">
                      <div class="row">
                        <span>영업일</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>일요일</th>
                  <td>
                    <div class="inner_td row">
                      <div class="row">
                        <span>영업일</span>
                        <input
                          type="checkbox"
                          id="toggle"
                          class="toggle"
                          hidden
                        />
                        <label for="toggle" class="toggleSwitch">
                          <span class="toggleButton"></span>
                        </label>
                      </div>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="saveTimeSetting" href="###" class="on">설정저장</a>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import InfoIcon from "vue-material-design-icons/InformationVariantCircleOutline.vue";
import {useRouter} from "vue-router";

const router = useRouter();

function saveTimeSetting(){
  if(!alert('저장되었습니다.')){
    router.go(0)
  }
}
</script>

<style scoped></style>
