<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">회원 목록</div>
        <div class="description">회원을 조회하고 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr v-if="authStore.isSuperAdmin">
                <th>업체별</th>
                <td>
                  <div class="inner_td">
                    <select class="w_200" v-model="companyId">
                      <option :value="null">전체</option>
                      <option v-for="company in companys"
                              :value="company.companyId"
                      >
                        {{ company.companyName }}
                      </option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>상태별</th>
                <td>
                  <FilterTabs v-model:selectTab="searchInfo.filterValue"
                      :tabs="linkOptions"
                      @tab-selected="setPageSize"/>
                </td>
              </tr>
              <tr>
                <th>키워드검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="searchInfo.searchKey">
                      <option value="storeUserName">이름</option>
                      <option value="phoneNumber">휴대폰번호</option>
                    </select>
                    <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                        v-model="searchInfo.searchValue"
                        @keyup.enter="fetchMemberInfo"
                    />
                    <button @click="fetchMemberInfo" type="button">검색</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="명"
                                 v-model:searchResultCount="pagination.pageSize"
                                 @resultCount="setPageSize"/>
            <button @click="router.push('/member/add')" type="button">회원등록</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 7%;">
                  <col style="width: 4%;">
                  <col v-if="authStore.isSuperAdmin" style="width: 14%">
                  <col style="width: 10%;">
                  <col style="width: 14%;">
                  <col>
                  <col style="width: 10%;">
                  <col style="width: 14%;">
                  <col style="width: 10%;">
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th><input type="checkbox" v-model="isAllSelected"/></th>
                  <template v-for="sortField in sortFieldsState" :key="sortField.key">
                    <th v-if="!sortField.super || authStore.isSuperAdmin">
                      <TableHeaderItem :headData="sortField" @sort="setOrderBy"/>
                    </th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <template v-if="storeUsers.length !== 0">
                  <MemberListItem v-for="user in storeUsers"
                                  :memberInfo="user"
                                  :key="user.storeUserId"
                  />
                </template>
                <tr class="no_data" v-else>
                  <td :colspan="authStore.isSuperAdmin?9:8">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="deleteStoreUsers" >삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import FilterTabs from "@/components/common/FilterTabs.vue";
import {onMounted, ref, watch} from "vue";
import {useAuthStore} from "@/store/auth";
import {fetchMyCompany} from "@/api/company";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import MemberListItem from "@/components/member/MemberListItem.vue";
import {memberListSortFields} from "@/util/sortFields";
import {fetchStoreUsers, removeStoreUsers} from "@/api/storeUser";
import Pagination from "@/components/common/Pagination.vue";
import {useOrder} from "@/composables/orderBy";
import {useSelectAll} from "@/composables/selectAll";
import {linkOptions} from "@/util/filterFields";

const router = useRouter();
const authStore = useAuthStore();
const companyId = ref(null);
const companys = ref([]);
const storeUsers = ref([]);


const {sortFieldsState, setOrderBy, resetOrderBy, getOrderBy} = useOrder(memberListSortFields, fetchMemberInfo);
const {isAllSelected,getSelectedIds} = useSelectAll(storeUsers, 'storeUserId');

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});

const searchInfo = ref({
  filterValue: null,
  searchKey: 'storeUserName',
  searchValue: null,
});

onMounted(async () => {
  try {
    if (authStore.isSuperAdmin) {
      const {data} = await fetchMyCompany();
      companys.value = data;
    } else {
      companyId.value = authStore.companyId;
    }
  } catch (e) {
    console.error(e);
  }
  await fetchMemberInfo();
});

// 페이지네이션 버튼 클릭시 호출
watch(() => pagination.value.pageNumber, (newPage, oldPage) => {
  if (newPage !== oldPage) {
    fetchMemberInfo();
  }
}, {deep: true});

watch(companyId, fetchMemberInfo);

async function fetchMemberInfo() {
  try {
    const {data} = await fetchStoreUsers(companyId.value, {
          filterValue: searchInfo.value.filterValue,
          searchKey: searchInfo.value.searchKey || null,
          searchValue: searchInfo.value.searchValue || null
        },
        {
          orderKey: getOrderBy()?.key,
          orderValue: getOrderBy()?.direction
        }, {
          pageNumber: pagination.value.pageNumber,
          pageSize: pagination.value.pageSize,
        });
    console.log(data);

    pagination.value.pageNumber = data.pageNumber;
    pagination.value.pageSize = data.pageSize;
    pagination.value.totalCount = data.totalCount;

    storeUsers.value = data.storeUserMetaDataList;
    storeUsers.value.forEach(user => user.isSelected = false);

  } catch (e) {
    console.error(e);
  }
}

function setPageSize() {
  resetOrderBy();
  pagination.value.pageNumber = 1;
  fetchMemberInfo();
}

async function deleteStoreUsers(){
  const ids = getSelectedIds();
  if(ids.length === 0 ) {
    alert('선택된 항목이 없습니다.');
    return;
  }
  if(!confirm('삭제하시겠습니까?')) return;

  try {
    const {status} = await removeStoreUsers(ids.join(','));
    if(status === 204){
      alert('삭제되었습니다.');
      await fetchMemberInfo();
    }
  } catch(e){
    if(e.response.status===400 && e.response.data.errorCode ==='Store User Has Assigned Locker'){
      const rejectedUserNames = storeUsers.value
          .filter(user=> e.response.data.storeUserIds.includes(user.storeUserId))
          .map(user=>`${user.storeUserName}(${user.storeUserPhoneNumber})`);
      alert(`할당된 락커가 있는 사용자가 있습니다.\n락커를 먼저 회수해 주세요.\n${rejectedUserNames.join(', ')}`);
    }
    console.error(e);
  }

}


</script>

<style scoped>

</style>
