import {tablets} from "@/api/index";

export function fetchTabletList(areaId){
    return tablets.get('/all',{params: {areaId}});
}

export function checkTabletName(companyId, tabletName){
    return tablets.get('/check-tablet-name', {params: {companyId, tabletName}});
}

export function checkSerialNumber(serialNumber){
    return tablets.get('/check-serial-number', {params: {serialNumber}});
}

export function registerTablet(tabletInfo){
    return tablets.post('', tabletInfo);
}

export function fetchTablets(companyId, searchInfo, pagination, orderInfo){
    return tablets.get('', {
        params:{
            companyId,
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
            orderKey: orderInfo.orderKey,
            orderValue: orderInfo.orderValue,
        }
    });
}

export function removeTablets(tabletIds){
    return tablets.delete('', {params: {ids: tabletIds}});
}

export function fetchTablet(tabletId){
    return tablets.get(`/${tabletId}`, {params: {tabletId}});
}

export function modifyTablet(tabletId, tabletInfo){
    return tablets.patch(`/${tabletId}`, tabletInfo);
}