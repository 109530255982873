<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 중계기 목록</div>
        <div class="description">락카 중계기를 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="authStore.isSuperAdmin">
                  <th>업체별</th>
                  <td>
                    <div class="inner_td">
                      <CompanySelector v-model:companyId="companyId"/>
                      <!-- <select class="w_250">
                        <option>업체를 선택하세요</option>
                        <option>마이즈</option>
                      </select> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>키워드 검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="searchInfo.searchKey">
                        <!-- <option>전체</option> -->
                        <option value="tabletName">중계기명</option>
                        <option value="serialNumber">시리얼넘버</option>
                        <option value="ipAddress">IP주소</option>
                        <option value="areaName">설치구역명</option>
                      </select>
                      <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                        v-model="searchInfo.searchValue"
                        @keydown.enter="fetchTabletsInfo"
                      />
                      <button type="button" @click="fetchTabletsInfo">검색</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="건"
                                 v-model:searchResultCount="pagination.pageSize"
                                 @resultCount="setPageSize"
                                 />
            <!-- <ResultCountSelector :total="42" unit="건"
                                 @resultCount="console.log"/> -->
            <button @click="router.push('/locker/relay/add')" type="button">중계기 등록</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 7%" />
                  <col style="width: 5%" />
                  <col v-if="authStore.isSuperAdmin" />
                  <col style="width: 17%" />
                  <col style="width: 13%" />
                  <col style="width: 16%" />
                  <col style="width: 14%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <th>
                      <input type="checkbox" v-model="isAllSelected" />
                    </th>
                    <template v-for="sortField in sortFieldsState" :key="sortField.key ">
                      <th v-if="!sortField.super || authStore.isSuperAdmin">
                        <TableHeaderItem :headData="sortField" @sort="setOrderBy"/>
                      </th>
                    </template>
                    <!-- <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">상호명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">중계기명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">시리얼넘버</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">IP주소</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>설치구역명</th>
                    <th>등록된 락커</th> -->
                  </tr>
                </thead>
                <tbody>
                  <template v-if="tablets.length !== 0">
                    <TabletListItem v-for="tablet in tablets"
                                    :key="tablet.tabletId"
                                    :tabletInfo="tablet"/>
                  </template>
                  <tr class="no_data" v-else>
                    <td :colspan="authStore.isSuperAdmin?8:7">데이터가 없습니다</td>
                  </tr>
                  <!-- <tr>
                    <td class="o1">
                      <input type="checkbox" />
                    </td>
                    <td class="no_mobile">2</td>
                    <td class="seat o1 o_br">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="detail_page_a o2 o_title">
                      <div class="center_td">
                        <span class="only_mobile">중계기명 : </span>
                        <router-link to="/locker/relay/view" class="center_td">
                          <p class="text_bold long_p">1F-A</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                    <td class="seat o3">
                      <span class="only_mobile">시리얼넘버 : </span>
                      <span>0000165486</span>
                    </td>
                    <td class="seat o4r">
                      <span class="only_mobile">IP주소 : </span>
                      <span>106.135.222.548</span>
                    </td>
                    <td class="seat o5">
                      <span>여자탈의실</span>
                    </td>
                    <td class="seat o_stat">
                      <button @click="router.push('/locker/admin/list')" type="button">락커보기</button>
                    </td>
                  </tr> -->
                </tbody>
                <!-- <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody> -->
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="removeTablet">삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
          <!-- <div class="page_num">
            <div class="pageing">
              <a href="###"
                ><img src="@/images/front_n.png" alt="처음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/prev_n.png" alt="이전페이지"
              /></a>
              <a href="###" class="on">1</a>
              <a href="###">2</a>
              <a href="###">3</a>
              <a href="###"
                ><img src="@/images/next_n.png" alt="다음페이지"
              /></a>
              <a href="###"
                ><img src="@/images/end_n.png" alt="마지막페이지"
              /></a>
            </div>
          </div> -->
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {useAuthStore} from "@/store/auth";
import CompanySelector from "@/components/common/CompanySelector.vue";
import {onMounted, ref, watch} from "vue";
import {fetchTablets, removeTablets} from "@/api/tablet";
import Pagination from "@/components/common/Pagination.vue";
import {useOrder} from "@/composables/orderBy";
import {tabletListSortFields} from "@/util/sortFields";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import TabletListItem from "@/components/tablet/TabletListItem.vue";
import {useSelectAll} from "@/composables/selectAll";

const router = useRouter();
const authStore = useAuthStore();

const {sortFieldsState, setOrderBy, resetOrderBy, getOrderBy} = useOrder(tabletListSortFields, fetchTabletsInfo);

const companyId = ref(authStore.isSuperAdmin ? null : authStore.companyId);
const tablets = ref([]);

const {isAllSelected, getSelectedIds} = useSelectAll(tablets, 'tabletId');

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});

const searchInfo = ref({
  searchKey: 'tabletName',
  searchValue: null,
});

onMounted(async () => {
  await fetchTabletsInfo();
});

watch([companyId,
  ()=>pagination.value.pageNumber
], fetchTabletsInfo);

async function fetchTabletsInfo() {
  try {
    const resp = await fetchTablets(companyId.value,
        searchInfo.value, pagination.value, 
        {
          orderKey: getOrderBy()?.key,
          orderValue: getOrderBy()?.direction == undefined ? 'desc' : getOrderBy()?.direction,
        });

    console.log(resp);

    pagination.value.pageNumber = resp.data.pageNumber;
    pagination.value.pageSize = resp.data.pageSize;
    pagination.value.totalCount = resp.data.totalCount;

    tablets.value = resp.data.tabletMetadataList;
    tablets.value.forEach(tablet => tablet.isSelected = false);
  } catch (e) {
    console.error(e);
  }
}

function setPageSize(){
  pagination.value.pageNumber = 1;
  resetOrderBy();
  fetchTabletsInfo();
}

async function removeTablet(){
  const ids = getSelectedIds();
  if(ids.length === 0){
    alert('선택된 항목이 없습니다.')
    return;
  }
  if(!confirm('삭제하시겠습니까?')) return;

  console.log(ids.join(','))
  try{
    const resp = await removeTablets(ids.join(','));
    console.log(resp)
    if(resp.status === 204){
      alert('삭제되었습니다.');
      await fetchTabletsInfo();
    }
  }catch(error){
    console.error(error);
    if(error.status === 400 && error.response.data.errorCode === "Tablet Has Assigned Locker"){
      const tabletIds = error.response.data.tabletIds;
      const tabletMap = new Map(tablets.value.map(tablet => [tablet.tabletId, tablet.tabletName]));

      const tabletNames = tabletIds
        .map(tabletId => tabletMap.get(tabletId)) // Map에서 빠르게 tabletName을 가져옴
        .filter(tabletName => tabletName !== undefined); // undefined 제외

      if (tabletNames.length > 0) {
        alert(`소속된 락커가 있어 삭제가 불가합니다.\n삭제불가 : ${tabletNames.join(', ')}`);
      }
    }
  }
}
</script>

<style scoped></style>
