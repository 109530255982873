<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 중계기 상세보기</div>
        <div class="description">(*)표시는 필수 입력 정보입니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="authStore.isSuperAdmin">
                  <th>업체선택<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <CompanySelector :isViewMode="false" :isDisabled="true" v-model:companyId="companyId" @change="getAreaList" />
                      <!-- <select class="w_250">
                        <option>업체를 선택하세요</option>
                        <option selected>마이즈</option>
                      </select> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>중계기명<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        placeholder="중계기명을 입력하세요"
                        :value="tabletName"
                        @input="inputTabletName"
                        ref="tabletNameInput"
                      />
                      <div class="validation"
                         :class="tabletNameValid.valid?'text_green':'text_red'">
                      {{ tabletNameValid.msg }}
                      </div>
                      <!-- <div class="validation text_red">
                        이미 등록된 중계기명입니다.
                      </div> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>시리얼넘버<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        placeholder="시리얼넘버를 입력하세요"
                        v-model="serialNumber"
                        ref="serialNumberInput"
                      />
                      <div class="validation"
                         :class="serialNumberValid.valid?'text_green':'text_red'">
                      {{ serialNumberValid.msg }}
                      </div>
                      <!-- <div class="validation text_red">
                        이미 등록된 시리얼넘버입니다.
                      </div> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>IP주소<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        placeholder="IP주소를 입력하세요"
                        v-model="ipAddress"
                        ref="ipAddressInput"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>포트번호<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        maxlength="5"
                        placeholder="포트번호를 입력하세요(0~65535)"
                        v-model="port"
                        ref="portInput"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>설치구역<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <select class="w_250" v-model="areaId"
                              :disabled="companyId===null">
                        <option disabled :value="null">구역을 선택하세요</option>
                        <option v-for="area in areas" :value="area.areaId">{{ area.areaName }}</option>
                      </select>
                      <!-- <select class="w_250">
                        <option>설치구역을 선택하세요</option>
                        <option>1F</option>
                      </select> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>비고</th>
                  <td>
                    <div class="inner_td">
                      <input type="text" class="w_250" v-model="description" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="editLockerRelay" href="#" class="on">저장</a>
            <a @click.prevent="deleteLockerRelay" href="#">삭제</a>
            <a @click.prevent="listLockerRelay" href="#">목록</a>
            <!-- <router-link to="/locker/relay/list">목록</router-link> -->
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter, onBeforeRouteLeave} from "vue-router";
import {useAuthStore} from "@/store/auth";
import {onMounted, ref, watch} from "vue";
import CompanySelector from "@/components/common/CompanySelector.vue";
// import {useTabletStore} from "@/store/tablet";
import {storeToRefs} from "pinia";
import {debounce} from "lodash";
import {fetchAreaList} from "@/api/area";
import {checkTabletName, checkSerialNumber, modifyTablet, removeTablets, fetchTablet} from "@/api/tablet";

const router = useRouter();
const route = useRoute();
// const tabletStore = useTabletStore();
const authStore = useAuthStore();

const companyId = ref(0);
const companyName = ref('');
const tabletId = ref(0);
const tabletName = ref('');
const serialNumber = ref('');
const ipAddress = ref('');
const port = ref(0);
const areaId = ref(0);
const areaName = ref('');
const description = ref('');
// const {companyId, tabletName, serialNumber, ipAddress, port, areaId, description} = storeToRefs(tabletStore);

const tabletNameInput = ref(null);
const serialNumberInput = ref(null);
const ipAddressInput = ref(null);
const portInput = ref(null);

const areas = ref([]);

const tabletNameValid = ref({
  msg: '',
  valid: true,
})

const serialNumberValid = ref({
  msg: '',
  valid: true,
})

const initialState = ref({});
const isEdited = ref(false);
const isDataLoaded = ref(false);

onMounted(async () => {
  try {
    const {data} = await fetchTablet(route.params.tabletId);
    console.log(data);

    companyId.value = data.companyId;
    companyName.value = data.companyName;
    tabletId.value = data.tabletId;
    tabletName.value = data.tabletName;
    serialNumber.value = data.serialNumber;
    ipAddress.value = data.ipAddress;
    port.value = data.port;
    areaId.value = data.areaId;
    areaName.value = data.areaName;
    description.value = data.description;

    initialState.value = JSON.parse(JSON.stringify(data));
    isDataLoaded.value = true;
  } catch (error) {
    console.error(error);
  }

  // 상태 깊은 복사
  // await tabletStore.fetchTabletData(route.params.tabletId);
  // initialState.value = JSON.parse(JSON.stringify(tabletStore.$state));
  // isDataLoaded.value = true;

  if (!authStore.isSuperAdmin) {
    companyId.value = authStore.companyId;
  }
  getAreaList();
});

async function getAreaList() {
  if (companyId.value === null) return;
  try {
    const {data, status} = await fetchAreaList(companyId.value);
    if (status === 200) {
      areas.value = data;
    }
  } catch (e) {
    console.error(e);
  }
}

const inputTabletName = (e) => {
  const inputValue = e.target.value;

  tabletName.value = inputValue; // 수동으로 값 업데이트
  debouncedCheckTabletName(inputValue); // debounce 처리
}

const debouncedCheckTabletName = debounce(async (newNm) => {
  if (!newNm) {
    tabletNameValid.value.valid = false;
    tabletNameValid.value.msg = '중계기명을 입력해주세요';
    return;
  }
  if (!companyId.value) {
    alert('업체를 먼저 선택해주세요');
    tabletName.value = null;
    return;
  }

  if (initialState._rawValue.tabletName == newNm){ // 기존 값
    tabletNameValid.value.valid = true;
    tabletNameValid.value.msg = '';
    return;
  }

  try {
    const {data} = await checkTabletName(companyId.value, newNm);
    if (data.exists) {
      tabletNameValid.value.valid = false;
      tabletNameValid.value.msg = '이미 등록된 중계기명입니다.';
    } else {
      tabletNameValid.value.valid = true;
      tabletNameValid.value.msg = '등록 가능한 중계기명입니다.';
    }
  } catch (e) {
    tabletNameValid.value.valid = false;
    console.error(e);
  }
},300);

watch(serialNumber, (newNum) => {debouncedCheckSerialNumber(newNum);});
watch(port, (newPort) => {
  // 숫자로 변환
  const num = parseInt(newPort, 10);

  // 유효성 검사
  if (newPort === '' || (!isNaN(num) && num >= 0 && num <= 65535)) {
  } else {
    port.value = newPort.slice(0, -1); // 마지막 입력값 제거
  }
});


const debouncedCheckSerialNumber = debounce(async (newNum) => {
  if (!newNum) {
    serialNumberValid.value.valid = false;
    serialNumberValid.value.msg = '시리얼넘버를 입력해주세요';
    return;
  }

  if (initialState._rawValue.serialNumber == newNum){ // 기존 값
    serialNumberValid.value.valid = true;
    serialNumberValid.value.msg = '';
    return;
  }

  try {
    const {data} = await checkSerialNumber(newNum);
    if (data.exists) {
      serialNumberValid.value.valid = false;
      serialNumberValid.value.msg = '이미 등록된 시리얼넘버입니다.';
    } else {
      serialNumberValid.value.valid = true;
      serialNumberValid.value.msg = '등록 가능한 시리얼넘버입니다.';
    }
  } catch (e) {
    serialNumberValid.value.valid = false;
    console.error(e);
  }
},300);

function checkRequired() {
  if(!tabletNameValid.value.valid){
    tabletNameInput.value.focus();
    return false;
  }
  if(!serialNumberValid.value.valid){
    serialNumberInput.value.focus();
    return false;
  }
  if(!ipAddress.value){
    ipAddressInput.value.focus();
    return false;
  }
  if(!port.value){
    portInput.value.focus();
    return false;
  }
  return !!(companyId.value && areaId.value);
}

function isStateChanged() {
  return !!(companyId.value != initialState._rawValue.companyId || tabletName.value != initialState._rawValue.tabletName || serialNumber.value != initialState._rawValue.serialNumber ||
  ipAddress.value != initialState._rawValue.ipAddress || port.value != initialState._rawValue.port || areaId.value != initialState._rawValue.areaId || description.value != initialState._rawValue.description);
}

async function editLockerRelay() {
  if (!checkRequired()) {
    alert('락커 중계기 정보를 모두 입력해 주세요');
    return;
  }

  if (!isStateChanged()) {
    alert('저장이 완료되었습니다.')
    router.push('/locker/relay/list');
    return;
  }

  try {
    const {status} = await modifyTablet(route.params.tabletId, {
      companyId: companyId.value,
      tabletName: tabletName.value,
      serialNumber: serialNumber.value,
      ipAddress: ipAddress.value,
      port: port.value,
      areaId: areaId.value,
      description: description.value == '' ? null : description.value,
    });
    if (status === 200) {
      alert('저장이 완료되었습니다.')
      await router.push('/locker/relay/list');
    }
  } catch (e) {
    console.error(e);
  }
}

async function deleteLockerRelay() {
  if (!confirm('삭제하시겠습니까?')) return;

  try {
    const resp = await removeTablets(route.params.tabletId);
    if (resp.status === 204) {
      alert('삭제되었습니다.');
      await router.push('/locker/relay/list');
    }
  } catch (error) {
    console.error(error);
    if(error.status === 400 && error.response.data.errorCode === "Tablet Has Assigned Locker"){
      alert('소속된 락커가 있어 삭제가 불가합니다.');
    }
  }
}

function listLockerRelay(){
  if (isStateChanged()) {
    if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
  }
  router.push('/locker/relay/list');
}

// function editLockerRelay(){
//   if(!alert('저장되었습니다')){
//     router.push('/locker/relay/list');
//   }
// }

// function deleteLockerRelay(){
//   if(confirm('삭제하시겠습니까?')){
//     alert('삭제되었습니다.');
//     router.push('/locker/relay/list');
//   }
// }
</script>

<style scoped></style>
