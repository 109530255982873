<template>
  <div class="part_title">담당자정보</div>
  <table>
    <colgroup>
      <col style="width: 18%" class="col_width26" />
      <col />
    </colgroup>
    <tr>
      <th>이름</th>
      <td>
        <input
            type="text"
            class="w_250"
            placeholder="이름을 입력하세요"
            v-model="managerInfo.managerName"
        />
      </td>
    </tr>
    <tr>
      <th>휴대폰번호</th>
      <td>
        <div class="inner_td p_number">
          <select v-model="managerInfo.managerPhoneNumber[0]">
            <option>010</option>
            <option>011</option>
            <option>016</option>
            <option>017</option>
            <option>018</option>
            <option>019</option>
          </select>
          -
          <input v-model="managerInfo.managerPhoneNumber[1]" type="text" class="w_55" maxlength="4" />
          -
          <input v-model="managerInfo.managerPhoneNumber[2]" type="text" class="w_55" maxlength="4" />
        </div>
      </td>
    </tr>
    <tr>
      <th>이메일</th>
      <td>
        <input
            type="text"
            class="w_250"
            placeholder="이메일을 입력하세요"
            v-model="managerInfo.managerEmail"
        />
      </td>
    </tr>
  </table>
</template>

<script setup>
import {useCompanyStore} from "@/store/company";
import {storeToRefs} from "pinia";

let company = useCompanyStore();

const {managerInfo} = storeToRefs(company);

</script>

<style scoped>

</style>