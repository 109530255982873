<template>
  <!-- 푸터 -->
  <footer class="footer no_mobile">
    <div class="footer_box">Copyright ⓒ MIZE Inc. All Rights Reserved.</div>
  </footer>
  <!-- //푸터 -->
</template>

<script setup>
</script>
