<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">모니터링 화면</div>
        <div class="description">??</div>
        <article class="right_body"></article>
      </div>
    </div>
  </div>
</template>

<script setup>
</script>

<style scoped></style>
