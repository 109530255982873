<template>
  <tr>
    <td class="no_mobile num">{{userInfo.number}}</td>
    <td class="num phone o4 o_br">
      <span class="only_mobile">이메일 : </span>
      <span>{{ userInfo.email }}</span>
    </td>
    <td class="detail_page_a o1 o_title">
      <div class="center_td">
        <router-link :to="`/user/view/${userInfo.id}`" class="center_td">
          <p class="text_bold long_p">{{userInfo.name}}</p>
          <img src="@/images/detail_page.png"/>
        </router-link>
      </div>
    </td>
    <td class="num phone o4">
      <span class="only_mobile">휴대폰번호 : </span>
      <span>{{ toHyphenPhoneNumber(userInfo.phoneNumber)}}</span>
    </td>
    <td class="seat o3">
      <span class="only_mobile">가입수단 : </span>
      <span>{{userInfo.typeName}}</span>
    </td>
    <td class="num phone o5">
      <span class="only_mobile">가입일 : </span>
      <span>{{formatDate(userInfo.createdAt)}}</span>
    </td>
    <td class="num phone o7r">
      <span class="only_mobile">수정일 : </span>
      <span>{{formatDate(userInfo.updatedAt)}}</span>
    </td>
    <td class="num o8">
      <div class="center_td">
        <button @click="resetEasyPassword" type="button">초기화</button>
      </div>
    </td>
    <td class="num o9r">
      <div class="center_td">
        <button @click="emits('expelUser',userInfo.id)" type="button">강제탈퇴</button>
      </div>
    </td>
  </tr>
</template>

<script setup>


import {formatDate,toHyphenPhoneNumber} from "@/util/formatUtils";
import {modifyUser} from "@/api/user";

const emits = defineEmits(['expelUser']);
const props = defineProps({
  userInfo:{
    type: Object,
    required: true,
  }
});

async function resetEasyPassword(){
  if(!confirm('간편 비밀번호를 초기화하시겠습니까?')) return;
  try {
    const {status} = await modifyUser(props.userInfo.id, {
      isEasyPasswordResetRequired: true,
      isExitRequired: false,
    });
    if (status === 200) {
      alert('\'1234\'로 초기화되었습니다.');
    }
  } catch (e) {
    console.error(e);
  }
}
</script>

<style scoped>

</style>