import {useRoute} from "vue-router";


// 현재 선택된 사이드바의 효과를 주기 위한 함수
export function checkSideActive(menuPaths) {
    // return useRoute().path === menuPaths;
    return menuPaths.some(path => useRoute().path.includes(path));
}

// 현재 선택된 헤더 관리메뉴의 효과를 주기 위한 함수
export function checkHeaderActive(menuPath) {
    return useRoute().path.startsWith(menuPath);
}

