import axios from "axios";
import {setInterceptors} from "@/api/common/interceptors";

function createInstance(){
    return axios.create({
        baseURL: process.env.VUE_APP_API_BASE_URL,
    })
}

function createInstanceWithAuth(url){
    const instance = axios.create({
        baseURL: `${process.env.VUE_APP_API_BASE_URL}${url}`,
    });
    return setInterceptors(instance);
}
export const instance = createInstance();
export const companies = createInstanceWithAuth('companies');
export const managedUsers = createInstanceWithAuth('managed-users');
// 업체의 회원
export const storeUsers = createInstanceWithAuth('store-users');
export const storeUserRequests = createInstanceWithAuth('store-user-requests');
export const areas = createInstanceWithAuth('areas');
export const tablets = createInstanceWithAuth('tablets');
export const lockers = createInstanceWithAuth('lockers');
export const lockerUsages = createInstanceWithAuth('locker-usages');
export const policies = createInstanceWithAuth('policies');
export const users = createInstanceWithAuth('users');

