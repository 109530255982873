<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">사용자 상세보기</div>
        <div class="description">App 사용자 정보를 조회하고 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">계정정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26"/>
                <col/>
              </colgroup>
              <tr>
                <th>가입수단</th>
                <td>
                  <div class="inner_td">{{ joinType }}</div>
                </td>
              </tr>
              <tr>
                <th>이메일</th>
                <td>{{ userInfo.email }}</td>
              </tr>
              <tr>
                <th>비밀번호</th>
                <td>
                  <div class="passoword_reset_box">
                    <label>
                      <input type="checkbox" v-model="editData.isPasswordResetRequired"/>
                      <span>비밀번호 초기화</span>
                    </label>
                    <span class="accent">(qwer12#$)</span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">가입정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26"/>
                <col/>
              </colgroup>
              <tr>
                <th>이름</th>
                <td>{{ userInfo.name||'-' }}</td>
              </tr>
              <tr>
                <th>휴대폰번호</th>
                <td>{{ toHyphenPhoneNumber(String(userInfo.phoneNumber)) }}</td>
              </tr>
              <tr>
                <th>생년월일</th>
                <td>{{ userInfo.birthday || '-' }}</td>
              </tr>
              <tr>
                <th>성별</th>
                <td>
                  {{gender}}
                </td>
              </tr>
              <tr>
                <th>간편비밀번호</th>
                <td>
                  <div class="passoword_reset_box">
                    <label>
                      <input type="checkbox" v-model="editData.isEasyPasswordResetRequired"/>
                      <span>간편비밀번호 초기화</span></label
                    >
                    <span class="accent">(1234)</span>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="saveUser" href="#" class="on">저장</a>
            <a @click.prevent="expelUser" href="#">강제탈퇴</a>
            <router-link to="/user/list">목록</router-link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRoute, useRouter} from "vue-router";
import {computed, onMounted, ref} from "vue";
import {fetchUser, modifyUser} from "@/api/user";
import {toHyphenPhoneNumber} from "@/util/formatUtils";

const router = useRouter();
const route = useRoute();

const userInfo = ref({});
const editData = ref({
  isPasswordResetRequired: false,
  isEasyPasswordResetRequired: false,
  isExitRequired: false,
});

const joinType = computed(() => {
  switch (userInfo.value.typeId) {
    case '1':
      return '이메일'
    case '2':
      return '카카오톡'
    case '3':
      return '네이버'
    case '4':
      return '구글'
    case '5':
      return '애플'
  }
});

const gender = computed(()=>{
  switch (userInfo.value.gender) {
    case 'M':
      return '남성';
    case 'F':
      return '여성';
    default:
      return '-';
  }
})


onMounted(async () => {
  try {
    const {status, data} = await fetchUser(route.params.userId);
    if (status === 200) {
      console.table(data);
      userInfo.value = data;
    }
  } catch (e) {
    console.error(e);
  }
});

async function saveUser() {
  try {
    const {status} = await modifyUser(userInfo.value.id, editData.value);
    if (status === 200) {
      alert('저장이 완료되었습니다.');
      await router.push('/user/list');
    }
  } catch (e) {
    console.error(e);
  }
}

async function expelUser() {
  if (!confirm('해당 사용자를 강제탈퇴하시겠습니까?')) return;

  editData.value.isExitRequired = true;
  try {
    const {status} = await modifyUser(userInfo.value.id, editData.value);
    if (status === 200) {
      alert('탈퇴 처리가 완료되었습니다.')
      await router.push('/user/list');
    }
  } catch (e) {
    console.error(e);
  }
}


</script>

<style scoped></style>
