<template>
  <div class="pageing">
    <template v-if="startPage !== 1 && prevNextDisplay">
      <a class="link" @click.prevent="pageInfo.pageNumber = 1"><img src="@/images/front_n.png" alt="처음페이지"/></a>
      <a class="link" @click.prevent="pageInfo.pageNumber = startPage -1"><img src="@/images/prev_n.png" alt="이전페이지"/></a>
    </template>
    <div class="page-numbers">
      <a class="link" v-for="page in visiblePages"
         :key="page"
         :class="{on: page === pageInfo.pageNumber}"
         @click.prevent="pageInfo.pageNumber = page"
      >
        {{ page }}
      </a>
    </div>
    <template v-if="endPage !== totalPage && prevNextDisplay">
      <a class="link" @click.prevent="pageInfo.pageNumber = endPage +1"><img src="@/images/next_n.png" alt="다음페이지"/></a>
      <a class="link" @click.prevent="pageInfo.pageNumber= totalPage"><img src="@/images/end_n.png" alt="마지막페이지"/></a>
    </template>
  </div>
</template>

<script setup>

import {computed} from "vue";

// const {pageInfo} = defineProps(['pageInfo']);
const pageInfo = defineModel('pageInfo');

const props = defineProps(['countPage']);

// 한 화면에 출력 될 페이지 수
const countPage = props.countPage || 5;

// 전체 페이지 수 계산
const totalPage = computed(()=>(Math.ceil(pageInfo.value.totalCount / pageInfo.value.pageSize)) || 1);

// 총 페이지가 countPage 이하면 이전/다음 버튼을 보여주지 않음
const prevNextDisplay = computed(()=>totalPage.value>countPage);

// 화면에 보여줄 시작페이지
const startPage = computed(()=>Math.floor((pageInfo.value.pageNumber - 1) / countPage) * countPage + 1);
// 화면에 보여줄 마지막 페이지
const endPage = computed(()=>Math.min(startPage.value + countPage - 1, totalPage.value));

// 화면에 보여줄 페이지 목록
const visiblePages = computed(()=>{
  const pages = [];
  for (let i = startPage.value; i <= endPage.value ; i++) {
    pages.push(i);
  }
  return pages;
});

</script>

<style scoped>

.page-numbers{
  display: flex;
  justify-content: center;
  flex-grow: 1;
}
</style>