<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">업체 등록</div>
        <div class="description">(*) 표시는 필수 입력 정보입니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <CompanyInfo :isEdit="false"/>
          </div>
          <div class="tbl_search tbl_info">
            <ManagerInfo/>
          </div>
          <div class="tbl_search tbl_info">
            <ManagedUserAccountInfo/>
          </div>
          <div class="btn_bottom_a">
            <a  class="on" @click.prevent="addCompany">등록</a>
            <a  @click.prevent="cancelAction" >취소</a>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>

import {onBeforeRouteLeave, useRouter} from "vue-router";
import CompanyInfo from "@/components/company/CompanyInfo.vue";
import ManagerInfo from "@/components/company/ManagerInfo.vue";
import ManagedUserAccountInfo from "@/components/company/ManagedUserAccountAdd.vue";
import {onMounted,  ref} from "vue";
import {useCompanyStore} from "@/store/company";
import {createCompany} from "@/api/company";
import {storeToRefs} from "pinia";

const router = useRouter();
const companyStore = useCompanyStore();
const {findAddressBtn,addressInfo,managedUserPassword,managedUserPwInput} = storeToRefs(companyStore);

// 업체등록 초기상태를 저장할 변수
const initialState = ref({});
const isAdded = ref(false);

onMounted(()=>{
  // 상태 깊은 복사
  initialState.value = JSON.parse(JSON.stringify(companyStore.$state));
});


onBeforeRouteLeave((to, from, next) => {
  if(companyStore.isStateChanged(initialState.value)&& !isAdded.value){
    if(!confirm('입력 중인 내용이 사라집니다.')) return;
  }
  companyStore.$reset();
  next();
});

async function addCompany() {
  if(!companyStore.checkRequiredFields()){
    alert('필수 입력 정보를 모두 입력/확인해 주세요');
    return ;
  }else if(!addressInfo.value.zipCode){
    alert('필수 입력 정보를 모두 입력/확인해 주세요');
    findAddressBtn.value.click();
    return;
  }else if (!managedUserPassword.value){
    alert('필수 입력 정보를 모두 입력/확인해 주세요');
    managedUserPwInput.value.inputFocus();
    return false;
  }

  try{
    const {status} = await createCompany(companyStore.getCreateData());
    if(status === 201){
      isAdded.value = true;
      alert('등록되었습니다.');
      await router.push('/company/list');
    }
  }catch (error){
    console.log(error);
    let errMsg = '';
    error.response.data.message.forEach(err=>{
      errMsg += `${err.errors[0]}\n`;
    });
    alert(errMsg.trim());
  }
}

function cancelAction(){
  if(companyStore.isStateChanged(initialState.value)){
    if(!confirm('입력 중인 내용이 사라집니다.')) return;
  }
  companyStore.$reset();
  router.push('/company/list');
}


</script>

<style scoped></style>
