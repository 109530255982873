<template>
  <router-view />
</template>

<script setup>
</script>
<style>
[v-cloak] {
  display: none;
}

.none{
  display: none;
}

.cancel{
  cursor: pointer;
}
.flex{
  display: flex;
}

.flex-end{
  display: flex;
  justify-content: flex-end;
}

.column{
  flex-direction: column;
}
.align-center{
  align-items: center;
}
.flex-center{
  display: flex;
  align-items: center;
  justify-content: center;
}
.link{
  cursor: pointer;
}


</style>