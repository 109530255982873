import {users} from "@/api/index";
import {dateToString, formatDate} from "@/util/formatUtils";


// 앱 사용자 페이징 조회 API(사용자 목록, 탈퇴한 사용자)
export function fetchUsers(searchInfo,orderInfo,pagination){
    const existDate = searchInfo.dateRange?.length > 0;
    return users.get('',{
        params:{
            typeId:searchInfo.typeId,
            searchKey: searchInfo.searchKey,
            searchValue: searchInfo.searchValue,
            exitStatus: searchInfo.exitStatus,
            startDate: existDate? formatDate(searchInfo.dateRange[0]) :null ,
            endDate: existDate?formatDate(searchInfo.dateRange[1]):null ,
            orderByKey: orderInfo.orderKey,
            orderDirection: orderInfo.orderValue,
            pageNumber: pagination.pageNumber,
            pageSize: pagination.pageSize,
        }
    });
}

// 사용자 상세 조회 API
export function fetchUser(userId){
    return users.get(`/${userId}`);
}

//사용자 수정 API
export function modifyUser(userId,editData){
    return users.patch(`/${userId}`,{
        isPasswordResetRequired: editData.isPasswordResetRequired ,
        isEasyPasswordResetRequired: editData.isEasyPasswordResetRequired ,
        isExitRequired: editData.isExitRequired ,
    });
}