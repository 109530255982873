<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_66.png" alt=""/>락커관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <li v-for="page in pages">
          <a @click.prevent="navigateWithReload(page.link)"
             class="link" :class="{ on: checkSideActive(page.matchLinks)}"
          >
            {{ page.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";

import {ref} from "vue";
import {useRouteHandle} from "@/composables/routeHadler";

const {navigateWithReload} = useRouteHandle();

const pages = ref([
  {label: '락커 사용현황', link: '/locker/use/usestatus', matchLinks: ['/locker/use/usestatus']},
  {label: '락커 배정이력', link: '/locker/use/assignmenthistory', matchLinks: ['/locker/use/assignmenthistory']},
  {label: '락커 제어이력', link: '/locker/use/controlstatus', matchLinks: ['/locker/use/controlstatus']},
  {label: '락커 목록', link: '/locker/admin/list', matchLinks: ['/locker/admin/list', '/locker/admin/add','/locker/admin/view']},
  // {label:'락커 등록',link:'/locker/admin/add'},
  // {label:'락커 상세보기',link:'/locker/admin/view'},
  {label: '락커 일괄등록', link: '/locker/admin/batchadd', matchLinks: ['/locker/admin/batchadd']},
  {label: '락커 구역 목록', link: '/locker/area/list', matchLinks: ['/locker/area/list', '/locker/area/add','/locker/area/view']},
  // {label:'락커 구역 등록',link:'/locker/area/add'},
  // {label:'락커 구역 상세보기',link:'/locker/area/view'},
  {label: '락커 중계기 목록', link: '/locker/relay/list', matchLinks: ['/locker/relay/list', '/locker/relay/add','/locker/relay/view']},
  // {label:'락커 중계기 등록',link:'/locker/relay/add'},
  // {label:'락커 중계기 상세보기',link:'/locker/relay/view'},
]);


</script>

<style scoped></style>
