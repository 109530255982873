import {getAccessToken, getRefreshToken, isAutoLogin, resetStorage, saveToken} from "@/util/authToken";
import axios from "axios";

export function setInterceptors(instance) {
    instance.interceptors.request.use(
        function (config) {
            config.headers.Authorization = `Bearer ${getAccessToken()}`;
            return config;
        },
        function (error) {
            return Promise.reject(error);
        }
    );

    instance.interceptors.response.use(
        function (response) {
            return response;
        },
        async function (error) {
            const originalRequest = error.config;

            // 401 오류이면서, 이전에 시도했던 요청이 아닐 경우만 처리
            if (error.response.status === 401 && !originalRequest._retry) {
                originalRequest._retry = true;

                try {

                    // 리프레시 토큰이 존재하는지 확인
                    const refreshToken = getRefreshToken();
                    if (!refreshToken) {
                        resetStorage(); // 모든 토큰 삭제
                        window.location.href = '/login';
                        return Promise.reject(new Error('No refresh token available'))
                    }
                    const refreshResp = await axios.post(`${process.env.VUE_APP_API_BASE_URL}auth/refresh`, {}, {
                        headers: {
                            'Authorization': `Bearer ${refreshToken}`,
                        }
                    });

                    if (refreshResp.status === 200) {
                        const newAccessToken = refreshResp.data.accessToken;
                        saveToken(newAccessToken,
                            refreshResp.data.refreshToken,
                            isAutoLogin());

                        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`
                        return instance(originalRequest);
                    }else{
                        resetStorage();
                        window.location.href = '/login';
                    }


                } catch (refreshError) {
                    //     리프레시 토큰도 만료된 경우
                    resetStorage();
                    window.location.href = '/login';
                    return Promise.reject(refreshError);
                }
            }

            return Promise.reject(error);
        }
    );

    return instance;
}