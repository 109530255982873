<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">개인정보처리방침 등록</div>
        <div class="description">개인정보처리방침을 등록합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <TermsForm 
              v-model:terms-info="termsInfo"
              v-model:effectiveDateValid="effectiveDateValid"/>
          </div>
          <div class="btn_bottom_a">
            <button @click="addPrivacy" type="button" class="on">등록</button>
            <button @click="router.push('/admin/privacy/list')" type="button">취소</button>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>
<script setup>
import {onBeforeRouteLeave, useRouter} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";
import TermsForm from "@/components/admin/TermsForm.vue";
import {ref} from "vue";
import {registerPrivacy} from "@/api/policies";

const router = useRouter();

const termsInfo = ref({
  displayed: true,
  effectiveDate: null,
  content:'',
});
const isAdded = ref(false);

const effectiveDateValid = ref({
  msg: '',
  valid: false,
  page: 'privacy',
  origin: '',
});

onBeforeRouteLeave((to, from, next) => {
  if (isChanged() && !isAdded.value) {
    if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
  }
  next();
});

function isChanged() {
  return (!termsInfo.value.displayed
      || termsInfo.value.effectiveDate !== null
      || termsInfo.value.content !== '');
}

async function addPrivacy(){
  if (!termsInfo.value.content || !termsInfo.value.effectiveDate || !effectiveDateValid.value.valid) {
    alert('내용을 입력해 주세요.');
    return;
  }

  const date = termsInfo.value.effectiveDate;
  const newDate = date.getFullYear() + '-' + 
                  (date.getMonth() + 1).toString().padStart(2, '0') + '-' + 
                  date.getDate().toString().padStart(2, '0');

  console.log(newDate)

  try {
    const {status} = await registerPrivacy({
      content: termsInfo.value.content,
      effectiveDate: newDate,
      isActive: termsInfo.value.displayed,
    });
    if (status === 201) {
      alert('등록되었습니다.')
      isAdded.value = true;
      await router.push('/admin/privacy/list');
    }
  } catch (e) {
    console.error(e);
  }
}
</script>

<style scoped></style>
