<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">사용자 목록</div>
        <div class="description">사용자를 조회하고 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr>
                <th>가입수단별</th>
                <td>
                  <FilterTabs :tabs="signupOptions"
                              v-model:selectTab="searchInfo.typeId"
                              @tabSelected="setPageSize"
                  />
                </td>
              </tr>
              <tr>
                <th>키워드검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="searchInfo.searchKey">
                      <option value="email">이메일</option>
                      <option value="name">이름</option>
                      <option value="phoneNumber">휴대폰번호</option>
                    </select>
                    <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                        v-model="searchInfo.searchValue"
                        @keydown.enter="fetchUserList"
                    />
                    <button @click="fetchUserList" type="button">검색</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="명"
                                 v-model:searchResultCount="pagination.pageSize"
            />
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 7%"/>
                  <col/>
                  <col style="width: 13%"/>
                  <col style="width: 12%"/>
                  <col style="width: 9%"/>
                  <col style="width: 10%"/>
                  <col style="width: 10%"/>
                  <col style="width: 11%"/>
                  <col style="width: 13%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th v-for="sortField in sortFieldsState" :key="sortField.label ">
                    <TableHeaderItem :headData="sortField" @sort="setOrderBy"/>
                  </th>
                </tr>
                </thead>
                <tbody>
                <template v-if="users.length>0">
                  <UserListItem v-for="user in users" :key="user.id" :userInfo="user"
                                @expelUser="expelUser"
                  />
                </template>
                <tr class="no_data" v-else>
                  <td colspan="9">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>

import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {onMounted, ref, watch} from "vue";
import {signupOptions} from "@/util/filterFields";
import {useOrder} from "@/composables/orderBy";
import {userListSortFields} from "@/util/sortFields";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import Pagination from "@/components/common/Pagination.vue";
import UserListItem from "@/components/user/UserListItem.vue";
import {fetchUsers, modifyUser} from "@/api/user";

const {sortFieldsState, getOrderBy, resetOrderBy, setOrderBy} = useOrder(userListSortFields, fetchUserList);

const users = ref([]);

const searchInfo = ref({
  typeId: null,
  searchKey: 'email',
  searchValue: null,
  exitStatus: 'ACTIVE'
});

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});

onMounted(async () => {
  await fetchUserList()
});

watch([
  () => pagination.value.pageSize,
], setPageSize,);
watch(() => pagination.value.pageNumber, fetchUserList);

async function fetchUserList() {
  try {
    const {data} = await fetchUsers(searchInfo.value, {
      orderKey: getOrderBy()?.key,
      orderValue: getOrderBy()?.direction
    }, pagination.value);

    console.log(data);

    pagination.value.pageNumber = data.pageNumber;
    pagination.value.pageSize = data.pageSize;
    pagination.value.totalCount = data.totalCount;

    users.value = data.UserMetaDataList;

  } catch (e) {
    console.error(e);
  }
}

async function expelUser(userId) {
  if (!confirm('해당 사용자를 강제탈퇴하시겠습니까?')) return;
  try {
    const {status} = await modifyUser(userId, {
      isExitRequired: true,
    });
    if (status === 200) {
      alert('탈퇴 처리가 완료되었습니다.')
      await fetchUserList();
    }
  } catch (e) {
    console.error(e);
  }
}


function setPageSize() {
  resetOrderBy();
  pagination.value.pageNumber = 1;
  fetchUserList();
}


</script>

<style scoped></style>
