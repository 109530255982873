<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 제어이력</div>
        <div class="description">락커 잠금해제 이력을 유형별로 조회합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="authStore.isSuperAdmin">
                  <th>업체별</th>
                  <td>
                    <div class="inner_td">
                      <CompanySelector v-model:companyId="companyId"/>
                      <!-- <select class="w_200">
                        <option>전체</option>
                      </select> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>기간별</th>
                  <td>
                    <div class="inner_td m_date">
                      <DatePicker class="w_300" v-model="filterInfo.dateRange"
                                :format="'yyyy-MM-dd'"
                                :enable-time-picker="false" auto-apply
                                placeholder="기간별 조회"
                                locale="ko" range
                                week-start="0"
                      />
                      <!-- <input type="date" /> ~ <input type="date" /> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>제어유형별</th>
                  <td>
                    <FilterTabs :tabs="controlTypeOptions" 
                                v-model:select-tab="filterInfo.controlType"
                                @tab-selected="onTabSelected"/>
                  </td>
                </tr>
                <tr>
                  <th>키워드검색</th>
                  <td>
                    <div class="inner_td">
                      <select v-model="searchInfo.searchKey">
                        <!-- <option>전체</option> -->
                        <option value="areaName">구역명</option>
                        <option value="lockerNum">락커번호</option>
                        <option value="storeUserName">이름</option>
                        </select>
                        <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                        v-model="searchInfo.searchValue"
                        @keydown.enter="fetchLockerControlInfo"
                      /><button type="button" @click="fetchLockerControlInfo">검색</button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="건"
                                 v-model:search-result-count="pagination.pageSize"
                                 @resultCount="setPageSize"/>
            <!-- <ResultCountSelector :total="42" unit="건"
                                 @resultCount="console.log"/> -->
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 7%" />
                  <col v-if="authStore.isSuperAdmin" />
                  <col style="width: 18%" />
                  <col style="width: 16%" />
                  <col style="width: 11%" />
                  <col style="width: 18%" />
                </colgroup>
                <thead class="no_mobile">
                  <tr>
                    <template v-for="sortField in sortFieldsState" :key="sortField.key">
                      <th v-if="!sortField.super || authStore.isSuperAdmin">
                        <TableHeaderItem :headData="sortField" @sort="setOrderBy"/>
                      </th>
                    </template>
                    <!-- <th>
                      <a href="###">번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">상호명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">제어일시</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">제어유형</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">락커번호</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th>
                    <th>
                      <a href="###">회원명</a>
                      <img src="@/images/up_icon_s.png" style="display: none" />
                      <img
                        src="@/images/down_icon_s.png"
                        style="display: none"
                      />
                    </th> -->
                  </tr>
                </thead>
                <tbody>
                  <template v-if="lockerControl.length !==0">
                    <LockerControlStatusListItem v-for="control in lockerControl"
                                            :key="control.lockerControlLogId"
                                            :controlInfo="control"
                    />
                  </template>
                  <tr class="no_data" v-else>
                    <td :colspan="authStore.isSuperAdmin?6:5">데이터가 없습니다</td>
                  </tr>

                  <!-- <tr>
                    <td class="num no_mobile">3</td>
                    <td class="seat o0 o_br">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o1 o_br">
                      <span class="only_mobile">제어일시 : </span>
                      <span>2024.07.01 15:00:02</span>
                    </td>
                    <td class="num o4r">
                      <span class="only_mobile">제어유형 : </span>
                      <span>App</span>
                    </td>
                    <td class="o2 o_title">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 1</span>
                    </td>
                    <td class="seat o3 detail_page_a">
                      <span class="only_mobile">회원명 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">서다혜</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="num no_mobile">3</td>
                    <td class="seat o0 o_br">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o1 o_br">
                      <span class="only_mobile">제어일시 : </span>
                      <span>2024.07.01 15:00:02</span>
                    </td>
                    <td class="num o4r">
                      <span class="only_mobile">제어유형 : </span>
                      <span>중계기</span>
                    </td>
                    <td class="o2 o_title">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 3</span>
                    </td>
                    <td class="seat o3 detail_page_a">
                      <span class="only_mobile">회원명 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">서다혜</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="num no_mobile">3</td>
                    <td class="seat o0 o_br">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o1 o_br">
                      <span class="only_mobile">제어일시 : </span>
                      <span>2024.07.01 15:00:02</span>
                    </td>
                    <td class="num o4r">
                      <span class="only_mobile">제어유형 : </span>
                      <span>관리자</span>
                    </td>
                    <td class="o2 o_title">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 2</span>
                    </td>
                    <td class="seat o3 detail_page_a">
                      <span class="only_mobile">회원명 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">-</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td class="num no_mobile">3</td>
                    <td class="seat o0 o_br">
                      <span class="only_mobile">상호명 : </span>
                      <span>주식회사마이즈</span>
                    </td>
                    <td class="num seat o1 o_br">
                      <span class="only_mobile">제어일시 : </span>
                      <span>2024.07.01 15:00:02</span>
                    </td>
                    <td class="num o4r">
                      <span class="only_mobile">제어유형 : </span>
                      <span>관리자</span>
                    </td>
                    <td class="o2 o_title">
                      <span class="only_mobile">락커번호 : </span>
                      <span>[1F] 2</span>
                    </td>
                    <td class="seat o3 detail_page_a">
                      <span class="only_mobile">회원명 : </span>
                      <div class="center_td">
                        <router-link to="/member/view" class="center_td">
                          <p class="text_bold long_p">서다혜</p>
                          <img src="@/images/detail_page.png" />
                        </router-link>
                      </div>
                    </td>
                  </tr> -->
                </tbody>
                <!-- <tbody style="display: none">
                  <tr class="no_data">
                    <td colspan="8">데이터가 없습니다</td>
                  </tr>
                </tbody> -->
              </table>
            </div>
            <!--//목록-->
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>

        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {controlTypeOptions} from "@/util/filterFields";
import {onMounted, ref, watch} from "vue";
import {useAuthStore} from "@/store/auth";
import CompanySelector from "@/components/common/CompanySelector.vue";
import Pagination from "@/components/common/Pagination.vue";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import {useOrder} from "@/composables/orderBy";
import {lockerControlStatusListSortFields} from "@/util/sortFields";
import LockerControlStatusListItem from "@/components/locker/LockerControlStatusListItem.vue";
import {fetchLockerControlLogs} from "@/api/locker/locker";

const authStore = useAuthStore();

const companyId = ref(authStore.isSuperAdmin ? null : authStore.companyId);

const {
  sortFieldsState,
  getOrderBy,
  resetOrderBy,
  setOrderBy
} = useOrder(lockerControlStatusListSortFields, fetchLockerControlInfo);

const lockerControl = ref([]);

const filterInfo = ref({
  controlType: null,
  dateRange: [],
});

const searchInfo = ref({
  searchKey: 'areaName',
  searchValue: null,
});

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});

onMounted(async () => {
  await fetchLockerControlInfo();
});

watch([filterInfo,
  () => pagination.value.pageSize,
  () => pagination.value.pageNumber,
  companyId,
], fetchLockerControlInfo, {deep: true});

async function fetchLockerControlInfo() {
  console.log(filterInfo.value.controlType)
  try {
    const {data} = await fetchLockerControlLogs(companyId.value, {
          controlSource: filterInfo.value.controlType,
          dateRange: filterInfo.value.dateRange || [],
        }, {
          orderKey: getOrderBy()?.key,
          orderValue: getOrderBy()?.direction
        },
        searchInfo.value,
        pagination.value);
    console.log(data);
    pagination.value.pageNumber = data.pageNumber;
    pagination.value.pageSize = data.pageSize;
    pagination.value.totalCount = data.totalCount;

    lockerControl.value = data.lockerControlLogDataList;
    lockerControl.value.forEach(control => control.isSelected = false);
  } catch (e) {
    console.error(e);
  }
}

function onTabSelected() {
  pagination.value.pageNumber = 1;
  resetOrderBy();
  fetchLockerControlInfo();
}

function setPageSize() {
  resetOrderBy();
  pagination.value.pageNumber = 1;
  fetchLockerControlInfo();
}
</script>

<style scoped>
.inner_td.m_date {
  flex-wrap: nowrap;
}
</style>
