<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">회원정보 수정</div>
        <div class="description">회원 정보를 수정합니다.</div>
        <article class="right_body" >
          <MemberForm :isEdit="true" v-model:storeUser="storeUser" v-if="isRendering" ref="formInputs"/>
          <div class="btn_bottom_a">
            <a @click.prevent="editMember" href="###" class="on">
              저장
            </a>
            <router-link to="/member/list">취소</router-link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
import FilterTabs from "@/components/common/FilterTabs.vue";
import MemberForm from "@/components/member/MemberForm.vue";
import {onMounted, ref, watch} from "vue";
import {fetchStoreUser, modifyStoreUser, registerStoreUser} from "@/api/storeUser";
import {splitByHyphen} from "@/util/formatUtils";

const router = useRouter();
const route = useRoute();
const isRendering = ref(false);

const isEdited = ref(false);
const formInputs = ref(null);

const storeUser = ref({
  storeUserId: null,
  companyId : null,
  companyName: null,
  name : '',
  phoneNumber : ['010','',''],
  birthday : '',
  gender : '',
  description : '',
  phoneValid: true
});

const initialState =ref({});

onMounted(async ()=>{
  try {
    const {data} = await fetchStoreUser(route.params.memberId);
    storeUser.value.birthday = data.birthday;
    storeUser.value.companyId = data.companyId;
    storeUser.value.companyName = data.companyName;
    storeUser.value.phoneNumber = splitByHyphen(data.storeUserPhoneNumber);
    storeUser.value.name = data.storeUserName;
    storeUser.value.storeUserId = data.storeUserId;
    storeUser.value.gender = data.gender;
    storeUser.value.description = data.description||'';

    initialState.value = JSON.parse(JSON.stringify(storeUser.value));
    isRendering.value = true;
  } catch (e) {
    console.error(e);
  }
});

onBeforeRouteLeave((to, from, next) => {
  if (isStateChanged() && !isEdited.value) {
    if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
  }
  next();
});

function isStateChanged() {
  return JSON.stringify(initialState.value) !== JSON.stringify(storeUser.value);
}

async function editMember(){
  if (!checkRequired()) {
    alert('필수 입력 정보를 모두 입력/확인 해주세요');
    return;
  }
  try{
    const resp = await modifyStoreUser(storeUser.value);
    if(resp.status === 200){
      isEdited.value = true;
      alert('저장되었습니다.');
      await router.push('/member/list');
    }
  } catch(e){
    console.error(e);
  }
}

function checkRequired() {
  if(storeUser.value.name === ''){
    formInputs.value.nameInput.focus();
    return false;
  }
  if(!storeUser.value.phoneValid){
    if (storeUser.value.phoneNumber[1].length<4){
      formInputs.value.phoneInput2.focus();
    }else{
      formInputs.value.phoneInput3.focus();
    }
    return false;
  }

  return storeUser.value.companyId !== null;
}
</script>

<style scoped></style>
