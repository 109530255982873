<template>
  <tr>
    <td class="o1"><input type="checkbox" v-model="userInfo.isSelected" /></td>
    <td class="no_mobile num">{{userInfo.number}}</td>
    <td class="num phone o8 o_br">
      <span class="only_mobile">이메일 : </span>
      <span>{{userInfo.email}}</span>
    </td>
    <td class="seat o2 o_title">
      <span>{{userInfo.name ||'-'}}</span>
    </td>
    <td class="num phone o6">
      <span class="only_mobile">휴대폰번호 : </span>
      <span>{{ toHyphenPhoneNumber(userInfo.phoneNumber) }}</span>
    </td>

    <td class="seat o4">
      <span class="only_mobile">가입수단 : </span>
      <span>{{userInfo.typeName}}</span>
    </td>
    <td class="seat o5r">
      <span class="only_mobile">탈퇴유형 : </span>
      <span>{{exitStatus}}</span>
    </td>
    <td class="num phone o7r">
      <span class="only_mobile">탈퇴일 : </span>
      <span>{{dateToString(userInfo.deletedAt)||'-'}}</span>
    </td>
    <td class="num o_stat">
      <div class="center_td">
        <button @click="accountRecovery" type="button">계정복구</button>
      </div>
    </td>
  </tr>
</template>

<script setup>

import {computed} from "vue";
import {dateToString, formatDate, toHyphenPhoneNumber} from "../../util/formatUtils";

const props = defineProps({
  userInfo:{
    type:Object,
    required: true,
  }
});

const exitStatus = computed(()=>props.userInfo.exitStatus==='ADMIN_EXIT'?'강제탈퇴':'본인탈퇴');


function accountRecovery(){
  alert('서비스 준비중입니다.');
}
</script>

<style scoped>

</style>