export const dailyAlarmOptions = [
    {label:'1분전',value:1,on:false},
    {label:'3분전',value:3,on:false},
    {label:'5분전',value:5,on:false},
    {label:'10분전',value:10,on:false},
    {label:'20분전',value:20,on:false},
    {label:'30분전',value:30,on:false},
];

export const personalAlarmOptions = [
    {label:'1일전',value:1,on:false},
    {label:'2일전',value:2,on:false},
    {label:'3일전',value:3,on:false},
    {label:'5일전',value:5,on:false},
    {label:'7일전',value:7,on:false},
    {label:'30일전',value:30,on:false},
];