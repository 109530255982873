<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 구역 목록</div>
        <div class="description">락커 구역을 관리합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr v-if="authStore.isSuperAdmin">
                <th>업체별</th>
                <td>
                  <div class="inner_td">
                    <CompanySelector v-model:companyId="companyId"/>
                    <!-- <select class="w_250">
                      <option>업체를 선택하세요</option>
                      <option>마이즈</option>
                    </select> -->
                  </div>
                </td>
              </tr>
              <tr>
                <th>구역명 검색</th>
                <td>
                  <div class="inner_td">
                    <input
                        type="text"
                        class="pop_text"
                        placeholder="구역명을 입력하세요"
                        v-model="searchInfo.searchValue"
                        @keydown.enter="fetchAreasInfo"
                    />
                    <button type="button" @click="fetchAreasInfo">검색</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="건"
                                 v-model:searchResultCount="pagination.pageSize"
                                 @resultCount="setPageSize"
            />
            <!-- <ResultCountSelector :total="42" unit="건"
                                 @resultCount="console.log"/> -->
            <button @click="router.push('/locker/area/add')" type="button">구역등록</button>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 7%"/>
                  <col style="width: 5%"/>
                  <col v-if="authStore.isSuperAdmin"/>
                  <col />
                  <col style="width: 14%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <th>
                    <input type="checkbox" v-model="isAllSelected"/>
                  </th>
                  <template v-for="sortField in sortFieldsState" :key="sortField.key ">
                    <th v-if="!sortField.super || authStore.isSuperAdmin">
                      <TableHeaderItem :headData="sortField" @sort="setOrderBy"/>
                    </th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <template v-if="areas.length !== 0">
                  <AreaListItem v-for="area in areas"
                                :key="area.areaId"
                                :areaInfo="area"/>
                </template>
                <tr class="no_data" v-else>
                  <td :colspan="authStore.isSuperAdmin?5:4">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>
          <div class="list_bottom">
            <div>
              <a @click.prevent="removeLockerArea">삭제</a>
            </div>
          </div>
          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {useAuthStore} from "@/store/auth";
import CompanySelector from "@/components/common/CompanySelector.vue";
import {onMounted, ref, watch} from "vue";
import {fetchAreaList, fetchAreas, removeLockerAreas} from "@/api/area";
import Pagination from "@/components/common/Pagination.vue";
import {useOrder} from "@/composables/orderBy";
import {areaListSortFields} from "@/util/sortFields";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import AreaListItem from "@/components/area/AreaListItem.vue";
import {useSelectAll} from "@/composables/selectAll";

const router = useRouter();
const authStore = useAuthStore();

const {sortFieldsState, setOrderBy, resetOrderBy, getOrderBy} = useOrder(areaListSortFields, fetchAreasInfo);

const companyId = ref(authStore.isSuperAdmin ? null : authStore.companyId);
const areas = ref([]);

const {isAllSelected, getSelectedIds} = useSelectAll(areas, 'areaId');

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});

const searchInfo = ref({
  searchKey: 'areaName',
  searchValue: null,
});

onMounted(async () => {
  await fetchAreasInfo();
});

watch(()=>pagination.value.pageNumber,fetchAreasInfo);

watch(companyId, async (newCompanyId) => {
  if (newCompanyId == null) {
    await fetchAreasInfo();
    return;
  }

  try {
    const {data, status} = await fetchAreaList(newCompanyId);
    if (status === 200) {
      areas.value = data;
      await fetchAreasInfo();
    }
  } catch (e) {
    console.error(e);
  }
});

async function fetchAreasInfo() {
  try {
    const resp = await fetchAreas(companyId.value,
        searchInfo.value, pagination.value,
        {
          orderKey: getOrderBy()?.key,
          orderValue: getOrderBy()?.direction == undefined ? 'desc' : getOrderBy()?.direction,
        });

    console.log(resp);

    pagination.value.pageNumber = resp.data.pageNumber;
    pagination.value.pageSize = resp.data.pageSize;
    pagination.value.totalCount = resp.data.totalCount;

    areas.value = resp.data.areaMetaDataList;
    areas.value.forEach(area => area.isSelected = false);
  } catch (e) {
    console.error(e);
  }
}

function setPageSize() {
  pagination.value.pageNumber = 1;
  resetOrderBy();
  fetchAreasInfo();
}

async function removeLockerArea() {
  const ids = getSelectedIds();
  if (ids.length === 0) {
    alert('선택된 항목이 없습니다.')
    return;
  }
  if (!confirm('삭제하시겠습니까?')) return;

  try {
    const resp = await removeLockerAreas(ids.join(','));
    console.log(resp)
    if (resp.status === 204) {
      alert('삭제되었습니다.');
      await fetchAreasInfo();
    }
  } catch (error) {
    console.error(error);
    if (error.status === 400 && error.response.data.errorCode === "Area Has Assigned Tablet") {
      const areaIds = error.response.data.areaIds;
      const areaMap = new Map(areas.value.map(area => [area.areaId, area.areaName]));

      const areaNames = areaIds
          .map(areaId => areaMap.get(areaId)) // Map에서 빠르게 areaName을 가져옴
          .filter(areaName => areaName !== undefined); // undefined 제외

      if (areaNames.length > 0) {
        alert(`소속된 중계기가 있어 삭제가 불가합니다.\n삭제불가 : ${areaNames.join(', ')}`);
      }
    }
  }
}
</script>

<style scoped></style>
