<template>
  <tr>
    <td class="num no_mobile">{{ controlInfo.number }}</td>
    <td class="seat o0 o_br" v-if="authStore.isSuperAdmin">
      <span class="only_mobile">상호명 : </span>
      <span>{{ controlInfo.companyName }}</span>
    </td>
    <td class="num seat o1 o_br">
      <span class="only_mobile">제어일시 : </span>
      <span>{{ timeToStringDetail(controlInfo.createdAt, '.') }}</span>
    </td>
    <td class="num o4r">
      <span class="only_mobile">제어유형 : </span>
      <span>{{ controlSourceInfo.text }}</span>
    </td>
    <td class="o2 o_title">
      <span class="only_mobile">락커번호 : </span>
      <span>[{{ controlInfo.areaName }}] {{ controlInfo.lockerNum }}</span>
    </td>
    <td class="seat o3 detail_page_a">
      <span class="only_mobile">회원명 : </span>
      <div class="center_td">
        <router-link :to="`/member/view/${controlInfo.storeUserId}`" class="center_td">
          <p class="text_bold long_p">{{ controlInfo.storeUserName || '-' }}</p>
          <img src="@/images/detail_page.png" />
        </router-link>
      </div>
    </td>
  </tr>

</template>

<script setup>
import {useAuthStore} from "@/store/auth";
import {computed} from "vue";
import {timeToStringDetail} from "@/util/formatUtils";

const authStore = useAuthStore();

const props = defineProps({
  controlInfo: {
    type: Object,
    required: true
  }
});

const controlSourceInfo = computed(() => {
  switch (props.controlInfo.controlSource) {
    case 'USER_APP':
      return {
        text: 'App'
      };
    case 'TABLET':
      return {
        text: '중계기'
      };
    case 'ADMIN_WEB':
      return {
        text: '관리자'
      };
    default:
      return {
        text: '-'
      }
  }
})
</script>
