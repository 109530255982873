import {defineStore} from "pinia";
import {computed, ref} from "vue";
import {existAccessToken, existRefreshToken, getRole, resetStorage,} from "@/util/authToken";
import {fetchMyInfo} from "@/api/managedUser";
import {useRouter} from "vue-router";

const SUPER_ADMIN = "superAdmin";
const router = useRouter();

export const useAuthStore = defineStore("auth", () => {
    const username = ref('');
    const role = ref(getRole() || '');
    const companyId = ref(null);
    // const role = ref("superAdmin");

    const isLogin = ref(!!existAccessToken() || !!existRefreshToken());
    // const isLogin = ref(true);

    // 로그인관련 api 추가되면 코드작성 예정
    const isSuperAdmin = computed(() => role.value === SUPER_ADMIN);

    async function hydrate() {
        try {
            const {data, status} = await fetchMyInfo();
            if (status === 200) {
                username.value = data.name;
                companyId.value = data.companyId;
                role.value = getRole();
            } else {
                logout();
            }
        } catch (e) {
            console.error(e);
            logout();
        }
    }

    function $reset() {
        username.value = "";
        role.value = "";
        isLogin.value = false;
        companyId.value = null;
    }

    function logout() {
        $reset();
        resetStorage();
    }

    return {
        username,
        role,
        companyId,
        isLogin,
        isSuperAdmin,
        $reset,
        logout,
        hydrate,
    };
},);
