<template>
  <template v-if="headData.key">
    <a @click.prevent="changeSort" >{{headData.label}}</a>
    <img src="@/images/up_icon_s.png" :class="{show : headData.direction === 'asc'}"/>
    <img src="@/images/down_icon_s.png" :class="{show : headData.direction === 'desc'}"/>
  </template>
  <template v-else>
    {{headData.label}}
  </template>
</template>

<script setup>

const {headData} = defineProps(['headData']);
const emit = defineEmits(['sort']);

function changeSort(){
  let direction = headData.direction;
  if(!direction || direction === 'desc'){
    direction = 'asc';
  }else{
    direction = 'desc'
  }
  emit('sort',{
    key:headData.key,
    direction
  });
}

</script>

<style scoped>
a{
  cursor: pointer;
  user-select: none;
}
img{
  display: none;
}
.show{
  display: inline;
}
</style>