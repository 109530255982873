import {useRoute, useRouter} from "vue-router";
import {ref} from "vue";

// 헤더메뉴, 사이드바 버튼 클릭시 동일한 페이지일 때에도 리로드 하기 위한 composable
export function useRouteHandle(){
    const router = useRouter();
    const route = useRoute();
    const reloadCounter = ref(0);

    const navigateWithReload = (to) => {

        router.push({
            path: to,
            query: {
                ...(route.path === to ? { reload: reloadCounter.value++ } : {}),
            },
        });
    };

    return{
        navigateWithReload
    }
}