import {managedUsers} from "@/api/index";

// 업체 로그인 아이디 중복검사 API
function checkLoginId(loginId){
    return managedUsers.get(`/check-login-id`,{
        params:{
            loginId
        }
    });
}

// 로그인된 관리자의 id, 이름을 가져오는 API
function fetchMyInfo(){
    return managedUsers.get('/me');
}

// 관리자 계정 비밀번호를 변경하는 API
function modifyManagedUserPassword(currentPassword,newPassword){
    return managedUsers.patch(`/password`,{
        currentPassword,newPassword
    })
}

export {
    checkLoginId,
    fetchMyInfo,
    modifyManagedUserPassword,
}