<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 중계기 등록</div>
        <div class="description">
          락커 중계기를 등록합니다.<br />
          (*)표시는 필수 입력 정보입니다.
        </div>
        <article class="right_body">
          <div class="tbl_search tbl_info">
            <div class="part_title">기본정보</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
              </colgroup>
              <tbody>
                <tr v-if="authStore.isSuperAdmin">
                  <th>업체선택<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <CompanySelector :isViewMode="false" v-model:companyId="companyId" @change="getAreaList" />
                      <!-- <select class="w_250">
                        <option>업체를 선택하세요</option>
                        <option>마이즈</option>
                      </select> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>중계기명<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        placeholder="중계기명을 입력하세요"
                        :value="tabletName"
                        @input="inputTabletName"
                        ref="tabletNameInput"
                      />
                      <div class="validation"
                         :class="tabletNameValid.valid?'text_green':'text_red'">
                      {{ tabletNameValid.msg }}
                      </div>
                      <!-- <div class="validation text_red">
                        이미 등록된 중계기명입니다.
                      </div> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>시리얼넘버<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        placeholder="시리얼넘버를 입력하세요"
                        v-model="serialNumber"
                        ref="serialNumberInput"
                      />
                      <div class="validation"
                         :class="serialNumberValid.valid?'text_green':'text_red'">
                      {{ serialNumberValid.msg }}
                      </div>
                      <!-- <div class="validation text_red">
                        이미 등록된 시리얼넘버입니다.
                      </div> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>IP주소<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        class="w_250"
                        placeholder="IP주소를 입력하세요"
                        v-model="ipAddress"
                        ref="ipAddressInput"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>포트번호<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <input
                        type="text"
                        maxlength="5"
                        class="w_250"
                        placeholder="포트번호를 입력하세요(0~65535)"
                        v-model="port"
                        ref="portInput"
                      />
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>설치구역<span class="text_red">*</span></th>
                  <td>
                    <div class="inner_td">
                      <select class="w_250" v-model="areaId"
                              :disabled="companyId===null">
                        <option disabled :value="null">구역을 선택하세요</option>
                        <option v-for="area in areas" :value="area.areaId">{{ area.areaName }}</option>
                      </select>
                      <!-- <select class="w_250">
                        <option>설치구역을 선택하세요</option>
                        <option>1F</option>
                      </select> -->
                    </div>
                  </td>
                </tr>
                <tr>
                  <th>비고</th>
                  <td>
                    <div class="inner_td">
                      <input type="text" class="w_250" v-model="description" />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="addLockerRelay" href="###" class="on">등록</a>
            <router-link to="/locker/relay/list">취소</router-link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter, onBeforeRouteLeave} from "vue-router";
import {useAuthStore} from "@/store/auth";
import {onMounted, ref, watch} from "vue";
import CompanySelector from "@/components/common/CompanySelector.vue";
import {fetchAreaList} from "@/api/area";
import {debounce} from "lodash";
import {checkTabletName, checkSerialNumber, registerTablet} from "@/api/tablet";

const router = useRouter();
const authStore = useAuthStore();

const areas = ref([]);

const companyId = ref(null);
const tabletName = ref(null);
const serialNumber = ref(null);
const ipAddress = ref(null);
const port = ref(null);
const areaId = ref(null);
const description = ref(null);

const tabletNameInput = ref(null);
const serialNumberInput = ref(null);
const ipAddressInput = ref(null);
const portInput = ref(null);


const tabletNameValid = ref({
  msg: '',
  valid: false,
})

const serialNumberValid = ref({
  msg: '',
  valid: false,
})

const isAdded = ref(false);

onMounted(() => {
  if (!authStore.isSuperAdmin) {
    companyId.value = authStore.companyId;
  }
  getAreaList();
});

onBeforeRouteLeave((to, from, next) => {
  if (isStateChanged() && !isAdded.value) {
    if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
  }
  next();
});

async function getAreaList() {
  if (companyId.value === null) return;
  areaId.value = null;
  try {
    const {data, status} = await fetchAreaList(companyId.value);
    if (status === 200) {
      areas.value = data;
    }
  } catch (e) {
    console.error(e);
  }
}

// watch(tabletName, (newNm) => {debouncedCheckTabletName(newNm);});

const inputTabletName = (e) => {
  const inputValue = e.target.value;

  tabletName.value = inputValue; // 수동으로 값 업데이트
  debouncedCheckTabletName(inputValue); // debounce 처리
}

const debouncedCheckTabletName = debounce(async (newNm) => {
  if (!newNm) {
    tabletNameValid.value.valid = false;
    tabletNameValid.value.msg = '중계기명을 입력해주세요';
    return;
  }
  if (!companyId.value) {
    alert('업체를 먼저 선택해주세요');
    tabletName.value = null;
    return;
  }

  try {
    const {data} = await checkTabletName(companyId.value, newNm);
    if (data.exists) {
      tabletNameValid.value.valid = false;
      tabletNameValid.value.msg = '이미 등록된 중계기명입니다.';
    } else {
      tabletNameValid.value.valid = true;
      tabletNameValid.value.msg = '등록 가능한 중계기명입니다.';
    }
  } catch (e) {
    tabletNameValid.value.valid = false;
    console.error(e);
  }
},300);

watch(serialNumber, (newNum) => {debouncedCheckSerialNumber(newNum);});
watch(port, (newPort) => {
  // 숫자로 변환
  const num = parseInt(newPort, 10);

  // 유효성 검사
  if (newPort === '' || (isNaN(num) || num < 0 || num > 65535)) {
    port.value = newPort.slice(0, -1); // 마지막 입력값 제거
  }
});

watch(companyId,()=>{
  debouncedCheckTabletName(tabletName.value);
  debouncedCheckSerialNumber(serialNumber.value)
});

const debouncedCheckSerialNumber = debounce(async (newNum) => {
  if (!newNum) {
    serialNumberValid.value.valid = false;
    serialNumberValid.value.msg = '시리얼넘버를 입력해주세요';
    return;
  }

  try {
    const {data} = await checkSerialNumber(newNum);
    if (data.exists) {
      serialNumberValid.value.valid = false;
      serialNumberValid.value.msg = '이미 등록된 시리얼넘버입니다.';
    } else {
      serialNumberValid.value.valid = true;
      serialNumberValid.value.msg = '등록 가능한 시리얼넘버입니다.';
    }
  } catch (e) {
    serialNumberValid.value.valid = false;
    console.error(e);
  }
},300);

function checkRequired() {
  if(!tabletNameValid.value.valid){
    tabletNameInput.value.focus();
    return false;
  }
  if(!serialNumberValid.value.valid){
    serialNumberInput.value.focus();
    return false;
  }
  if(!ipAddress.value){
    ipAddressInput.value.focus();
    return false;
  }
  if(!port.value){
    portInput.value.focus();
    return false;
  }
  return !!(companyId.value && areaId.value);
}

function isStateChanged() {
  return !!(companyId.value || tabletName.value || serialNumber.value ||
  ipAddress.value || port.value || areaId.value || description.value);
}

async function addLockerRelay() {
  if (!checkRequired()) {
    alert('락커 중계기 정보를 모두 입력해 주세요');
    return;
  }

  try {
    const {status} = await registerTablet({
      companyId: companyId.value,
      tabletName: tabletName.value,
      serialNumber: serialNumber.value,
      ipAddress: ipAddress.value,
      port: Number(port.value),
      areaId: areaId.value,
      description: description.value,
    });
    if (status === 201) {
      isAdded.value = true;
      alert('등록되었습니다.')
      await router.push('/locker/relay/list');
    }
  } catch (e) {
    console.error(e);
  }
}

</script>

<style scoped>

</style>
