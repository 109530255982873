<template>
  <tr>
    <th>주소<span class="text_red">*</span></th>
    <td class="td_address">
      <div>
        <input
            type="text"
            class="w_150"
            placeholder="우편번호"
            readonly
            v-model="addressInfo.zipCode"
        />
        <button ref="findAddressBtn" @click="findAddress" type="button" class="keep">주소찾기</button>
      </div>
      <input v-model="addressInfo.address" type="text" class="w_250" placeholder="주소"
             ref="addressInput"
      />
      <input v-model="addressInfo.addressDetail" type="text" class="w_250" placeholder="상세주소"
             ref="addressDetailInput"
      />
    </td>
  </tr>
</template>

<script setup>
import {useCompanyStore} from "@/store/company";
import {storeToRefs} from "pinia";

const company = useCompanyStore();

const {addressInfo,findAddressBtn,addressInput,addressDetailInput} = storeToRefs(company);

// 주소찾기 후 상세주소칸에 포커스를 주기 위한 상태값

function findAddress() {
  const width = 500; //팝업의 너비
  const height = 600; //팝업의 높이
  new window.daum.Postcode({
    oncomplete: (data) => {
      addressInfo.value.zipCode = data.zonecode;
      addressInfo.value.address = data.address;
      addressInfo.value.addressDetail = `${data.buildingName.replaceAll('-', ' ')} `;

      addressDetailInput.value.focus();
    }
  }).open({
    popupKey: 'popup',
    left: (window.screen.width / 2) - (width / 2),
    top: (window.screen.height / 2) - (height / 2)
  });
}

</script>

<style scoped>

</style>