<template>
  <div id="Modal" class="d_pop">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">락커연장</div>
        <a @click.prevent="modal = false" class="cancel">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%"/>
              <col/>
            </colgroup>
            <tbody>
            <tr>
              <th>락커번호</th>
              <td>
                <span>[{{ modalData.areaName }}] {{ modalData.lockerNum }} - </span>
                <span class="accent_color bold">
                  {{ isDaily ? '일일락커' : '개인락커' }}
                </span>
              </td>
            </tr>
            <tr>
              <th>회원</th>
              <td>
                <div class="inner_td">
                  <span class="bold">{{ modalData.storeUserName }}</span>
                  <span>({{ modalData.phoneNumber }})</span>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ isDaily ? '이용시간' : '이용기간' }}</th>
              <td>
                <div class="inner_td">
                  {{ getFormattedTime(modalData.usageStartTime) }} ~ {{ getFormattedTime(modalData.usageEndTime) }}
                </div>
              </td>
            </tr>
            <tr>
              <template v-if="isDaily">
                <th>연장시간 선택</th>
                <td>
                  <div class="inner_td">
                    <select class="w_150" v-model="usageTime.hours">
                      <option :value="null">직접입력</option>
                      <option v-for="i in maxExtensionTime" :value="i-1">{{ i - 1 }}시간</option>
                    </select>
                    <select class="w_150" v-model="usageTime.minute"
                            :disabled="usageTime.hours===null"
                    >
                      <option :value="0">0분</option>
                      <option :value="15">15분</option>
                      <option :value="30">30분</option>
                      <option :value="45">45분</option>
                    </select>
                  </div>
                </td>
              </template>
              <template v-else>
                <th>연장기간 선택</th>
                <td>
                  <div class="inner_td">
                    <select class="w_150" v-model="usagePeriod">
                      <option :value="null">직접입력</option>
                      <option v-for="i in 12" :value="i">{{ i }}개월</option>
                    </select>
                  </div>
                </td>
              </template>
            </tr>
            <tr>
              <th>이용{{ isDaily ? '시간' : '기간' }} 연장</th>
              <td>
                <div class="inner_td">
                  <div class="flex align-center">
                    <DatePicker class="datetime-input w_200"
                                :disabled="true"
                                v-model="startTime"
                                placeholder="이용시간"
                                :format="isDaily?'yy.MM.dd HH:mm':'yy.MM.dd'"
                                locale="ko" auto-apply
                                week-start="0"
                                :clearable="false"
                    />
                    ~
                  </div>
                  <DatePicker class="datetime-input w_200"
                              :disabled="usageTime.hours !== null && usagePeriod !==null"
                              v-model="extensionDate"
                              placeholder="이용시간"
                              :format="isDaily?'yy.MM.dd HH:mm':'yy.MM.dd'"
                              locale="ko" auto-apply
                              week-start="0"
                              :clearable="false"
                              :min-date="endTime"
                              :max-date="isDaily?limitTime:''"
                  />
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="d_pop_btn">
        <button @click="extensionLocker" type="button" class="on">확인</button>
        <button @click="modalOff" type="button">취소</button>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, onMounted, ref, watch} from "vue";
import {formatDate, formatDateTime} from "@/util/formatUtils";
import {extensionLockerUsage} from "@/api/locker/lockerUsage";
import {getHourDiff} from "@/util/calculater";

const modal = defineModel('modal');
const modalData = defineModel('modalData');
const emits = defineEmits(['complete']);

const usageTime = ref({
  hours: 1,
  minute: 0
});

const usagePeriod = ref(1);
const isDaily = computed(() => modalData.value.usageType === 'daily');
const startTime = computed(() => new Date(modalData.value.usageStartTime));
const endTime = computed(() => new Date(modalData.value.usageEndTime));

const extensionDate = ref(isDaily.value ? calculateExtendTime() : calculateExtendPeriod());
const initialDate = ref(extensionDate.value);

const maxExtensionTime = computed(()=>24-getHourDiff(startTime.value,endTime.value));
const limitTime = computed(() => {
  const time = new Date(startTime.value);
  time.setDate(time.getDate() + 1);
  return time;
});

watch(usageTime, (time) => {
  if (time.hours === null) {
    usageTime.value.minute = 0;
    return;
  }
  extensionDate.value = calculateExtendTime();
}, {deep: true});

watch(usagePeriod, () => extensionDate.value = calculateExtendPeriod());

async function extensionLocker() {
  if (!extensionDate.value) {
    alert('락커 연장 정보를 모두 입력해주세요');
    return;
  }
  try {
    const {status} = await extensionLockerUsage(modalData.value.lockerUsageId, extensionDate.value);
    if (status === 204) {
      alert('연장되었습니다.');
      emits('complete');
    }
  } catch (e) {
    console.error(e);
  }
}


function getFormattedTime(time) {
  return isDaily.value ? formatDateTime(time) : formatDate(time);
}

function calculateExtendPeriod() {
  let period = new Date(endTime.value);
  let end = new Date(period.setMonth(period.getMonth() + usagePeriod.value));
  end.setHours(23, 59, 59);
  return new Date(end);
}

function calculateExtendTime() {
  let time = new Date(endTime.value);
  return time.setMinutes(time.getMinutes() + usageTime.value.hours * 60 + usageTime.value.minute);
}

function isChanged(){
  return initialDate.value !== extensionDate.value;
}

function modalOff(){
  if(isChanged()){
    if(!confirm('입력 중인 내용이 사라집니다.')) return;
  }
  modal.value = false;
}


</script>

<style scoped>
.align-start {
  align-items: start;
}
</style>