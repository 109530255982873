// 월 차이 계산
export function getMonthDiff(d1, d2) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    const diffDate = date1.getTime() - date2.getTime();
    return Math.floor(Math.abs(diffDate / (1000 * 60 * 60 * 24 * 30)));
}

// 시간 차이 계산
export function getHourDiff(d1, d2) {
    const date1 = new Date(d1);
    const date2 = new Date(d2);

    // 두 날짜의 밀리초 차이 계산
    const diffTime = date1.getTime() - date2.getTime();

    // 시간 단위로 변환 (1시간 = 1000ms * 60s * 60m)
    return Math.floor(Math.abs(diffTime / (1000 * 60 * 60)));
}
