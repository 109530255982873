<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 배정이력</div>
        <div class="description">락커 배정, 연장, 회수 이력을 조회합니다.</div>
        <article class="right_body">
          <!--검색툴 -->
          <div class="tbl_search seach_mobile">
            <table>
              <colgroup>
                <col style="width: 15%"/>
                <col/>
              </colgroup>
              <tbody>
              <tr v-if="authStore.isSuperAdmin">
                <th>업체별</th>
                <td>
                  <div class="inner_td">
                    <CompanySelector v-model:companyId="companyId"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>기간별</th>
                <td>
                  <div class="inner_td m_date2">
                    <select v-model="filterInfo.filterDateBy">
                      <option value="createdAt">처리일</option>
                      <option value="usageStartTime">시작일</option>
                      <option value="usageEndTime">만료일</option>
                    </select>
                    <DatePicker class="w_300" v-model="filterInfo.dateRange"
                                :format="'yyyy-MM-dd'"
                                :enable-time-picker="false" auto-apply
                                placeholder="기간별 조회"
                                locale="ko" range
                                week-start="0"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>구분별</th>
                <td>
                  <FilterTabs v-model:select-tab="filterInfo.usageEventType"
                              :tabs="divisionOptions"
                  />
                </td>
              </tr>
              <tr>
                <th>배정유형별</th>
                <td>
                  <FilterTabs v-model:select-tab="filterInfo.usageType"
                              :tabs="assigmentOptions"
                              @tab-selected="console.log(filterInfo.usageType)"/>
                </td>
              </tr>
              <tr>
                <th>키워드검색</th>
                <td>
                  <div class="inner_td">
                    <select v-model="searchInfo.searchKey">
                      <option value="lockerNum">락커번호</option>
                      <option value="areaName">구역명</option>
                      <option value="storeUserName">이름</option>
                    </select>
                    <input
                        type="text"
                        class="pop_text"
                        placeholder="검색어를 입력하세요"
                        v-model="searchInfo.searchValue"
                        @keydown.enter="fetchLockerAssigmentLogs"
                    />
                    <button @click="fetchLockerAssigmentLogs" type="button">검색</button>
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <!-- //검색툴 -->
          <div class="count_page">
            <ResultCountSelector :total="pagination.totalCount" unit="건"
                                 v-model:search-result-count="pagination.pageSize"
                                 @resultCount="console.log(pagination.pageSize)"/>
          </div>
          <div class="right_contents">
            <!--목록-->
            <div class="tbl_list table_m mobile_table">
              <table>
                <colgroup>
                  <col style="width: 5%"/>
                  <col v-if="authStore.isSuperAdmin"/>
                  <col style="width: 7%"/>
                  <col style="width: 13%"/>
                  <col style="width: 9%"/>
                  <col style="width: 8%"/>
                  <col style="width: 14%"/>
                  <col style="width: 14%"/>
                  <col style="width: 14%"/>
                </colgroup>
                <thead class="no_mobile">
                <tr>
                  <template v-for="sortField in sortFieldsState" :key="sortField.key">
                    <th v-if="!sortField.super || authStore.isSuperAdmin">
                      <TableHeaderItem :headData="sortField" @sort="setOrderBy"/>
                    </th>
                  </template>
                </tr>
                </thead>
                <tbody>
                <template v-if="assigmentHistories.length>0">
                  <AssignmentHistoryListItem v-for="assignmentInfo in assigmentHistories"
                                             :key="assignmentInfo.lockerUsageLogId"
                                             :assignmentInfo="assignmentInfo"
                  />

                </template>
                <tr class="no_data" v-else>
                  <td :colspan="authStore.isSuperAdmin?9:8">데이터가 없습니다</td>
                </tr>
                </tbody>
              </table>
            </div>
            <!--//목록-->
          </div>

          <!-- 페이징 -->
          <div class="page_num">
            <Pagination v-model:pageInfo="pagination"/>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import FilterTabs from "@/components/common/FilterTabs.vue";
import ResultCountSelector from "@/components/common/ResultCountSelector.vue";
import {onMounted, ref, watch} from "vue";
import {assigmentOptions, divisionOptions} from "@/util/filterFields";
import {useAuthStore} from "@/store/auth";
import CompanySelector from "@/components/common/CompanySelector.vue";
import Pagination from "@/components/common/Pagination.vue";
import {useOrder} from "@/composables/orderBy";
import {assignmentHistoryListSortFields} from "@/util/sortFields";
import TableHeaderItem from "@/components/common/TableHeaderItem.vue";
import AssignmentHistoryListItem from "@/components/locker/AssignmentHistoryListItem.vue";
import {fetchLockerUsageLogs} from "@/api/locker/lockerUsage";
import {useRoute} from "vue-router";

const authStore = useAuthStore();
const route = useRoute();

const {
  sortFieldsState,
  getOrderBy,
  resetOrderBy,
  setOrderBy
} = useOrder(assignmentHistoryListSortFields, fetchLockerAssigmentLogs);

const companyId = ref(authStore.isSuperAdmin ? null : authStore.companyId);
const assigmentHistories = ref([]);

const filterInfo = ref({
  usageEventType: null,
  usageType: null,
  filterDateBy: 'createdAt',
  dateRange: [],
});

const searchInfo = ref({
  searchKey: 'lockerNum',
  searchValue: null,
});

const pagination = ref({
  pageNumber: 1,
  pageSize: 5,
  totalCount: 0
});


onMounted(() => {
  const storeUserName = route.query.name;
  if (storeUserName) {
    searchInfo.value.searchKey = 'storeUserName';
    searchInfo.value.searchValue = storeUserName;
  }

  fetchLockerAssigmentLogs();
});

watch([companyId, filterInfo,
  () => pagination.value.pageSize,
], setPageSize, {deep: true});

watch(() => pagination.value.pageNumber, fetchLockerAssigmentLogs);


async function fetchLockerAssigmentLogs() {
  try {
    const {data} = await fetchLockerUsageLogs(companyId.value, filterInfo.value,
        {
          orderKey: getOrderBy()?.key,
          orderValue: getOrderBy()?.direction
        },
        searchInfo.value, pagination.value);

    console.log(data);
    pagination.value.pageNumber = data.pageNumber;
    pagination.value.pageSize = data.pageSize;
    pagination.value.totalCount = data.totalCount;

    assigmentHistories.value = data.lockerUsageLogDataList;

  } catch (e) {
    console.error(e);
  }
}


function setPageSize() {
  resetOrderBy();
  pagination.value.pageNumber = 1;
  fetchLockerAssigmentLogs();
}


</script>

<style scoped></style>
