<template>
  <div id="Modal" class="d_pop">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">락커수정</div>
        <a @click.prevent="modalOff" class="cancel" href="###">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%"/>
              <col/>
            </colgroup>
            <tbody>
            <tr>
              <th>락커번호</th>
              <td>[{{ modalData.areaName }}] {{ modalData.lockerNum }}</td>
            </tr>
            <tr>
              <th>회원</th>
              <td>
                <div class="inner_td relative member_search">
                  <input
                      tyoe="text"
                      class="w_250"
                      placeholder="이름 또는 휴대폰번호로 검색"
                      disabled
                      :value="`${modalData.storeUserName}(${modalData.phoneNumber})`"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>락커유형</th>
              <td>
                <FilterTabs :tabs="[
                      { label: '일일락커', value: 'daily' },
                      { label: '개인락커', value: 'personal' },]"
                            v-model:select-tab="lockerType"/>
              </td>
            </tr>
            <!--일일락커 선택시에만 노출-->

            <template v-if="lockerType==='daily'">
              <tr>
                <th>이용시간 선택</th>
                <td>
                  <div class="inner_td">
                    <select v-model="usageTime.hours" class="w_150">
                      <option :value="null">직접입력</option>
                      <option v-for="i in 24" :value="i-1">{{ i - 1 }}시간</option>
                    </select>
                    <select class="w_150" v-model="usageTime.minute" :disabled="usageTime.hours===null">
                      <option :disabled="usageTime.hours===0" :value="0">0분</option>
                      <option :value="15">15분</option>
                      <option :value="30">30분</option>
                      <option :value="45">45분</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>이용시간</th>
                <td>
                  <div class="inner_td time-range-picker">
                    <div class="flex align-center">
                      <DatePicker class="datetime-input w_200" v-model="usageDateRange[0]"
                                  placeholder="이용시간"
                                  format="yy.MM.dd HH:mm"
                                  locale="ko" auto-apply
                                  week-start="0"
                                  :clearable="false"
                                  :min-date="new Date()"
                                  @closed="calculateEndTime"
                      />
                      ~
                    </div>
                    <DatePicker class="datetime-input w_200" v-model="usageDateRange[1]"
                                :disabled="usageTime.hours !== null"
                                placeholder="이용시간"
                                format="yy.MM.dd HH:mm"
                                locale="ko" auto-apply
                                week-start="0"
                                :clearable="false"
                                :min-date="usageDateRange[0]"
                                :max-date="limitTime"
                    />
                  </div>
                </td>
              </tr>
            </template>
            <!--//일일락커 선택시에만 노출-->
            <!--개인락커 선택시에만 노출-->
            <template v-else>
              <tr>
                <th>이용기간 선택</th>
                <td>
                  <div class="inner_td">
                    <select v-model="usagePeriod">
                      <option :value="null">직접입력</option>
                      <option v-for="i in 12" :value="i">{{ i }}개월</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>이용기간</th>
                <td>
                  <div class="inner_td">
                    <div class="flex align-center">
                      <DatePicker class="datetime-input w_200" v-model="usageDateRange[0]"
                                  :format="'yyyy.MM.dd'"
                                  :enable-time-picker="false" auto-apply
                                  placeholder="이용기간"
                                  locale="ko"
                                  week-start="0"
                                  :clearable="false"
                                  :min-date="new Date()"
                                  @closed="calculateEndDate"
                      />
                      ~
                    </div>
                    <DatePicker class="datetime-input w_200" v-model="usageDateRange[1]"
                                :disabled="usagePeriod!==null"
                                :format="'yyyy.MM.dd'"
                                :enable-time-picker="false" auto-apply
                                placeholder="이용기간"
                                locale="ko"
                                week-start="0"
                                :clearable="false"
                                :min-date="usageDateRange[0]"
                    />
                  </div>
                </td>
              </tr>
            </template>
            <!--//개인락커 선택시에만 노출-->
            </tbody>
          </table>
        </div>
      </div>
      <div class="d_pop_btn">
        <button @click="modifyUsage" type="button" class="on">확인</button>
        <button @click="modalOff" type="button">취소</button>
      </div>
    </div>
  </div>
</template>

<script setup>

import FilterTabs from "@/components/common/FilterTabs.vue";
import {computed, onMounted, ref, watch} from "vue";
import {value} from "lodash/seq";
import {formatDate, formatDateTime} from "@/util/formatUtils";
import {getMonthDiff} from "@/util/calculater";
import {modifyLockerUsageTime} from "@/api/locker/lockerUsage";

const modal = defineModel('modal');
const modalData = defineModel('modalData');
const emits = defineEmits(['complete']);

const lockerType = ref(modalData.value.usageType);

console.log(modalData.value);

const usageTime = ref({
  hours: Math.floor(modalData.value.dailyDefaultDuration / 60),
  minute: modalData.value.dailyDefaultDuration % 60
}); // 이용시간

const monthDiff = getMonthDiff(modalData.value.usageStartTime,
    modalData.value.usageEndTime);
const usagePeriod = ref(monthDiff <= 1 ? 1 : monthDiff);
const usageDateRange = ref([
  formatDateTime(modalData.value.usageStartTime),
  formatDateTime(modalData.value.usageEndTime),
]);

const initialValue = ref({});


const maxTime = computed(() => {
  const time = new Date(usageDateRange.value[0]);
  // time.setDate(time.getDate()+1);
  time.setHours(time.getHours() + (usageTime.value.hours === null?1:usageTime.value.hours));
  time.setMinutes(time.getMinutes() + (usageTime.value.hours === null?0:usageTime.value.minute));
  return time;
});

const limitTime = computed(() => {
  const time = new Date(usageDateRange.value[0]);
  time.setDate(time.getDate() + 1);
  return time;
});

const maxDate = computed(() => {
  const max = new Date(usageDateRange.value[0]);
  max.setMonth(max.getMonth() + usagePeriod.value === null ? 1 : usagePeriod.value - 1);
  return max;
});


onMounted(() => {
  initialValue.value.startDate = usageDateRange.value[0];
  initialValue.value.endDate = usageDateRange.value[1];
});

watch(lockerType, (type) => {
  if (type === 'daily') {
    usageDateRange.value = calculateTimeRange(
        usageTime.value.hours * 60 + usageTime.value.minute,
        new Date(formatDateTime(modalData.value.usageStartTime))
    );
  } else {
    usageDateRange.value = calculatePeriodRange(usagePeriod.value, new Date(formatDate(modalData.value.usageStartTime)));
  }
});

watch(usagePeriod, (month) => {
  if (!month) return;
  usageDateRange.value = calculatePeriodRange(
      month,
      new Date(formatDate(modalData.value.usageStartTime))
  );
});

watch(usageTime, (time) => {
  if (time.hours === null) {
    usageTime.value.minute = 0;
    return;
  }
  usageDateRange.value = calculateTimeRange(
      time.hours * 60 + time.minute,
      new Date(usageDateRange.value[0])
  );
}, {deep: true});

function calculateEndTime() {
  usageDateRange.value[1] = maxTime.value;
}

function calculateEndDate() {
  if(!usagePeriod.value) return;
  usageDateRange.value[1] = maxDate.value;
}

async function modifyUsage() {
  try {
    const {status} = await modifyLockerUsageTime(modalData.value.lockerUsageId, {
      usageType: lockerType.value,
      usageStartTime: formatDateTime(usageDateRange.value[0], '-', '+09:00'),
      usageEndTime: formatDateTime(usageDateRange.value[1], '-', '+09:00'),
    });
    if (status === 200) {
      alert('수정되었습니다.');
      emits('complete');
    }
  } catch (e) {
    console.error(e);
  }
}

function modalOff() {
  if (isChanged()) {
    if (!confirm('입력 중인 내용이 사라집니다.')) return;
  }
  modal.value = false;
}

function isChanged() {
  return (initialValue.value.startDate !== usageDateRange.value[0]
      || initialValue.value.endDate !== usageDateRange.value[1])
}

// 이용 기간 계산
function calculatePeriodRange(month = 1, start = new Date()) {
  let endDate = new Date(start).setMonth(start.getMonth() + month);
  endDate = new Date(endDate).setHours(0, 0, 0);
  return [start, new Date(endDate)];
}

// 이용 시간 계산
function calculateTimeRange(minutes, start = new Date()) {
  let endDate = new Date(start).setMinutes(start.getMinutes() + minutes);
  return [start, endDate];
}

</script>

<style scoped></style>