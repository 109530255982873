<template>
  <tr>
    <td class="no_mobile num">{{ dateToString(lockerUsagesInfo.registrationDate, '.') || '-' }}</td>
    <td class="seat o1 o_br">
      <span class="only_mobile">유형 : </span>
      <span>{{ lockerType }}</span>
    </td>
    <td class="seat o2 o_title">
      <span class="only_mobile">락커정보 : </span>
      <span>[{{ lockerUsagesInfo.areaName }}] {{ lockerUsagesInfo.lockerNum }}</span>
    </td>
    <td class="num o4 o_br">
      <span class="only_mobile">이용기간 : </span>
      <span>{{ usagePeriod.startdate }}</span> <b>{{ usagePeriod.starttime }}</b> ~
      <span>{{ usagePeriod.enddate }}</span> <b>{{ usagePeriod.endtime }}</b>
      <!-- <br/>
      <span class="bold"> (31일)</span> -->
    </td>
    <td class="o3">
      <span class="only_mobile">상태 : </span>
      <span class="bold" :class="usageStatus.class">{{ usageStatus.text }}</span>
      <template v-if="!isAvailable">
        <br/>
        <span class="text_grey">{{ usageStatus.timeRemaining }}</span>
      </template>
    </td>
    <td class="o_stat">
      <div class="center_td inner_td">
        <template v-if="!isAvailable">
          <button @click="onExtensionModal" type="button">연장</button>
          <button type="button" @click="emits('retrieve', lockerUsagesInfo.lockerUsageId)">회수</button>
        </template>
      </div>
      <!-- <div class="center_td">
          <div class="inner_td">
          <button type="button">연장</button>
          <button type="button">회수</button>
          </div>
      </div> -->
    </td>
  </tr>
  <!-- <tr>
      <td class="no_mobile num">2024-07-01</td>
      <td class="seat o1 o_br">
      <span class="only_mobile">유형 : </span>
      <span>개인락커</span>
      </td>
      <td class="seat o2 o_title">
      <span class="only_mobile">락커정보 : </span>
      <span>[여자탈의실] 237번</span>
      </td>
      <td class="num o4 o_br">
      <span class="only_mobile">이용기간 : </span>
      <span>2024-07-01</span> <b>15:00</b> ~
      <span>2024-07-01</span> <b>18:00</b>
      <br/>
      <span class="bold">(3시간)</span>
      </td>
      <td class="o3">
      <span class="only_mobile">상태 : </span>
      <span class="text_red bold">기간만료</span>
      </td>
      <td class="o_stat">
      <div class="center_td">
          <div class="inner_td">
          <button type="button">연장</button>
          <button type="button">회수</button>
          </div>
      </div>
      </td>
  </tr>
  <tr>
      <td class="no_mobile num">2024-07-01</td>
      <td class="seat o1 o_br">
      <span class="only_mobile">유형 : </span>
      <span>개인락커</span>
      </td>
      <td class="seat o2 o_title">
      <span class="only_mobile">락커정보 : </span>
      <span>[여자탈의실] 237번</span>
      </td>
      <td class="num o4 o_br">
      <span class="only_mobile">이용기간 : </span>
      <span>2024-07-01 ~ 2024-07-31</span>
      <br/>
      <span class="bold"> (31일)</span>
      </td>
      <td class="o3">
      <span class="only_mobile">상태 : </span>
      <span class="text_grey bold">사용완료</span>
      </td>
      <td class="o_stat">
      <div class="center_td">
          <div class="inner_td">
          <button type="button">삭제</button>
          </div>
      </div>
      </td>
  </tr> -->
</template>

<script setup>
import {computed} from "vue";
import {useAuthStore} from "@/store/auth";
import {dateToString, timeToString} from "@/util/formatUtils";

const authStore = useAuthStore();

const emits = defineEmits(['onExtensionModal', 'onAssigmentModal', 'retrieve']);

const props = defineProps({
  lockerUsagesInfo: {
    type: Object,
    required: true
  }
});

const isAvailable = computed(() => props.lockerUsagesInfo.usageStatus === 'completed');

const lockerType = computed(() => {
  switch (props.lockerUsagesInfo.usageType) {
    case 'personal':
      return '개인락커';
    case 'daily':
      return '일일락커';
    default:
      return '-';
  }
});

// TODO 초과시간 계산 백엔드 수정 결과에 따라 다시 수정하기
// TODO 사용중이지만 만료일시가 지난 건은 기간만료로 처리하기
const usageStatus = computed(() => {
  switch (props.lockerUsagesInfo.usageStatus) {
    case 'inUse':
      return {
        text: '사용중',
        class: 'text_green',
        timeRemaining: `잔여 ${calculateRemainingDate(props.lockerUsagesInfo.remainingOrExceededTime)}일`
      };
    case 'expired':
      return {
        text: '기간만료',
        class: 'text_red',
        timeRemaining: calculateReamainingTime(props.lockerUsagesInfo.usageEndTime,
            props.lockerUsagesInfo.remainingOrExceededTime),
      };
    default:
      return {text: '사용완료', class: 'text_grey',};
  }
});

function calculateRemainingDate(minute) {
  return Math.floor(minute / 1440);
}

function calculateReamainingTime(referenceTime, timeRemaining) {
  const minutesInADay = 1440;
  const referenceDateTime = new Date(referenceTime.replace('Z', ''));

  // 기준 시간으로부터 경과된 시간을 추가한 결과 시간 계산
  const elapsedDate = new Date(referenceDateTime.getTime() + timeRemaining * 60000);

  // 경과 시간이 하루 미만일 때 시, 분으로 표시
  if (timeRemaining < minutesInADay) {
    const hours = elapsedDate.getHours();
    const minutes = elapsedDate.getMinutes();

    // return `${hours}:${minutes.toString().padStart(2,'0')} 초과`;
    return `${Math.floor(timeRemaining / 60)}:${minutes.toString().padStart(2, '0')} 초과`;
  } else {
    // 하루 이상일 경우 일 수로 표시
    const days = calculateRemainingDate(timeRemaining);
    return `${days}일 초과`;
  }
}

const usagePeriod = computed(() => {
  switch (props.lockerUsagesInfo.usageType) {
    case 'personal':
      return {
        startdate: dateToString(props.lockerUsagesInfo.usageStartTime, '.') || '-',
        starttime: '',
        enddate: dateToString(props.lockerUsagesInfo.usageEndTime, '.') || '-',
        endtime: ''
      }
    case 'daily':
      return {
        startdate: timeToString(props.lockerUsagesInfo.usageStartTime, '.').split(' ')[0] || '-',
        starttime: timeToString(props.lockerUsagesInfo.usageStartTime, '.').split(' ')[1] || '',
        enddate: timeToString(props.lockerUsagesInfo.usageEndTime, '.').split(' ')[0] || '-',
        endtime: timeToString(props.lockerUsagesInfo.usageEndTime, '.').split(' ')[1] || ''
      }
    default:
      return {
        startdate: '-',
        starttime: '',
        enddate: '-',
        endtime: ''
      };
  }
})

function onExtensionModal() {
  emits('onExtensionModal', props.lockerUsagesInfo);
}
</script>