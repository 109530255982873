<template>
  <div class="body_left">
    <div class="left_top no_mobile">
      <img src="@/images/topic_thum_54.png" alt="" />업체관리
    </div>
    <div class="left_body">
      <ul class="menu">
        <li  v-for="page in pages">
          <a @click.prevent="navigateWithReload(page.link)"
             class="link" :class="{ on: checkSideActive(page.matchLinks)}"
          >
            {{ page.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";
import {ref} from "vue";
import {useRouteHandle} from "@/composables/routeHadler";

const {navigateWithReload} = useRouteHandle();

const pages = ref([
  {label:'업체목록',link:'/company/list',matchLinks:['/company/list','/company/add','/company/view']},
  // {label:'업체등록',link:'/company/add'},
]);
</script>

<style scoped></style>
