<template>
    <tr>
        <td class="o1">
            <input type="checkbox" v-model="areaInfo.isSelected" />
        </td>
        <td class="no_mobile">{{areaInfo.number}}</td>
        <td class="seat o1 o_br" v-if="authStore.isSuperAdmin">
            <span class="only_mobile">상호명 : </span>
            <span>{{areaInfo.companyName}}</span>
        </td>
        <td class="detail_page_a o2 o_title">
            <div class="center_td">
            <span class="only_mobile">구역명 : </span>
            <router-link :to="`/locker/area/view/${areaInfo.areaId}`" class="center_td">
                <p class="text_bold long_p">{{areaInfo.areaName}}</p>
                <img src="@/images/detail_page.png" />
            </router-link>
            </div>
        </td>
        <td class="seat o_stat">
            <button @click="goToLockerList(areaInfo.companyId, areaInfo.areaId)" type="button">락커보기</button>
        </td>
    </tr>
</template>

<script setup>
    import {computed} from "vue";
    import {useRouter} from "vue-router";
    import {useAuthStore} from "@/store/auth";

    const router = useRouter();
    const authStore = useAuthStore();  

    const props = defineProps({
        areaInfo: {
            type: Object,
            required: true
        }
    });

    const goToLockerList = (companyId, areaId) => {
        // 쿼리 파라미터를 전달하면서 다른 페이지로 이동
        const searchParams = {
            companyId: companyId,
            areaId: areaId,
        };

        // 쿼리 파라미터로 넘기기
        router.push({ 
            name: 'LockerAdminList',  // 라우트 이름 (라우터 설정에서 정의된 이름)
            query: searchParams  // 쿼리 파라미터
        });
        };

</script>