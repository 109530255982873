<template>
  <table>
    <colgroup>
      <col style="width: 18%" class="col_width26" />
      <col />
    </colgroup>
    <tr>
      <th>표시상태<span class="text_red">*</span></th>
      <td>
        <FilterTabs v-model:select-tab="termsInfo.displayed"
                    :tabs="termsOptions" @tab-selected="console.log(termsInfo.displayed)"/>
      </td>
    </tr>
    <tr>
      <th>시행일<span class="text_red">*</span></th>
      <td>
        <div class="inner_td">
          <DatePicker class="w_250" v-model="termsInfo.effectiveDate"
                      :format="'yyyy-MM-dd'"
                      :enable-time-picker="false" auto-apply
                      placeholder="연도-월-일"
                      locale="ko" week-start="0"
                      @update:modelValue="changeEffectiveDate"
          />
          <div class="validation"
              :class="effectiveDateValid.valid?'text_green':'text_red'">
            {{ effectiveDateValid.msg }}
          </div>
        </div>
      </td>
    </tr>
    <tr>
      <th>내용<span class="text_red">*</span></th>
      <td>
        <div class="inner_td textarea_full">
          <textarea class="h_400" v-model="termsInfo.content"></textarea>
        </div>
      </td>
    </tr>
  </table>
</template>

<script setup>

import FilterTabs from "@/components/common/FilterTabs.vue";
import {termsOptions} from "@/util/filterFields";
import {onMounted, ref, watch} from "vue";
import {debounce} from "lodash";
import {checkEffectiveDateTerms, checkEffectiveDatePrivacy} from "@/api/policies";
import {dateToString} from "@/util/formatUtils";

const termsInfo = defineModel('termsInfo');
const effectiveDateValid = defineModel('effectiveDateValid');

function changeEffectiveDate(date) {
  let newDate = '';
  if (date != null && date != undefined) {
    newDate = date.getFullYear() + '-' + 
              (date.getMonth() + 1).toString().padStart(2, '0') + '-' + 
              date.getDate().toString().padStart(2, '0');
  }
  debouncedCheckEffectiveDate(newDate);        
}

const debouncedCheckEffectiveDate = debounce(async (newDate) => {
  if (!newDate) {
    effectiveDateValid.value.valid = false;
    effectiveDateValid.value.msg = '시행일을 선택해주세요';
    return;
  }

  const originDate = dateToString(effectiveDateValid.value.origin);
  if (originDate == newDate) {
    effectiveDateValid.value.valid = true;
    effectiveDateValid.value.msg = '';
    return;
  }

  if (effectiveDateValid.value.page == 'terms') {
    try {
      const {data} = await checkEffectiveDateTerms(newDate);
      if (data.isDuplicated) {
        effectiveDateValid.value.valid = false;
        effectiveDateValid.value.msg = '해당 시행일에 등록된 이용약관이 존재합니다.';
      } else {
        effectiveDateValid.value.valid = true;
        effectiveDateValid.value.msg = '등록 가능한 시행일입니다.';
      }
    } catch (e) {
      effectiveDateValid.value.valid = false;
      console.error(e);
    }
  } else if (effectiveDateValid.value.page == 'privacy') {
    try {
      const {data} = await checkEffectiveDatePrivacy(newDate);
      if (data.isDuplicated) {
        effectiveDateValid.value.valid = false;
        effectiveDateValid.value.msg = '해당 시행일에 등록된 개인정보처리방침이 존재합니다.';
      } else {
        effectiveDateValid.value.valid = true;
        effectiveDateValid.value.msg = '등록 가능한 시행일입니다.';
      }
    } catch (e) {
      effectiveDateValid.value.valid = false;
      console.error(e);
    }
  }  
},300);
</script>

<style scoped>

</style>