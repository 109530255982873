<template>
  <div class="inner_td">
    <button v-for="tab in tabs" :key="tab.value"
            type="button" :class="{on : checkedTabs.includes(tab.value)}"
            @click="clickTab(tab.value)"
    >
      {{ tab.label }}
    </button>
  </div>
</template>

<script setup>

const checkedTabs = defineModel('checkedTabs');

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  // defaultTab: {
  //   type: String,
  //   default: ''
  // }
});

function clickTab(tabValue){
  const index = checkedTabs.value.indexOf(tabValue);
  if(index !== -1){
    checkedTabs.value.splice(index,1);
  }else{
    checkedTabs.value.push(tabValue);
  }
}

</script>

<style scoped>

</style>