<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">공지사항 등록</div>
        <div class="description">
          공지사항을 등록합니다. <span class="text_red">(* 필수)</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AdminNoticeAdd",
};
</script>

<style scoped></style>
