<template>
  <div class="body_left">
    <div class="left_top no_mobile">내 정보</div>
    <div class="left_body">
      <ul class="menu">
        <li v-for="page in pages">
          <a @click.prevent="navigateWithReload(page.link)"
             class="link" :class="{ on: checkSideActive(page.matchLinks)}"
          >
            {{ page.label }}
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>
import {checkSideActive} from "@/components/Layout/common/layoutCommon";
import {ref} from "vue";
import {useRouteHandle} from "@/composables/routeHadler";

const {navigateWithReload} = useRouteHandle();

const pages = ref([
  {label: '내 정보 수정', link: '/myinfo/management', matchLinks:['/myinfo/management']},
]);

</script>

<style scoped></style>
