<template>
  <div id="Modal" class="d_pop">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">락커배정</div>
        <a @click.prevent="modalOff" class="cancel" href="###">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%"/>
              <col/>
            </colgroup>
            <tbody>
            <tr>
              <th>락커번호</th>
              <td>[{{ modalData.areaName }}] {{ modalData.lockerNum }}</td>
            </tr>
            <tr>
              <th>회원검색</th>
              <td>
                <div class="inner_td relative member_search">
                  <input
                      tyoe="text"
                      class="w_250"
                      placeholder="이름 또는 휴대폰번호로 검색"
                      :disabled="targetUserId!==null"
                      :value="searchValue"
                      @input="handleSearchInput"
                      ref="searchInput"
                  />
                  <button type="button" v-show="targetUserId!==null"
                          @click="targetUserId=null"
                  >재검색
                  </button>
                  <div class="autocomplate" v-show="searchedUsers.length > 0 && targetUserId === null">
                    <ul>
                      <li v-for="user in searchedUsers"
                          @click="()=>{
                            targetUserId = user.storeUserId;
                            searchValue=`${user.storeUserName}(${user.phoneNumber})`
                          }"
                      >
                        {{ user.storeUserName }}({{ user.phoneNumber }})
                      </li>
                    </ul>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <th>락커유형</th>
              <td>
                <FilterTabs :tabs="[
                      { label: '일일락커', value: 'daily' },
                      { label: '개인락커', value: 'personal' },]"
                            v-model:select-tab="lockerType"/>
              </td>
            </tr>
            <!--일일락커 선택시에만 노출-->

            <template v-if="lockerType==='daily'">
              <tr>
                <th>이용시간 선택</th>
                <td>
                  <div class="inner_td">
                    <select v-model="usageTime.hours" class="w_150">
                      <option :value="null">직접입력</option>
                      <option v-for="i in 24" :value="i-1">{{ i - 1 }}시간</option>
                    </select>
                    <select class="w_150" v-model="usageTime.minute" :disabled="usageTime.hours===null">
                      <option :disabled="usageTime.hours===0" :value="0">0분</option>
                      <option :value="15">15분</option>
                      <option :value="30">30분</option>
                      <option :value="45">45분</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>이용시간</th>
                <td>
                  <div class="inner_td">
                    <div class="flex align-center">
                      <DatePicker class="datetime-input w_200" v-model="usageDateRange[0]"
                                  placeholder="이용시간"
                                  format="yy.MM.dd HH:mm"
                                  locale="ko" auto-apply time-picker-inline
                                  week-start="0"
                                  :clearable="false"
                                  :min-date="new Date()"
                                  @closed="calculateEndTime"
                      />
                      ~
                    </div>
                    <DatePicker class="datetime-input w_200" v-model="usageDateRange[1]"
                                :disabled="usageTime.hours !== null"
                                placeholder="이용시간"
                                format="yy.MM.dd HH:mm"
                                locale="ko" auto-apply time-picker-inline
                                week-start="0"
                                :clearable="false"
                                :min-date="usageDateRange[0]"
                                :max-date="limitTime"
                    />
                  </div>
                </td>
              </tr>
            </template>
            <!--//일일락커 선택시에만 노출-->
            <!--개인락커 선택시에만 노출-->
            <template v-else>
              <tr>
                <th>이용기간 선택</th>
                <td>
                  <div class="inner_td">
                    <select v-model="usagePeriod">
                      <option :value="null">직접입력</option>
                      <option v-for="i in 12" :value="i">{{ i }}개월</option>
                    </select>
                  </div>
                </td>
              </tr>
              <tr>
                <th>이용기간</th>
                <td>
                  <div class="inner_td">
                    <div class="flex align-center">
                      <DatePicker class="datetime-input w_200" v-model="usageDateRange[0]"
                                  :format="'yyyy.MM.dd'"
                                  :enable-time-picker="false" auto-apply
                                  placeholder="이용기간"
                                  locale="ko"
                                  week-start="0"
                                  :clearable="false"
                                  :min-date="new Date()"
                                  @closed="calculateEndDate"
                      />
                      ~
                    </div>
                    <DatePicker class="datetime-input w_200" v-model="usageDateRange[1]"
                                :disabled="usagePeriod!==null"
                                :format="'yyyy.MM.dd'"
                                :enable-time-picker="false" auto-apply
                                placeholder="이용기간"
                                locale="ko"
                                week-start="0"
                                :clearable="false"
                                :min-date="usageDateRange[0]"
                    />
                  </div>
                </td>
              </tr>
            </template>
            <!--//개인락커 선택시에만 노출-->
            </tbody>
          </table>
        </div>
      </div>
      <div class="d_pop_btn">
        <button @click="registerUsage" type="button" class="on">확인</button>
        <button @click="modalOff" type="button">취소</button>
      </div>
    </div>
  </div>
</template>

<script setup>

import FilterTabs from "@/components/common/FilterTabs.vue";
import {computed, onMounted, ref, watch} from "vue";
import {debounce} from "lodash";
import {fetchSearchedStoreUser} from "@/api/storeUser";
import {value} from "lodash/seq";
import {registerLockerUsage} from "@/api/locker/lockerUsage";
import {formatDateTime} from "@/util/formatUtils";

const modal = defineModel('modal');
const modalData = defineModel('modalData');
const emits = defineEmits(['complete']);

const lockerType = ref('daily');

const targetUserId = ref(null);
const usageTime = ref({
  hours: Math.floor(modalData.value.dailyDefaultDuration / 60),
  minute: modalData.value.dailyDefaultDuration % 60
}); // 이용시간
const usagePeriod = ref(1);// 이용기간 1개월 기본값
const usageDateRange = ref(calculateTimeRange(modalData.value.dailyDefaultDuration));

const searchValue = ref('');
const searchedUsers = ref([]);
const searchInput = ref(null);

const initialValue = ref({});

const maxTime = computed(() => {
  const time = new Date(usageDateRange.value[0]);
  // time.setDate(time.getDate() + 1);
  time.setHours(time.getHours() + (usageTime.value.hours === null ? 1 : usageTime.value.hours));
  time.setMinutes(time.getMinutes() + (usageTime.value.hours === null ? 0 : usageTime.value.minute));
  return time;
});

const limitTime = computed(() => {
  const time = new Date(usageDateRange.value[0]);
  time.setDate(time.getDate() + 1);
  return time;
});


const maxDate = computed(() => {
  const max = new Date(usageDateRange.value[0]);
  max.setMonth(max.getMonth() + usagePeriod.value === null ? 1 : usagePeriod.value - 1);
  return max;
});

onMounted(() => {
  initialValue.value.targetUserId = null;
  initialValue.value.startDate = usageDateRange.value[0];
  initialValue.value.endDate = usageDateRange.value[1];
});

watch(lockerType, (type) => {
  if (type === 'daily') {
    usageDateRange.value = calculateTimeRange(usageTime.value.hours * 60 + usageTime.value.minute);
  } else {
    usageDateRange.value = calculatePeriodRange(usagePeriod.value, usageDateRange.value[0]);
  }
})

watch(usagePeriod, (month) => {
  if (!month) return;
  usageDateRange.value = calculatePeriodRange(month)
});

watch(usageTime, (time) => {
  if (time.hours === null) {
    usageTime.value.minute = 0;
    return;
  }
  usageDateRange.value = calculateTimeRange(time.hours * 60 + time.minute, usageDateRange.value[0]);
}, {deep: true});


function calculateEndTime() {
  if (!usageTime.value.hours){
    usageDateRange.value[1] = limitTime.value;
  }else{
    usageDateRange.value[1] = maxTime.value;
  }
}

function calculateEndDate() {
  if (!usagePeriod.value) return;
  usageDateRange.value[1] = maxDate.value;
}

function handleSearchInput(e) {
  const keyword = e.target.value;
  searchValue.value = keyword;

  // 검색어가 비어 있을 경우 바로 목록을 초기화
  if (!keyword) {
    searchedUsers.value = [];
    debouncedSearchUser.cancel(); // 이미 대기 중인 디바운스된 호출 취소
    return;
  }
  debouncedSearchUser(keyword); // 디바운스된 검색 함수 호출
}

const debouncedSearchUser = debounce(async (keyword) => {
  try {
    const {data, status} = await fetchSearchedStoreUser(modalData.value.companyId, keyword);
    if (status === 200) {
      searchedUsers.value = data.responses; // 검색 결과 저장
    }
  } catch (e) {
    console.error("자동완성 API 호출 에러:", e);
  }
}, 200);

async function registerUsage() {
  if (!checkRequiredFields()) {
    alert('락커 배정 정보를 모두 입력해 주세요');
    return;
  }
  try {
    const {status} = await registerLockerUsage({
      lockerId: modalData.value.lockerId,
      storeUserId: targetUserId.value,
      usageType: lockerType.value,
      usageStartTime: formatDateTime(usageDateRange.value[0], '-', '+09:00'),
      usageEndTime: formatDateTime(usageDateRange.value[1], '-', '+09:00'),
    });
    if (status === 201) {
      alert('등록되었습니다.');
      emits('complete');
    }
  } catch (e) {
    console.error(e);
  }
}

function checkRequiredFields() {
  if (!targetUserId.value) {
    searchInput.value.focus();
    return false;
  }
  return (usageDateRange.value !== null
      && usageDateRange.value.length === 2);
}

function modalOff() {
  if (isChanged()) {
    if (!confirm('입력 중인 내용이 사라집니다.')) return;
  }
  modal.value = false;
}

function isChanged() {
  return (initialValue.value.targetUserId !== targetUserId.value
      || initialValue.value.startDate !== usageDateRange.value[0]
      || initialValue.value.endDate !== usageDateRange.value[1])
}

// 이용 기간 계산
function calculatePeriodRange(month = 1, today = new Date()) {
  let endDate = new Date().setMonth(today.getMonth() + month);
  // endDate = new Date(endDate).setHours(23,59,59);
  endDate = new Date(endDate).setHours(0, 0, 0);
  return [today, new Date(endDate)];
}

// 이용 시간 계산
function calculateTimeRange(minutes, now = new Date()) {
  let endDate = new Date(now).setMinutes(now.getMinutes() + minutes);
  return [now, endDate];
}

</script>

<style scoped>
.autocomplate {
  height: auto;
  z-index: 1;
}
</style>