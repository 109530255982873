<template>
  <div id="Modal" class="d_pop">
    <div class="d_pop_box">
      <div class="d_pop_top">
        <div class="title">반려하기</div>
        <a class="cancel" @click.prevent="emit('cancel')">⨉</a>
      </div>
      <div class="d_pop_middle">
        <div class="tbl_search">
          <table>
            <colgroup>
              <col style="width: 25%"/>
              <col/>
            </colgroup>
            <tbody>
            <tr>
              <th>사유입력</th>
              <td>
                <textarea class="w_97p" placeholder="반려 사유를 입력하세요"
                          maxlength="20"
                          :value="rejectReason"
                          @input="inputReason"
                          style="resize: none"
                ></textarea>
              </td>
            </tr>
            </tbody>
          </table>
          <div class="flex-end">
            <span class="num counter"
                  :class="{text_red:rejectReason.length>=20}"
            >{{rejectReason.length}}/20</span>
          </div>
        </div>
      </div>
      <div class="d_pop_btn">
        <button type="button" class="on" @click="emit('reject',rejectReason)">완료</button>
        <button @click="emit('cancel')" type="button">취소</button>
      </div>
    </div>
  </div>
</template>

<script setup>

import {ref} from "vue";

const storeUserId = defineModel('storeUserId');
const rejectReason = ref('');

const emit = defineEmits(['cancel','reject']);

function inputReason(e){
  let newReason = e.target.value;
  if(newReason.length > 20) {
    return;
  }
  rejectReason.value = newReason;
}

</script>

<style scoped>
.counter{
  margin: 4px 4px 0 0;
}
.d_pop .d_pop_middle {
  margin-bottom: 12px;
}
</style>
