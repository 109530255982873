<template>
  <tr class="no_move">
    <td class="o1"><input type="checkbox" v-model="termsInfo.isSelected" /></td>
    <td class="no_mobile">{{ termsInfo.number }}</td>
    <td class="detail_page_a long_txt o2 o_title">
      <div>
        <router-link :to="`${detailPath}`" class="center_td">
          <p class="text_bold" style="max-width: 280px">{{ dateToString(termsInfo.effectiveDate, '.') || '-' }}</p>
          <img src="@/images/detail_page.png"/>
        </router-link>
      </div>
    </td>
    <td class="center_td o_stat">
<!--      <div class="stat back_green"><span>표시</span></div>-->
      <div class="stat" :class="statusInfo.class"><span>{{ statusInfo.text }}</span></div>
    </td>
  </tr>
</template>

<script setup>
import {useAuthStore} from "@/store/auth";
import {computed} from "vue";
import {dateToString} from "@/util/formatUtils";

const authStore = useAuthStore();

const props = defineProps({
  termsInfo: {
    type: Object,
    required: true,
  },
  detailPath: String,
});

const statusInfo = computed(() => {
  switch (props.termsInfo.isActive) {
    case true:
      return {
        class: 'back_green',
        text: '표시'
      };
    default:
      return {
        class: 'back_red',
        text: '미표시'
      }
  }
})
</script>

<style  scoped>

</style>