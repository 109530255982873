import {onUnmounted, ref} from 'vue';

export function useOrder(sortFields, fetchInfo) {
    const sortFieldsState = ref(sortFields);

    function getOrderBy() {
        return sortFieldsState.value.find(field => field.direction !== null);
    }

    function setOrderBy({key, direction}) {
        sortFieldsState.value
            // .filter(field => 'direction' in field)
            .forEach(field => {
                if (field.key === key) {
                    field.direction = direction;
                } else {
                    field.direction = null;
                }
            });
        fetchInfo();
    }

    function resetOrderBy() {
        sortFieldsState.value
            .filter(field => 'direction' in field)
            .forEach(field => field.direction = null);
    }

    onUnmounted(() => {
        sortFieldsState.value
            .filter(field => 'direction' in field)
            .forEach(field=> field.direction = null);
    })

    return {
        sortFieldsState,
        getOrderBy,
        setOrderBy,
        resetOrderBy,
    };
}
