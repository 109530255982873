<template>
  <div class="inner_td">
    <button v-for="tab in tabs" :key="tab.value"
            type="button" :class="{on : selectTab ===tab.value}"
            @click="clickTab(tab.value)"
    >
      {{ tab.label }}
    </button>
  </div>
</template>

<script setup>

import {ref} from "vue";

const emit = defineEmits(['tab-selected']);

const props = defineProps({
  tabs: {
    type: Array,
    required: true,
  },
  defaultTab: {
    type: String,
    default: ''
  }
});

// const selectTab = ref(props.defaultTab || props.tabs[0].value);
const selectTab = defineModel('selectTab');

function clickTab(tabValue){
  selectTab.value = tabValue;
  emit('tab-selected');
}

</script>

<style scoped>

</style>