<template>
  <div class="container" id="login-container">
    <form @submit.prevent="login">
      <div class="wrap">
        <img src="@/images/logo.png" alt="Logo" class="logo"/>
        <div class="logotxt">블루투스 락커 솔루션</div>
        <input
            type="text"
            v-model="userid"
            id="userid"
            placeholder="아이디"
            TabIndex="1"
            autofocus
        />
        <input
            type="password"
            v-model="password"
            id="password"
            placeholder="비밀번호"
            TabIndex="2"
        />
        <div class="id_wrap">
          <div>
            <input
                id="chkLogin"
                type="checkbox"
                v-model="isAutoLogin"
                name="chkLogin"
            /><label for="chkLogin">자동로그인</label>
          </div>
        </div>
        <button type="submit"
                :class="{disabled: isValidLoginInfo}"
                :disabled="isValidLoginInfo">로그인</button>
        <div class="line"></div>
        <span class="solution-name"
              style="font-size: 0.7em;font-weight:300;letter-spacing:0.6em;"
        >
          Bluetooth Locker</span
        >
        <div class="bottom"></div>
      </div>
      <div class="footer_tl">MizeCloud</div>
      <div class="footer_tr">
        <a href="http://mizeinc.co.kr/index.html" target="_blank"
        >Help | <img src="@/images/q_color.png"
        /></a>
      </div>
      <div class="footer_l">
        <a href="https://mizeinc.co.kr/introduce.html" target="_blank"
        ><img src="@/images/logo.png"
        /></a>
      </div>
      <div class="footer">Copyright ⓒ Mize Inc. All Rights Reserved.</div>
    </form>
  </div>
</template>

<script setup>

import {computed, ref} from "vue";
import {useRouter} from "vue-router";
import {loginUser} from "@/api/auth";
import {useAuthStore} from "@/store/auth";
import {saveToken} from "@/util/authToken";

const userid = ref('');
const password = ref('');
const isAutoLogin = ref(false);

const router = useRouter();
const isValidLoginInfo = computed(()=>!userid.value || !password.value);

const authStore = useAuthStore();

if(authStore.isLogin){
  router.push('/');
}

async function login() {

  const user = {
    loginId: userid.value,
    password: password.value,
  };

  try {
    const response = await loginUser(user);

    if (response.status === 200) {
      authStore.username = response.data.managedUserName;
      authStore.role = response.data.role;
      authStore.isLogin = true;
      saveToken(response.data.accessToken, response.data.refreshToken,isAutoLogin.value);
      router.push('/locker/use/usestatus');
    }
  } catch (error) {
    if (error.response) {
      // 요청이 서버에 도달했으나 응답을 받지 못한
      console.log(error);
      if(error.response.status === 403 && error.response.data.errorCode === 'Unauthorized'){
        alert('비활성화된 계정입니다.');
      }
    } else if (error.request) {
      // 요청이 서버에 도달하지 못한
      console.error("Request Error:", error.request);
      alert("서버에 접근할 수 없습니다.");
    } else {
      console.error("Error:", error.message);
      alert(`${error.message}`);
    }
  }
}

</script>

<style scoped >

.loading-spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.disabled,.disabled:hover{
  opacity: 0.5!important;
  cursor:no-drop!important;
}

</style>
