<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">설정</div>
        <div class="description">락커 기본제공 시간 및 알림을 설정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info" v-if="authStore.isSuperAdmin">
            <div class="part_title">업체선택</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26"/>
                <col/>
              </colgroup>
              <tbody>
              <tr>
                <th>업체</th>
                <td>
                  <div class="inner_td">
                    <CompanySelector v-model:companyId="companyId"
                                     :is-view-mode="false"
                                     :selected="true"
                    />
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">일일락커 설정</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26"/>
                <col/>
              </colgroup>
              <tbody>
              <tr>
                <th>기본제공시간</th>
                <td>
                  <div class="inner_td">
                    <TimeSelector v-model:time="settingData.dailyDefaultDuration"
                                  :isPresent="true"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div class="reldiv">
                    최대연장시간
                    <InfoIcon class="icon" width="5" :size="15"/>
                    <div class="descbox">
                      방문객이 일일락커 이용 중
                      <br/>직접 연장 가능한 시간을 설정합니다.
                    </div>
                  </div>
                </th>
                <td>
                  <div class="inner_td">
                    <TimeSelector v-model:time="settingData.dailyMaxExtension"/>
                  </div>
                </td>
              </tr>
              <tr>
                <th>만료알림설정</th>
                <td>
                  <div class="inner_td column left">
                    <div class="inner_td">
                      이용 만료 전 알림을 발송할 시간을 선택해주세요.
                    </div>
                    <CheckTabs v-model:checked-tabs="settingData.dailyExpirationNotifications"
                               :tabs="dailyAlarmOptions"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div class="reldiv">
                    미반납알림설정
                    <InfoIcon class="icon" width="5" :size="15"/>
                    <div class="descbox">
                      반납 시까지 매일 작성한 시각에 맞춰
                      <br/>사용자에게 push 알림이 발송됩니다.
                    </div>
                  </div>
                </th>
                <td>
                  <div class="inner_td">
                    <DatePicker class="w_150" v-model="settingData.dailyUnReturnedNotificationTime"
                                placeholder="00:00"
                                time-picker auto-apply
                                :clearable="false"
                    />
                    *반납 시까지 매일 설정한 시각에 맞춰
                    사용자에게 push 알림이 발송됩니다.
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="tbl_search tbl_info">
            <div class="part_title">개인락커 설정</div>
            <table>
              <colgroup>
                <col style="width: 18%" class="col_width26"/>
                <col/>
              </colgroup>
              <tbody>
              <tr>
                <th>알림발송시각</th>
                <td>
                  <div class="inner_td">
                    <DatePicker class="w_150" v-model="settingData.personalNotificationTime"
                                placeholder="00:00"
                                time-picker auto-apply
                                :clearable="false"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>만료알림설정</th>
                <td>
                  <div class="inner_td column left">
                    <div class="inner_td">
                      이용 만료 전 알림을 발송할 시간을 선택해주세요.
                    </div>
                    <CheckTabs v-model:checked-tabs="settingData.personalExpirationNotifications"
                               :tabs="personalAlarmOptions"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <div class="reldiv">
                    미반납알림설정
                    <InfoIcon class="icon" width="5" :size="15"/>
                    <div class="descbox">
                      반납 시까지 매일 작성한 시각에 맞춰
                      <br/>사용자에게 push 알림이 발송됩니다.
                    </div>
                  </div>
                </th>
                <td>
                  <div class="inner_td">
                    <DatePicker class="w_150" v-model="settingData.personalUnReturnedNotificationTime"
                                placeholder="00:00"
                                time-picker auto-apply
                                :clearable="false"
                    />
                    *반납 시까지 매일 설정한 시각에 맞춰
                    사용자에게 push 알림이 발송됩니다.
                  </div>
                </td>
              </tr>
              </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="saveAlarmSetting" href="###" class="on">설정저장</a>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {useRouter} from "vue-router";
import CheckTabs from "@/components/common/CheckTabs.vue";
import {dailyAlarmOptions, personalAlarmOptions} from "@/util/checkFilds";
import {ref, toRaw, watch} from "vue";
import {useAuthStore} from "@/store/auth";
import CompanySelector from "@/components/common/CompanySelector.vue";
import InfoIcon from "vue-material-design-icons/InformationVariantCircleOutline.vue";
import TimeSelector from "@/components/common/TimeSelector.vue";
import {fetchCompanyLockerSetting, saveCompanyLockerSetting} from "@/api/company";
import {timeStringToObject} from "@/util/formatUtils";

const router = useRouter()
const authStore = useAuthStore();
const companyId = ref(authStore.isSuperAdmin ? null : authStore.companyId);

const settingData = ref({
  dailyDefaultDuration: 30,
  dailyMaxExtension: 0,
  dailyExpirationNotifications: [],
  dailyUnReturnedNotificationTime: {hours: 9, minutes: 0},
  personalNotificationTime: {hours: 9, minutes: 0},
  personalExpirationNotifications: [],
  personalUnReturnedNotificationTime: {hours: 9, minutes: 0},
});

watch(companyId, async () => {
  resetData();
  await fetchLockerSetting()
}, {immediate: true});

async function fetchLockerSetting() {
  try {
    const {data} = await fetchCompanyLockerSetting(companyId.value);
    console.log(data);
    settingData.value = data;
    settingData.value.dailyUnReturnedNotificationTime = timeStringToObject(data.dailyUnReturnedNotificationTime);
    settingData.value.personalNotificationTime = timeStringToObject(data.personalNotificationTime);
    settingData.value.personalUnReturnedNotificationTime = timeStringToObject(data.personalUnReturnedNotificationTime);
  } catch (e) {
    if (!e.response.data.errorCode === 'Company Locker Setting Not Found') {
      console.error(e);
    }
  }
}

async function saveAlarmSetting() {
  if(!checkData()){
    alert('알림 정보를 모두 입력해 주세요.')
    return;
  }
  try {
    const {status} = await saveCompanyLockerSetting(companyId.value,settingData.value);
    if(status === 200){
      alert('저장되었습니다.');
    }
  } catch(e){
    console.error(e);
  }
}

function resetData() {
  settingData.value.dailyDefaultDuration = 30;
  settingData.value.dailyMaxExtension = 0;
  settingData.value.dailyExpirationNotifications = [];
  settingData.value.dailyUnReturnedNotificationTime = {hours: 9, minutes: 0};
  settingData.value.personalNotificationTime = {hours: 9, minutes: 0};
  settingData.value.personalExpirationNotifications = [];
  settingData.value.personalUnReturnedNotificationTime = {hours: 9, minutes: 0};
}

function checkData(){
  return settingData.value.dailyDefaultDuration > 0
      && settingData.value.dailyExpirationNotifications.length>0
      && settingData.value.personalExpirationNotifications.length>0
}

</script>

<style scoped>
.descbox {
  display: none;
}

.icon:hover ~ .descbox {
  display: block;
}
</style>
