<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">회원 등록</div>
        <div class="description">(*) 표시는 필수 입력 정보입니다.</div>
        <article class="right_body">
          <MemberForm v-model:storeUser="storeUser"
                      :companys="companys"
                      ref="formInputs"
          />
          <div class="btn_bottom_a">
            <a @click.prevent="addMember" class="on">
              등록
            </a>
            <router-link to="/member/list">취소</router-link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import MemberForm from "@/components/member/MemberForm.vue";
import {onBeforeRouteLeave, useRouter} from "vue-router";
import {onMounted, reactive, ref} from "vue";
import {fetchMyCompany} from "@/api/company";
import {useAuthStore} from "@/store/auth";
import {registerStoreUser} from "@/api/storeUser";

const router = useRouter();
const authStore = useAuthStore();

// 자식 컴포넌트를 참조할 ref
const formInputs = ref(null);
const isAdded = ref(false);

const storeUser = ref({
  birthday: '',
  companyId: null,
  description: null,
  phoneNumber: ['010', '', ''],
  gender: null,
  name: '',
  phoneValid: false,
});

const companys = ref([]);

const initialState = ref({});

onMounted(async () => {
  try {
    if (authStore.isSuperAdmin) {
    const {data} = await fetchMyCompany();
      companys.value = data;
    } else {
      storeUser.value.companyId = authStore.companyId;
    }
  } catch (e) {
    console.error(e);
  }

  initialState.value = JSON.parse(JSON.stringify(storeUser.value));
})


onBeforeRouteLeave((to, from, next) => {
  if (isStateChanged() && !isAdded.value) {
    if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
  }
  next();
});

function isStateChanged() {
  return JSON.stringify(initialState.value) !== JSON.stringify(storeUser.value);
}

async function addMember() {
  if (!checkRequired()) {
    alert('필수 입력 정보를 모두 입력해주세요');
    return;
  }
  try{
    const resp = await registerStoreUser(storeUser.value);
    if(resp.status === 201){
      isAdded.value = true;
      alert('등록되었습니다.');
      await router.push('/member/list');
    }
  } catch(e){
    console.error(e);
  }
}

function checkRequired() {
  if(storeUser.value.name === ''){
    formInputs.value.nameInput.focus();
    return false;
  }
  if(!storeUser.value.phoneValid){
    if (storeUser.value.phoneNumber[1].length<4){
      formInputs.value.phoneInput2.focus();
    }else{
      formInputs.value.phoneInput3.focus();
    }
    return false;
  }

  return storeUser.value.companyId !== null;
}

</script>

<style scoped></style>
