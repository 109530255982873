<template>
  <div class="body_right">
    <div class="right_container">
      <div class="right_title">
        <div class="title">락커 상세보기</div>
        <div class="description">락커 등록정보를 조회하고 수정합니다.</div>
        <article class="right_body">
          <div class="tbl_search tbl_info" v-if="isDataLoaded">
            <div class="part_title">기본정보</div>
            <table>
                <colgroup>
                <col style="width: 18%" class="col_width26" />
                <col />
                </colgroup>
                <tbody>
                <tr v-if="authStore.isSuperAdmin">
                    <th>업체선택<span class="text_red">*</span></th>
                    <td>
                    <div class="inner_td">
                        <CompanySelector :isViewMode="false" :isDisabled="true" v-model:companyId="companyId" @change="getAreaList"/>
                        <!-- <select class="w_250">
                        <option>업체를 선택하세요</option>
                        <option selected>마이즈</option>
                        </select> -->
                    </div>
                    </td>
                </tr>
                <tr>
                    <th>구역 / 중계기<span class="text_red">*</span></th>
                    <td>
                    <div class="inner_td">
                        <select class="w_250" v-model="areaId"
                                @change="getTabletList"
                                :disabled="companyId===null">
                            <option disabled :value="null">구역을 선택하세요</option>
                            <option v-for="area in areas" :value="area.areaId">{{ area.areaName }}</option>
                        </select>
                        <select class="w_250" v-model="tabletId"
                                :disabled="companyId===null || areaId === null">
                            <option disabled :value="null">중계기를 선택하세요</option>
                            <option v-for="tablet in tablets" :value="tablet.tabletId">{{ tablet.tabletName }}</option>
                        </select>
                        <!-- <select class="w_250">
                        <option>구역을 선택하세요</option>
                        <option selected>1F</option>
                        </select>
                        <select class="w_250">
                        <option>중계기를 선택하세요</option>
                        <option selected>1F 1~30</option>
                        </select> -->
                    </div>
                    </td>
                </tr>
                <tr>
                    <th>락커번호<span class="text_red">*</span></th>
                    <td>
                    <div class="inner_td">
                        <input
                            type="text"
                            class="w_250"
                            placeholder="락커번호를 입력하세요"
                            v-model="lockerNum"
                            ref="lockerInput"
                        />
                        <div class="validation"
                            :class="lockerNumbValid.valid?'text_green':'text_red'">
                            {{ lockerNumbValid.msg }}
                        </div>
                        <!-- <div class="validation text_red">
                        이미 등록된 락커번호입니다.
                        </div> -->
                    </div>
                    </td>
                </tr>
                <tr>
                    <th>시리얼넘버<span class="text_red">*</span></th>
                    <td>
                    <div class="inner_td">
                        <input
                        type="text"
                        class="w_250"
                        placeholder="시리얼넘버를 입력하세요"
                        v-model="serialNumber"
                        ref="serialInput"
                        />
                        <div class="validation"
                            :class="serialNumValid.valid?'text_green':'text_red'">
                            {{ serialNumValid.msg }}
                        </div>
                        <!-- <div class="validation text_red">
                        이미 등록된 시리얼넘버입니다.
                        </div> -->
                    </div>
                    </td>
                </tr>
                <tr>
                    <th>PIN번호<span class="text_red">*</span></th>
                    <td>
                    <input
                        type="text"
                        class="w_250"
                        placeholder="PIN번호를 입력하세요"
                        v-model="pinNumber"
                        ref="pinInput"
                    />
                    </td>
                </tr>
                </tbody>
            </table>
          </div>
          <div class="btn_bottom_a">
            <a @click.prevent="editLocker" href="###" class="on">저장</a>
            <a @click.prevent="deleteLocker" href="###">삭제</a>
            <a @click.prevent="listLocker" href="###">목록</a>
            <!-- <router-link to="/locker/admin/list">목록</router-link> -->
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script setup>
import {onBeforeRouteLeave, useRoute, useRouter} from "vue-router";
import {useAuthStore} from "@/store/auth";
import {onMounted, ref, watch} from "vue";
import CompanySelector from "@/components/common/CompanySelector.vue";
import {fetchAreaList} from "@/api/area";
import {fetchTabletList} from "@/api/tablet";
import {debounce} from "lodash";
// import {useLockerStore} from "@/store/locker";
import {storeToRefs} from "pinia";
import {modifyLocker, removeLockers, checkLockerNumber, checkSerialNumber, registerLocker, fetchLocker} from "@/api/locker/locker";

const router = useRouter();
const route = useRoute();
// const lockerStore = useLockerStore();
const authStore = useAuthStore();

// 락커수정 초기상태를 저장할 변수
const initialState = ref({});
const isEdited = ref(false);
const isDataLoaded = ref(false);

const areas = ref([]);
const tablets = ref([]);

const companyId = ref(0);
const companyName = ref('');
const areaId = ref(0);
const areaName = ref('');
const tabletId = ref(0);
const tabletName = ref('');
const lockerNum = ref(0);
const batteryLevel = ref(0);
const serialNumber = ref('');
const pinNumber = ref('');

const lockerInput = ref(null);
const serialInput = ref(null);
const pinInput = ref(null);

const lockerNumbValid = ref({
  msg: '',
  valid: false,
});
const serialNumValid = ref({
  msg: '',
  valid: false,
});

// const {companyId, areaId, tabletId, lockerNum, serialNumber, pinNumber, lockerNumbValid, serialNumValid} = storeToRefs(lockerStore);

onMounted(async () => {
  try {
    const {data} = await fetchLocker(route.params.lockerId);
    console.log(data);

    companyId.value = data.companyId;
    companyName.value = data.companyName;
    areaId.value = data.areaId;
    areaName.value = data.areaName;
    tabletId.value = data.tabletId;
    tabletName.value = data.tabletName;
    lockerNum.value = data.lockerNum;
    batteryLevel.value = data.batteryLevel;
    serialNumber.value = data.serialNumber;
    pinNumber.value = data.pinNumber;

    initialState.value = JSON.parse(JSON.stringify(data));
    isDataLoaded.value = true;
  } catch (error) {
    console.error(error);
  }

  // 상태 깊은 복사
  // await lockerStore.fetchLockerData(route.params.lockerId);
  // initialState.value = JSON.parse(JSON.stringify(lockerStore.$state));
  // isDataLoaded.value = true;

  if (!authStore.isSuperAdmin) {
    companyId.value = authStore.companyId;
  }
  getAreaList();
  getTabletList(0);

  debouncedCheckLockerNumber(lockerNum);
  debouncedCheckSerialNumber(serialNumber);
});

watch(lockerNum, (newNum) => {debouncedCheckLockerNumber(newNum);});

const debouncedCheckLockerNumber = debounce(async (newNum) => {
    console.log("debouncedCheckLockerNumber called with", newNum);
  if (!newNum) {
    lockerNumbValid.value.valid = false;
    lockerNumbValid.value.msg = '락커번호를 입력해주세요';
    return;
  }
  
  if (!areaId.value) {
    alert('구역을 먼저 선택해주세요');
    lockerNum.value = null;
    return;
  }

  if (initialState._rawValue.lockerNum == newNum){ // 기존 값
    lockerNumbValid.value.valid = true;
    lockerNumbValid.value.msg = '';
    return;
  }

  try {
    const {data} = await checkLockerNumber(areaId.value, newNum);
    if (data.exists) {
      lockerNumbValid.value.valid = false;
      lockerNumbValid.value.msg = '이미 등록된 락커번호입니다.';
    } else {
      lockerNumbValid.value.valid = true;
      lockerNumbValid.value.msg = '등록 가능한 락커번호입니다.';
    }
  } catch (e) {
    lockerNumbValid.value.valid = false;
    console.error(e);
  }
},300);

watch(serialNumber, (newNum) => {debouncedCheckSerialNumber(newNum);});

const debouncedCheckSerialNumber = debounce(async (newNum) => {
    console.log("debouncedCheckSerialNumber called with", newNum);
  if (!newNum) {
    serialNumValid.value.valid = false;
    serialNumValid.value.msg = '시리얼넘버를 입력해주세요';
    return;
  }

  if (initialState._rawValue.serialNumber == newNum.value || initialState._rawValue.serialNumber == newNum){ // 기존 값
    serialNumValid.value.valid = true;
    serialNumValid.value.msg = '';
    return;
  }

  try {
      const {data} = await checkSerialNumber(newNum);
      if (data.exists) {
        serialNumValid.value.valid = false;
        serialNumValid.value.msg = '이미 등록된 시리얼넘버입니다.';
      } else {
        serialNumValid.value.valid = true;
        serialNumValid.value.msg = '등록 가능한 시리얼넘버입니다.';
      }
  } catch (e) {
    serialNumValid.value.valid = false;
    console.error(e);
  }
},300)

async function getAreaList() {
  if (companyId.value === null) return;
  try {
    const {data, status} = await fetchAreaList(companyId.value);
    if (status === 200) {
      areas.value = data;
    }
  } catch (e) {
    console.error(e);
  }
}

async function getTabletList(cnt) {
  if (areaId.value === null) return;

  if (cnt != 0)
    tabletId.value = null;

  try {
    const {data, status} = await fetchTabletList(areaId.value);
    if (status === 200) {
      tablets.value = data;
      debouncedCheckLockerNumber(lockerNum.value);
    }
  } catch (e) {
    console.error(e);
  }
}

function checkRequired() {
  if(!lockerNumbValid.value.valid){
    lockerInput.value.focus();
    return false;
  }
  if(!serialNumValid.value.valid){
    serialInput.value.focus();
    return false;
  }
  if(!pinNumber.value){
    pinInput.value.focus();
    return false;
  }
  return !!(companyId.value && areaId.value && tabletId.value);
}

function isStateChanged() {
  return !!(companyId.value != initialState._rawValue.companyId || areaId.value != initialState._rawValue.areaId || tabletId.value != initialState._rawValue.tabletId ||
      lockerNum.value != initialState._rawValue.lockerNum || serialNumber.value != initialState._rawValue.serialNumber || pinNumber.value != initialState._rawValue.pinNumber);
}

async function editLocker() {
  if (!checkRequired()) {
    alert('락커정보를 모두 입력해 주세요');
    return;
  }

  try {
    const {status} = await modifyLocker(route.params.lockerId, {
      companyId: companyId.value,
      areaId: areaId.value,
      tabletId: tabletId.value,
      lockerNum: lockerNum.value,
      serialNumber: serialNumber.value,
      pinNumber: pinNumber.value,
    });
    if (status === 200) {
      alert('저장이 완료되었습니다.')
      await router.push('/locker/admin/list');
    }
  } catch (e) {
    console.error(e);
  }
}

async function deleteLocker() {
  if (!confirm('삭제하시겠습니까?')) return;

  try {
    const resp = await removeLockers(route.params.lockerId);
    if (resp.status === 204) {
      alert('삭제되었습니다.');
      await router.push('/locker/admin/list');
    }
  } catch (error) {
    console.error(error);
  }
}

function listLocker(){
  if (isStateChanged()) {
    if (!confirm('입력 중인 내용이 사라집니다.\n계속 하시겠습니까?')) return;
  }
  router.push('/locker/admin/list');
}

// function editLocker(){
//   if(!alert('저장이 완료되었습니다.')){
//     // 락커정보 수정...
//     router.push('/locker/admin/list');
//   }
// }
// function deleteLocker(){
//   if(confirm('락커를 삭제하시겠습니까?')){
//     // 락커삭제...
//     alert('삭제되었습니다.');
//     router.push('/locker/admin/list');
//   }
// }
</script>

<style scoped></style>
